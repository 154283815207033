import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image,InputGroup  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import '../constants/env';
import axios from 'axios';
import { Link,Navigate } from "react-router-dom";
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import Loading from '../loading'
const baseUrl=global.config.data.path.basePath
const bkash=global.config.data.files.bkash
const amex=global.config.data.files.amex
const master=global.config.data.files.master
const visa=global.config.data.files.visa
const rocket=global.config.data.files.rocket
const nexus=global.config.data.files.nexus
export default class Login extends Component {
    state = {
        validated:false,
        authorization:false,
        showLogin:true,
        showFundType:false,
        showBo:false,
        showSip:false,
        showPayment:false,
        showOfflineDeposit:false,
        showPaymentSuccess:false,
        showProfile:false,
        showApplication:false,
        step_check:'',
        errorStatus:'',
        token:'',

        client_id: '',
        password: '',
        client_idError: '',
        passwordError: '',
        paymentFrequency:'Monthly',
        installment_tenure:'03',
        installment_date:'05',
        installment_size:'',
        installment_size_error:'',
        installmentAmount: 100,
        investmentType:'',
        investmentCategory: 'General',
        investmentCategoryMonthly: 1000,
        investmentCategoryQuarterly: 5000,
        investmentCategoryYearly: 25000,
        investmentCategoryMax: 'No Limit',
        accountType: 'Individual',
        portfolios:[],
        fundName:'',
        portfolio_name:'',
        boStatus:'',
        boAccountNo:'',
        paymentType: 'beftn',
        account_no:'',
        bank_id:'',
        bank_name:'',
        branch_id:'',
        branch_name:'',
        routing_no:'',
        browseFile: '',
        purchase_price:'',
        instrument_date_show:new Date().getDate()+'-'+new Date().getMonth()+1+'-'+new Date().getFullYear(),
        instrument_date:new Date().getMonth()+1+'-'+new Date().getDate()+'-'+new Date().getFullYear(),
        operation_mode:'',
        deposit_receipt:'',
        reg_no:'',
        dividend_type:'CASH',
        dividend_typeError:'',
        cip_percentage:'',
        cash_percentage:'',
        investor_name:'',
        paymentMethod:'bKashMFS',
        no_of_unit:'',
        cip_cash_error:'',
        bkash_charge:1.3,
        visa_charge:1.6,
        master_charge:1.6,
        nexus_charge:1,
        rocket_charge:1,
        american_charge:1,
        institution_name:'',
        investor_account_id:'',
        auto_debit_instructed:false,
        isLoading: true
    }
    componentDidMount(){
        document.title = 'Login';
        this.setState({
            isLoading: false
        })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const status = urlParams.get('status');
        if(status==200){
            Swal.fire({
                position: 'top-right',
                text: 'Your Request Successfully received',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        }else if(status!=null){
            Swal.fire({
                position: 'top-right',
                text: 'Invalid Payment Request',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            })
        }
    }
    changeState = (e) => {
        if(e.target.name=='fundName'){
            this.getCurrentNav(e.target.value)
            if(document.querySelector('input[name="no_of_unit"]')!=null){
                document.querySelector('input[name="no_of_unit"]').value=''
            }
            const selectedOption = e.target.options[e.target.selectedIndex];
            this.setState({
                portfolio_name:selectedOption.textContent,
                fundName:e.target.value,
                installment_size:''
            })
            
        }else if(e.target.name=='deposit_receipt'){
            let file = e.target.files[0].name
            file = file + ' file is ready to upload.'
            this.setState({
                browseFile: file,
                deposit_receipt:e.target.files[0]
            })
        }else if(e.target.name=='no_of_unit'){
            this.setState({
                installment_size:(this.state.purchase_price*e.target.value)
            })
        }else if(e.target.name=='cip_percentage' || e.target.name=='cash_percentage'){
            if(e.target.name=='cip_percentage'){
                this.setState({
                    [e.target.name]: e.target.value,
                    cash_percentage:100-parseInt(e.target.value)
                })
            }else{
                this.setState({
                    [e.target.name]: e.target.value,
                    cip_percentage:100-parseInt(e.target.value)
                })
            }
        }else if(e.target.name == 'auto_debit_instructed'){
            this.setState({
                auto_debit_instructed:!this.state.auto_debit_instructed
            })
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    login = (e) => {
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        let data = {
            client_id: 'AIMS-'+this.state.client_id,
            password: this.state.password
        }
        const url = baseUrl+'/api/login'
        this.setState({
            isLoading: true
        })
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }).then((res) => {
            localStorage.setItem('token',res.data.access)
            localStorage.setItem('refreshToken',res.data.refresh)
            if(res.data.access){
                this.getPortfolio(res.data.access)
                this.checkStep(res.data.access)
                this.setState({
                    authorization:true,
                    validated:!this.state.validated,
                    showLogin:!this.state.showLogin,
                    token:res.data.access,
                    isLoading: false
                })
            }else{
                this.setState({
                    errorStatus:400,
                    isLoading: false
                })
            }
            
            
        }).catch((err) => {
            this.setState({
                errorStatus:400,
                passwordError:err.response.data.detail,
                isLoading: false
            }) 
         });
    }
    investmentType = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    investmentCategory = (e) => {
        if (e.target.value == "micro") {
            this.setState({
                investmentCategory: 'Micro',
                investmentCategoryMonthly: 100,
                investmentCategoryQuarterly: 500,
                investmentCategoryYearly: 5000,
                investmentCategoryMax: 50000,
            })
        } else if (e.target.value == "small") {
            this.setState({
                investmentCategory: 'Small',
                investmentCategoryMonthly: 100,
                investmentCategoryQuarterly: 500,
                investmentCategoryYearly: 5000,
                investmentCategoryMax: 500000,
            })
        } else if (e.target.value == "general") {
            this.setState({
                investmentCategory: 'General',
                investmentCategoryMonthly: 1000,
                investmentCategoryQuarterly: 5000,
                investmentCategoryYearly: 25000,
                investmentCategoryMax: 'No Limit',
            })
        }
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                portfolios:res.data.data
            })
            
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    checkStep=(token)=>{
        const url = baseUrl+'/api/get-step-check'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.status==200){
                localStorage.setItem('STEP_CHECK',res.data.data[0].STEP_CHECK)
               
                if(res.data.data[0].STEP_CHECK==null){
                    this.setState({
                        showFundType:!this.state.showFundType,
                        reg_no:res.data.data[0].INVESTOR_REG_NO,
                        operation_mode:res.data.data[0].OPERATION_MODE_ID,
                        boAccountNo:(res.data.data[0].BO_ACCOUNT_NO!=null?res.data.data[0].BO_ACCOUNT_NO:res.data.data[0].INST_BO_ACCOUNT_NO),
                        investor_name:res.data.data[0].INVESTOR_NAME,
                        institution_name:res.data.data[0].INSTITUTION_NAME,
                        investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID,
                        installment_tenure:(res.data.data[0].INSTALLMENT_TENOR==null?'':res.data.data[0].INSTALLMENT_TENOR),
                        installment_date:(res.data.data[0].INSTALLMENT_DATE==null?'':res.data.data[0].INSTALLMENT_DATE),
                        auto_debit_instructed:res.data.data[0].AUTO_DEBIT,
                    })
                }else if(res.data.data[0].STEP_CHECK=="INVESTMENT"){
                    this.setState({
                        showBo:!this.state.showBo,
                        fundName: res.data.data[0].PORTFOLIO_ID,
                        investmentType: res.data.data[0].INVESTMENT_TYPE_ID,
                        boAccountNo:(res.data.data[0].BO_ACCOUNT_NO!=null?res.data.data[0].BO_ACCOUNT_NO:res.data.data[0].INST_BO_ACCOUNT_NO),
                        operation_mode:res.data.data[0].OPERATION_MODE_ID,
                        reg_no:res.data.data[0].INVESTOR_REG_NO,
                        dividend_type:res.data.data[0].DIVIDEND_TYPE,
                        portfolio_name:res.data.data[0].PORTFOLIO_NAME,
                        investor_name:res.data.data[0].INVESTOR_NAME,
                        installment_size:res.data.data[0].INSTALLMENT_SIZE,
                        institution_name:res.data.data[0].INSTITUTION_NAME,
                        investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID,
                        installment_tenure:(res.data.data[0].INSTALLMENT_TENOR==null?'':res.data.data[0].INSTALLMENT_TENOR),
                        installment_date:(res.data.data[0].INSTALLMENT_DATE==null?'':res.data.data[0].INSTALLMENT_DATE),
                        auto_debit_instructed:res.data.data[0].AUTO_DEBIT,
                    })
                    this.getCurrentNav(res.data.data[0].PORTFOLIO_ID)
                }else if(res.data.data[0].STEP_CHECK=="BOACCOUNT" || res.data.data[0].STEP_CHECK=="PAYMENT_UNCOMPLETE"){
                    this.setState({ 
                        showSip:!this.state.showSip,
                        fundName: res.data.data[0].PORTFOLIO_ID,
                        investmentType: res.data.data[0].INVESTMENT_TYPE_ID,
                        boAccountNo:(res.data.data[0].BO_ACCOUNT_NO!=null?res.data.data[0].BO_ACCOUNT_NO:res.data.data[0].INST_BO_ACCOUNT_NO),
                        operation_mode:res.data.data[0].OPERATION_MODE_ID,
                        reg_no:res.data.data[0].INVESTOR_REG_NO,
                        dividend_type:res.data.data[0].DIVIDEND_TYPE,
                        portfolio_name:res.data.data[0].PORTFOLIO_NAME,
                        investor_name:res.data.data[0].INVESTOR_NAME,
                        installment_size:res.data.data[0].INSTALLMENT_SIZE,
                        institution_name:res.data.data[0].INSTITUTION_NAME,
                        investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID,
                        installment_tenure:(res.data.data[0].INSTALLMENT_TENOR==null?'':res.data.data[0].INSTALLMENT_TENOR),
                        installment_date:(res.data.data[0].INSTALLMENT_DATE==null?'':res.data.data[0].INSTALLMENT_DATE),
                        auto_debit_instructed:res.data.data[0].AUTO_DEBIT,
                    })
                    this.getCurrentNav(res.data.data[0].PORTFOLIO_ID)
                }else if(res.data.data[0].STEP_CHECK=="PAYMENTOP"){
                    this.setState({ 
                        showOfflineDeposit:!this.state.showOfflineDeposit,
                        fundName: res.data.data[0].PORTFOLIO_ID,
                        investmentType: res.data.data[0].INVESTMENT_TYPE_ID,
                        boAccountNo:(res.data.data[0].BO_ACCOUNT_NO!=null?res.data.data[0].BO_ACCOUNT_NO:res.data.data[0].INST_BO_ACCOUNT_NO),
                        operation_mode:res.data.data[0].OPERATION_MODE_ID,
                        reg_no:res.data.data[0].INVESTOR_REG_NO,
                        dividend_type:res.data.data[0].DIVIDEND_TYPE,
                        portfolio_name:res.data.data[0].PORTFOLIO_NAME,
                        investor_name:res.data.data[0].INVESTOR_NAME,
                        installment_size:res.data.data[0].INSTALLMENT_SIZE,
                        institution_name:res.data.data[0].INSTITUTION_NAME,
                        investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID,
                        installment_tenure:(res.data.data[0].INSTALLMENT_TENOR==null?'':res.data.data[0].INSTALLMENT_TENOR),
                        installment_date:(res.data.data[0].INSTALLMENT_DATE==null?'':res.data.data[0].INSTALLMENT_DATE),
                        auto_debit_instructed:res.data.data[0].AUTO_DEBIT,
                    })
                    this.getCurrentNav(res.data.data[0].PORTFOLIO_ID)
                    this.getAccountDetails(res.data.data[0].PORTFOLIO_ID)
                }else if(res.data.data[0].STEP_CHECK=="INVESTMENT_COMPLETE"){
                    this.setState({ 
                        showPaymentSuccess:!this.state.showPaymentSuccess,
                        fundName: res.data.data[0].PORTFOLIO_ID,
                        investmentType: res.data.data[0].INVESTMENT_TYPE_ID,
                        boAccountNo:(res.data.data[0].BO_ACCOUNT_NO!=null?res.data.data[0].BO_ACCOUNT_NO:res.data.data[0].INST_BO_ACCOUNT_NO),
                        operation_mode:res.data.data[0].OPERATION_MODE_ID,
                        reg_no:res.data.data[0].INVESTOR_REG_NO,
                        dividend_type:res.data.data[0].DIVIDEND_TYPE,
                        portfolio_name:res.data.data[0].PORTFOLIO_NAME,
                        showApplication:!this.state.showApplication,
                        installment_size:res.data.data[0].INSTALLMENT_SIZE,
                        institution_name:res.data.data[0].INSTITUTION_NAME,
                        investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID,
                        installment_tenure:(res.data.data[0].INSTALLMENT_TENOR==null?'':res.data.data[0].INSTALLMENT_TENOR),
                        installment_date:(res.data.data[0].INSTALLMENT_DATE==null?'':res.data.data[0].INSTALLMENT_DATE),
                        auto_debit_instructed:res.data.data[0].AUTO_DEBIT,
                    })
                    this.getCurrentNav(res.data.data[0].PORTFOLIO_ID)
                }else if(res.data.data[0].STEP_CHECK=="FINISH"){
                    this.setState({ 
                        showProfile:!this.state.showProfile
                    })
                }else{
                    this.setState({ 
                        showApplication:!this.state.showApplication
                    })
                }
            }else if(res.data.status==400){
                this.setState({
                    showFundType:!this.state.showFundType,
                    reg_no:1122
                })
            }
            
        }).catch((err) => {
            console.log(err.response)
         });
    }
    fundType = (e) => {
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        if(this.state.dividend_type=='BOTH'){
            if(parseInt(this.state.cip_percentage)+parseInt(this.state.cash_percentage)!=100){
                this.setState({
                    cip_cash_error:`Cash and Cip percentage should be equal to 100`
                })
                return
            }
        }
        if(this.state.investmentType==1){
            if(this.state.installment_size<this.state.investmentCategoryMonthly || this.state.installment_size>this.state.investmentCategoryMax ){
                if(this.state.investmentCategory!='General'){
                    this.setState({
                        installment_size_error:`Installment size should be between ${this.state.investmentCategoryMonthly} to  ${this.state.investmentCategoryMax}`
                    })
                }else{
                    this.setState({
                        installment_size_error:`Installment size should be greater than ${this.state.investmentCategoryMonthly}`
                    })
                }
                return
            }

            this.setState({
                isLoading:true
            })
            
            var data = {
                fundName: this.state.fundName,
                investmentType: this.state.investmentType,
                dividend_type:this.state.dividend_type,
                cip_percentage:this.state.cip_percentage,
                cash_percentage:this.state.cash_percentage,
                total_purchase_amount:this.state.installment_size,
                installment_date:this.state.installment_date,
                paymentFrequency: this.state.paymentFrequency,
                installment_tenure:this.state.installment_tenure,
                auto_debit_instructed:this.state.auto_debit_instructed,
                investor_account_id:this.state.investor_account_id
            }
        }else{
            var data = {
                fundName: this.state.fundName,
                investmentType: this.state.investmentType,
                dividend_type:this.state.dividend_type,
                cip_percentage:this.state.cip_percentage,
                cash_percentage:this.state.cash_percentage,
                total_purchase_amount:this.state.installment_size,
                auto_debit_instructed:this.state.auto_debit_instructed,
                investor_account_id:this.state.investor_account_id
            }
        }
        const url = baseUrl+'/api/fund-type'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.getCurrentNav()
                this.setState({
                    validated:!this.state.validated,
                    investor_account_id:res.data.data.investor_account_id,
                    showFundType:!this.state.showFundType,
                    showBo:!this.state.showBo
                })
            }
        }).catch((err) => {
            console.log(err.data)
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
        
    }
    paymentFrequency = (e) => {
        if (e.target.value == "Monthly") {
            this.setState({
                installmentAmount: 100,
                paymentFrequency:e.target.value
            })
        } else if (e.target.value == "Quarterly") {
            this.setState({
                installmentAmount: 500,
                paymentFrequency:e.target.value
            })
        } else if (e.target.value == "Yearly") {
            this.setState({
                installmentAmount: 5000,
                paymentFrequency:e.target.value
            })
        }
    }
    boCheck = (e, val) => {
        if (val == 'yes') {
            document.getElementById("nextBtn").classList.remove("hide")
            document.getElementsByClassName("bo-account-yes")[0].classList.remove("hide")
            document.getElementsByClassName("bo-account-no")[0].classList.add("hide")
        } else {
            document.getElementById("nextBtn").classList.add("hide")
            document.getElementsByClassName("bo-account-yes")[0].classList.add("hide")
            document.getElementsByClassName("bo-account-no")[0].classList.remove("hide")
        }
    }
    hasBoAccount = (e) => {
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        let data = {
            boStatus: this.state.boStatus,
            operation_mode:this.state.operation_mode,
            boAccountNo: this.state.boAccountNo
        }
        if(this.state.boAccountNo.length!=16){
            Swal.fire({
                position: 'top-right',
                text: "Bo account number must be 16 digit",
                icon: 'warning',
                timer: 2000
            })
            return
        }
        const url = baseUrl+'/api/bo-account'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            this.getCurrentNav()
            this.setState({
                validated:!this.state.validated,
                showBo:!this.state.showBo,
                showSip:!this.state.showSip
            })
        }).catch((err) => {
            console.log(err.data)
         });
        return false
    }
    showPayment=(e)=>{
        e.preventDefault();
        this.getAccountDetails(this.state.fundName)
        this.setState({
            validated:!this.state.validated,
            showSip:!this.state.showSip,
            showPayment:!this.state.showPayment
        }) 
    }
    showFundTypeAgain=(e)=>{
        e.preventDefault();
        this.setState({
            validated:!this.state.validated,
            showBo:!this.state.showBo,
            showFundType:!this.state.showFundType
        }) 
    }
    showBoAgain=(e)=>{
        e.preventDefault();
        this.setState({
            validated:!this.state.validated,
            showBo:!this.state.showBo,
            showSip:!this.state.showSip
        }) 
    }
    showSipAgain=(e)=>{
        e.preventDefault();
        this.setState({
            validated:!this.state.validated,
            showSip:!this.state.showSip,
            showPayment:!this.state.showPayment
        }) 
    }
    showPaymentAgain=(e)=>{
        e.preventDefault();
        this.getAccountDetails(this.state.fundName)
        this.setState({
            validated:!this.state.validated,
            showOfflineDeposit:!this.state.showOfflineDeposit,
            showPayment:!this.state.showPayment
        }) 
    }
    offlineDepositAgain=(e)=>{
        e.preventDefault();
        this.setState({
            validated:!this.state.validated,
            showOfflineDeposit:!this.state.showOfflineDeposit,
            showPaymentSuccess:!this.state.showPaymentSuccess
        }) 
    }
    getAccountDetails(fundName){
        const url = baseUrl+'/api/get-accountdetails'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:fundName}
        }).then((res) => {
            if(res.data.status==200){
               this.setState({
                    account_no:res.data.data.ACCOUNT_NO,
                    bank_id:res.data.data.ORG_ID,
                    bank_name:res.data.data.ORG_NAME,
                    branch_id:res.data.data.ORG_BRANCH_ID,
                    branch_name:res.data.data.BRANCH_NAME,
                    routing_no:res.data.data.BANK_ROUTING_NO
               })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    payment = (e) => {
        if(this.state.paymentType=='online'){
            this.setState({
                showPayment:!this.state.showPayment,
                showPaymentSuccess:!this.state.showPaymentSuccess
            })
            return
        }
        e.preventDefault()
        let data = {
            paymentType: this.state.paymentType
        }
        const url = baseUrl+'/api/payment-type'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    showPayment:!this.state.showPayment,
                    showOfflineDeposit:!this.state.showOfflineDeposit
                })
            }
        }).catch((err) => {
            console.log(err.data)
         });
        return false
    }
    offlineDeposit=(e)=>{
        e.preventDefault()
        this.setState({
            validated:!this.state.validated,
            showOfflineDeposit:!this.state.showOfflineDeposit,
            showPaymentSuccess:!this.state.showPaymentSuccess,
        }) 
    }
    getCurrentNav = (fund=this.state.fundName) => {
        const url = baseUrl+'/api/get-current-nav'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params: {fund:fund},
        }).then((res) => {
            this.setState({
                purchase_price:res.data.data[0].SELL_PRICE
            })
        }).catch((err) => {
            console.log(err)
         });
        return false
    }
    loginPurchase = (e) => {
        e.preventDefault()
        this.setState({
            isLoading:true
        })
        var no_of_unit = Math.floor(this.state.installment_size/this.state.purchase_price)
        const formData = new FormData();
        formData.append('fund',this.state.fundName)
        formData.append('investmentType',this.state.investmentType)
        formData.append('reg_no',this.state.reg_no)
        formData.append('total_purchase_amount',this.state.installment_size)
        formData.append('dividend_type',this.state.dividend_type)
        formData.append('payment_method',this.state.paymentType)
        formData.append('instrument_date',this.state.instrument_date)
        formData.append('reference_no','010101')
        formData.append('no_of_unit',parseInt(no_of_unit))
        formData.append('deposit_receipt',this.state.deposit_receipt)
        formData.append('login','login')
        formData.append('paymentMethod',this.state.paymentMethod)
        
        formData.append('account_id',this.state.investor_account_id)
        if(this.state.investmentType==1){
            formData.append('paymentFrequency',this.state.paymentFrequency)
            formData.append('installment_tenure',this.state.installment_tenure)
            formData.append('installment_date',this.state.installment_date)
        }

        if(this.state.paymentType=='online'){
            var url = baseUrl+'/api/payment-gateway'
        }else{
            var url = baseUrl+'/api/investor-purchase'
        }
        axios({
            url: url,
            method: "POST",
            data: formData,
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ String(this.state.token) },
        }).then((res) => {
            if(this.state.paymentType=='online'){
                localStorage.setItem('STEP_CHECK','PAYMENT_UNCOMPLETE')
                window.location.href=res.data
                return
            }
            localStorage.setItem('STEP_CHECK','INVESTMENT_COMPLETE')
            this.setState({
                errorStatus:200,
                validated:!this.state.validated,
                showPaymentSuccess:!this.state.showPaymentSuccess,
                showApplication:!this.state.showApplication,
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.data)
        });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("print");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/get-payment-confirmation-details'
        this.setState({
            isLoading:true
        })

        let gateway_title =this.state.paymentMethod=='bKashMFS'?'Bkash Charge':
                    this.state.paymentMethod=='AMEX'?'American Express Charge':
                    this.state.paymentMethod=='MC'?'Mastercard Charge':
                    this.state.paymentMethod=='VC'?'Visa Charge':
                    this.state.paymentMethod=='MB'?'Rocket Charge':
                    this.state.paymentMethod=='NEXUS'?'Nexus Charge':''
              
        let charge =this.state.paymentMethod=='bKashMFS'?`${this.state.bkash_charge }`:
                    this.state.paymentMethod=='AMEX'?`${this.state.american_charge }`:
                    this.state.paymentMethod=='MC'?`${this.state.master_charge }`:
                    this.state.paymentMethod=='VC'?`${this.state.visa_charge }`:
                    this.state.paymentMethod=='MB'?`${this.state.rocket_charge }`:
                    this.state.paymentMethod=='NEXUS'?`${this.state.nexus_charge }`:''
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{
                investor_name:(this.state.investor_name!=null?this.state.investor_name:this.state.institution_name),
                client_id:'AIMS-'+this.state.client_id,
                boAccountNo:this.state.boAccountNo,
                portfolio_name:this.state.portfolio_name,
                purchase_price:this.state.purchase_price,
                installment_size:this.state.installment_size,
                paymentType:this.state.paymentType,
                charge:charge,
                gateway_title:gateway_title,
                no_of_unit:this.state.installment_size/this.state.purchase_price,
                investmentType:this.state.investmentType,
                investmentTypeText:(this.state.investmentType==2?'Single Payment Investment (Non-SIP)':'Systemative Investment Payment (SIP)'),
                installment_tenure:this.state.installment_tenure,
                installment_date:this.state.installment_date
            }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Buy-AIMS-${this.state.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    render() {
        const {token= undefined,isLoading,operation_mode,paymentMethod} = this.state
        var no_of_unit = Math.floor(this.state.installment_size/this.state.purchase_price)
        if(no_of_unit==0){
            no_of_unit=''
        }
        let gateway_title =this.state.paymentMethod=='bKashMFS'?'Bkash Charge':
                    this.state.paymentMethod=='AMEX'?'American Express Charge':
                    this.state.paymentMethod=='MC'?'Mastercard Charge':
                    this.state.paymentMethod=='VC'?'Visa Charge':
                    this.state.paymentMethod=='MB'?'Rocket Charge':
                    this.state.paymentMethod=='NEXUS'?'Nexus Charge':''

        let charge =paymentMethod=='bKashMFS'?`${this.state.bkash_charge }%`:
                    paymentMethod=='AMEX'?`${this.state.american_charge }%`:
                    paymentMethod=='MC'?`${this.state.master_charge }%`:
                    paymentMethod=='VC'?`${this.state.visa_charge }%`:
                    paymentMethod=='MB'?`${this.state.rocket_charge }%`:
                    paymentMethod=='NEXUS'?`${this.state.nexus_charge }%`:''
                    
        const installment_date_list = [];
        installment_date_list.push(<option key='0' value='' disabled>Select Installment Date</option>)
        for (let i = 5; i <= 25; i=i+5) {
            installment_date_list.push(<option key={i} value={i}>{i}</option>);
        }
      
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                
                {
                    this.state.showApplication==true?<Navigate to="/application-form" replace={false} />:
                    (this.state.showProfile==true?<Navigate to="/investor/profile" replace={false} />:'')
                }
                <Header path="login" auth={this.state.authorization} />
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        {
                            this.state.showLogin==true?
                            <Col className="my-4" md={6} >
                                <Form  className="my-4"  noValidate validated={this.state.validated} onSubmit={(e)=>this.login(e)} >
                                    <Card>
                                        <Card.Body className='my-3'>
                                            <Card.Title>SIGN IN</Card.Title>
                                            <Card.Text>
                                                Login With Your Registered Investor ID
                                            </Card.Text>
                                            <Form.Label htmlFor="investorId"><span>*</span>Investor ID</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="addition">
                                                    AIMS-
                                                </InputGroup.Text>
                                                <Form.Control 
                                                    id="investorId" 
                                                    aria-describedby="addition" 
                                                    name="client_id"
                                                    type="number"
                                                    onChange={(e) => { this.changeState(e) }}
                                                    required
                                                />
                                                <Form.Control.Feedback className={this.state.client_idError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.client_idError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.client_idError==''?'This field is required only number':this.state.client_idError
                                                    }
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                           
                                            <Form.Group className="mb-3">
                                                <Form.Label><span>*</span>Password</Form.Label>
                                                <Form.Control
                                                    name="password"
                                                    type="password"
                                                    onChange={(e) => { this.changeState(e) }}
                                                    required
                                                />
                                                <Form.Control.Feedback className={this.state.passwordError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.passwordError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.passwordError==''?'This field is required':this.state.passwordError
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Card.Text className='danger' align="right">
                                                <Link to="/forgot">Forgot Password?</Link>
                                            </Card.Text>
                                            <Button
                                                className='form-button'
                                                type="submit"
                                                id="nextBtn"
                                            >Login <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                            <Card.Text className='danger mt-3' align="center">
                                                New Investor? <Link to="/register">Register Now</Link>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showFundType==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.fundType(e)}>
                                    <Card>
                                        <Card.Body className='my-3'>
                                            <Card.Title>SELECT FUND NAME & INVESTMENT TYPE</Card.Title>
                                            <Form.Group className="mb-3 mt-3" >
                                                <Form.Label className="fw-bold"><span>*</span>Fund Name</Form.Label>
                                                <Form.Control 
                                                    as="select" 
                                                    onChange={(e) => { this.changeState(e) }} 
                                                    name="fundName" 
                                                    variant="secondary" 
                                                    required
                                                    defaultValue={this.state.fundName}
                                                    >
                                                        <option value='' disabled>Select Fund</option>
                                                    {
                                                        this.state.portfolios.map(portfolio=>{
                                                            return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                        })
                                                    }

                                                </Form.Control>
                                                <Form.Control.Feedback className={this.state.p_a_nidError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.p_a_nidError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.p_a_nidError==''?'This field is required':this.state.p_a_nidError
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label className="fw-bold" ><span>*</span>Investment Type</Form.Label>
                                                <Form.Control as="select" 
                                                    name="investmentType" 
                                                    onChange={(e) => { this.investmentType(e) }} 
                                                    variant="secondary" 
                                                    required 
                                                    defaultValue={this.state.investmentType}
                                                    >
                                                    <option value='' disabled>Select Investment Type</option>
                                                    <option value="2">Single Payment Investment (Non-SIP)</option>
                                                    <option value="1">Systemative Investment Payment (SIP)</option>
                                                    
                                                </Form.Control>
                                                <Form.Control.Feedback className={this.state.p_a_nidError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.p_a_nidError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.p_a_nidError==''?'This field is required':this.state.p_a_nidError
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            
                                            {
                                                (this.state.investmentType == 1) ?
                                                    <div>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Installment Tenure (Years)</Form.Label>
                                                            <Form.Control as="select" 
                                                                name="installment_tenure" 
                                                                onChange={(e)=>{this.changeState(e) }}
                                                                variant="secondary" 
                                                                required 
                                                                defaultValue={this.state.installment_tenure}
                                                                >
                                                                    <option value='' disabled>Select Installment Tenure</option>
                                                                    <option value="1">01</option>
                                                                    <option value="3">03</option>
                                                                    <option value="5">05</option>
                                                                    <option value="7">07</option>
                                                                    <option value="10">10</option>
                                                                    <option value="20">20</option>
                                                            </Form.Control>
                                                                    
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                            <Form.Control.Feedback className="validation" type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Preferred Installment Date</Form.Label>
                                                            <Form.Control as="select" 
                                                                name="installment_date" 
                                                                onChange={(e)=>{this.changeState(e) }}
                                                                variant="secondary" 
                                                                required 
                                                                defaultValue={this.state.installment_date}
                                                                >
                                                                {installment_date_list}
                                                            </Form.Control>
                                                            
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                            <Form.Control.Feedback className="validation" type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Select Payment Frequency</Form.Label>
                                                            <Form.Control as="select" 
                                                                name="paymentFrequency" 
                                                                onChange={(e) => { this.paymentFrequency(e) }} 
                                                                variant="secondary" 
                                                                className="validation payment-frequency" 
                                                                required 
                                                                defaultValue={this.state.paymentFrequency}
                                                                >
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Quarterly">Quarterly</option>
                                                                <option value="Yearly">Yearly</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.p_a_nidError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.p_a_nidError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.p_a_nidError==''?'This field is required':this.state.p_a_nidError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label><span>*</span>Please Select Investor Category Type</Form.Label>
                                                            <Form.Check type="radio" >
                                                                <Form.Check.Input
                                                                    value="micro" 
                                                                    onClick={(e) => { this.investmentCategory(e) }} 
                                                                    type="radio" 
                                                                    name="investmentCategory" />
                                                                <Form.Check.Label className="fw-bold">
                                                                    Micro
                                                                </Form.Check.Label>
                                                                
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Check type="radio" >
                                                                <Form.Check.Input  
                                                                    value="small" 
                                                                    onClick={(e) => { this.investmentCategory(e) }} 
                                                                    type="radio" 
                                                                    name="investmentCategory" />
                                                                <Form.Check.Label className="fw-bold">
                                                                    Small
                                                                </Form.Check.Label>
                                                                
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Check type="radio" >
                                                                <Form.Check.Input  
                                                                    defaultChecked
                                                                    value="general" 
                                                                    onClick={(e) => { this.investmentCategory(e) }} 
                                                                    type="radio" 
                                                                    name="investmentCategory" />
                                                                <Form.Check.Label className="fw-bold">
                                                                    General
                                                                </Form.Check.Label>
                                                                
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Table bordered variant='success' >
                                                            <thead className="table-dark" >
                                                                <tr>
                                                                    <th>INVESTOR CATEGORY</th>
                                                                    <th>PARTICULARS</th>
                                                                    <th>MONTHLY</th>
                                                                    <th>QUARTERLY</th>
                                                                    <th>YEARLY</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td rowSpan={2} className="mt-4" >{this.state.investmentCategory}</td>
                                                                    <td>Minimum Installment</td>
                                                                    <td>BDT {this.state.investmentCategoryMonthly}</td>
                                                                    <td>BDT {this.state.investmentCategoryQuarterly}</td>
                                                                    <td>BDT {this.state.investmentCategoryYearly}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Portfolio Size</td>
                                                                    <td align='center' colSpan={3} >Max BDT {this.state.investmentCategoryMax}</td>

                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Installment Amount</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="number"
                                                                placeholder="Installment Amount"
                                                                name="installment_size"
                                                                value={this.state.installment_size}
                                                                onChange={(e)=>{this.changeState(e) }}
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                            <Form.Control.Feedback className={this.state.installment_size_error!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.installment_size_error
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.installment_size_error==''?'This field is required':this.state.installment_size_error
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Check type="radio" >
                                                                <Form.Check.Input
                                                                    onChange={(e) => this.changeState(e)}
                                                                    defaultChecked={this.state.auto_debit_instructed}
                                                                    name="auto_debit_instructed"
                                                                    type="checkbox" />
                                                                <Form.Check.Label>
                                                                    Auto debit instructed
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </div> :
                                                    <>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>No of Units</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="number"
                                                                placeholder="No of Units"
                                                                name="no_of_unit"
                                                                onChange={(e)=>{this.changeState(e) }}
                                                                onWheel={(e) => e.target.blur()}
                                                                value={no_of_unit}
                                                            />
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                            <Form.Control.Feedback className="validation" type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 bo-account-yes justify-content-end" >
                                                            <Form.Label className='text-end me-2 res-level' column md={5} sm={7}>Investment amount in Tk</Form.Label>
                                                            <Col md={5} sm={4}>
                                                            <Form.Control
                                                                readOnly
                                                                type="number"
                                                                name="installment_size"
                                                                onWheel={(e) => e.target.blur()}
                                                                value={Number(this.state.installment_size).toFixed(2)}
                                                            />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 bo-account-yes justify-content-end" >
                                                            <Form.Label className='text-end me-2 res-level' column md={5} sm={7}>Purchase price per unit in Tk</Form.Label>
                                                            <Col md={5} sm={4}>
                                                            <Form.Control
                                                                readOnly
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                value={this.state.purchase_price}
                                                            />
                                                            </Col>
                                                        </Form.Group>
                                                    </>

                                            }
                                            <Form.Group className="mb-3" >
                                                <Form.Label className="fw-bold" ><span>*</span>Select your preferred method for dividend payment</Form.Label>
                                                <Form.Control as="select" 
                                                    name="dividend_type" 
                                                    onChange={(e) => { this.changeState(e) }} 
                                                    variant="secondary"
                                                    required 
                                                    defaultValue={this.state.dividend_type}
                                                    >
                                                    <option value="CIP">CIP</option>
                                                    <option value="CASH">CASH</option>
                                                    <option value="BOTH">BOTH</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className={this.state.dividend_typeError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.dividend_typeError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.dividend_typeError==''?'This field is required':this.state.dividend_typeError
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {
                                                this.state.dividend_type=='BOTH'?
                                                <>
                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                        <Form.Label><span>*</span>CIP Percentage</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            placeholder="CIP Percentage"
                                                            name="cip_percentage"
                                                            value={this.state.cip_percentage}
                                                            onChange={(e)=>{this.changeState(e) }}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                        <Form.Control.Feedback className={this.state.cip_cash_error!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.cip_cash_error
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.cip_cash_error==''?'This field is required':this.state.cip_cash_error
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                        <Form.Label><span>*</span>CASH Percentage</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            placeholder="CASH Percentage"
                                                            name="cash_percentage"
                                                            value={this.state.cash_percentage}
                                                            onChange={(e)=>{this.changeState(e) }}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                        <Form.Control.Feedback className={this.state.cip_cash_error!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.cip_cash_error
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.cip_cash_error==''?'This field is required':this.state.cip_cash_error
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </>:''
                                            }
                                            <Form.Group className="mb-3 bo-account-no border-top border-bottom my-4" >
                                                    <Card.Text className='danger fw-bold  ' align="left">
                                                        <span className='d-inline-block danger pt-3 opacity-75'>Note:</span>
                                                    </Card.Text>
                                                    <Card.Text className="fw-light" align="left">
                                                        * Moratorium period of 90 days after purchase will be applicable for sale/surrender.
                                                    </Card.Text>
                                                    <Card.Text className="fw-light pb-3" align="left">
                                                        ** Any purchases after 4 PM will have the purchase date of next working day.
                                                    </Card.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3 bo-account-no " >
                                                    <div className="form-check">
                                                        <input required className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            I have read, understood and agreed to the <Link to="/terms-condition">TERMS & CONDITIONS</Link> as provided in this website.
                                                        </label>
                                                    </div>
                                                    <Form.Control.Feedback className={this.state.p_a_nidError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.p_a_nidError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.p_a_nidError==''?'This field is required':this.state.p_a_nidError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3 bo-account-no " >
                                                    <div className="form-check">
                                                        <input required className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            I/We have read and understood the contents of the Prospectus of the Scheme of Grameen Bank-AIMS First
                                                            Unit Fund and agree to be bound and governed by the same on availing/using the offer. <Link to="/prospectus">DETAILS...</Link>
                                                        </label>
                                                    </div>
                                                    <Form.Control.Feedback className={this.state.p_a_nidError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.p_a_nidError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.p_a_nidError==''?'This field is required':this.state.p_a_nidError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            <Button
                                                className='form-button'
                                                type="submit"
                                                id="nextBtn"
                                            >Continue Process <FontAwesomeIcon icon={faArrowAltCircleRight} />
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showBo==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.hasBoAccount(e)}>
                                    <Card className='common-margin'>
                                        <Card.Body className='my-3'>
                                            <Card.Title className="mb-4">Do you have any BO account?</Card.Title>
                                            <Form.Group className="mb-3" >
                                                <Form.Check
                                                    defaultChecked
                                                    inline
                                                    name="boStatus"
                                                    type="radio"
                                                    label="Yes"
                                                    value="yes"
                                                    className="validation"
                                                    onClick={(e) => { this.boCheck(e, 'yes') }}
                                                    onChange={(e)=>{this.changeState(e) }}
                                                />
                                                <Form.Check
                                                    inline
                                                    name="boStatus"
                                                    type="radio"
                                                    label="No"
                                                    value="no"
                                                    onClick={(e) => { this.boCheck(e, 'no') }}
                                                    onChange={(e)=>{this.changeState(e) }}
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback className="validation" type="invalid">
                                                    Please select one.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3 bo-account-yes " >
                                                <Form.Label><span>*</span>Provide BO Account No</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="BO Account No"
                                                    className="validation"
                                                    name="boAccountNo"
                                                    onChange={(e)=>{this.changeState(e) }}
                                                    value={this.state.boAccountNo}
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback className="validation" type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3 bo-account-no hide" >
                                                <Card.Text className='danger' align="left">
                                                    You will need BO account no to proceed further. <a href='https://cdbl.com.bd/bo/bo_portal.php'>Click Here</a> to create your BO account.
                                                </Card.Text>
                                                <Card.Text className='danger' align="left">
                                                    <span className='danger'>Note:</span> You will be leaving this page and will be redirected to CDBL website. But you can resume your journey after you have your BO account no using ‘Login’
                                                </Card.Text>
                                            </Form.Group>
                                            <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3 center-align">
                                                    <Button
                                                        className='form-button my-4'
                                                        type="submit"
                                                        onClick={(event) => { this.showFundTypeAgain(event) }}
                                                    ><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back </Button>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3 center-align">
                                                    <Button
                                                        className='form-button float-end my-4'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Proceed to Invest <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showSip==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4">
                                    <div className='common-margin'>
                                        <h1 className='mb-4' align="center" >INVESTMENT DETAILS</h1>
                                        <ListGroup className='my-4 investment-details'>
                                            <ListGroup.Item className="border-0 pt-3" variant="light">
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>Fund Name</span>
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>{this.state.portfolio_name}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0" variant="light">
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>Investment Type</span>
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>{this.state.investmentType==1?'Systemative Investment Payment (SIP)':'Single Payment Investment (Non-SIP)'}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0" variant="light">
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>Buy Price Per Unit</span>
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}><span className='text-danger'>৳</span> {this.state.purchase_price}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0" variant="light">
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>Total purchase amount</span>
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}><span className='text-danger'>৳</span> {Number(this.state.installment_size).toFixed(2)}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 pb-3" variant="light">
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>Investor Category</span>
                                                <span className="col-md-6 text-center" style={{ display: "inline-block" }}>{operation_mode==1?"Single":(operation_mode==2?"Joint":"Institution")}</span>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3 center-align">
                                                    <Button
                                                        className='form-button my-4'
                                                        type="submit"
                                                        onClick={(event) => { this.showBoAgain(event) }}
                                                    ><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back </Button>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3 center-align">
                                                    <Button
                                                        className='form-button float-end my-4'
                                                        type="submit"
                                                        id="nextBtn"
                                                        onClick={(event) => { this.showPayment(event) }}
                                                    >Proceed to Invest <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </Col>
                            :''
                        }
                        {
                            this.state.showPayment==true?
                            <Col className="my-4" md={6}>
                                <Form className="my-4">
                                    <Card>
                                        <Card.Body className='payment-option'>
                                            <Card.Title className='fs-3'>PAYMENT OPTION</Card.Title>
                                            <Card.Title className='fw-bold fs-6 my-4'>You can pay any of the following ways</Card.Title>
                                            <Form.Group className="mb-3" >
                                                <Form.Check type="radio" >
                                                    <Form.Check.Input value="online" onChange={(e) => this.changeState(e)} type="radio" name="paymentType" />
                                                    <Form.Check.Label className="fw-bold">
                                                        Online Payment Gateway
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <p>Transaction charges will be applicable.</p>
                                            </Form.Group>
                                            <Card className={`col-12 mx-auto ${this.state.paymentType == `online` ? `show`:`hide` }`}>
                                                <Card.Body>
                                                    <Form.Group className="mb-3 bo-account-no  border-bottom" >
                                                        <Card.Text className="fw-light" align="left">
                                                            <span>Note:</span> Due to Bangladesh Bank restrictions, if your transaction profile with your bank is not updated;
                                                            you may face difficulty to pay premium more than Tk 50,000 in a single transaction:
                                                        </Card.Text>
                                                        <Card.Text className="fw-light pb-3" align="left">
                                                            *If your transaction profile is updated for required transaction, simply proceed by selecting your payment method
                                                            and click "Pay" below.
                                                        </Card.Text>
                                                    </Form.Group>
                                                    <Card>
                                                        <Card.Body className='payment-gateway-card'>
                                                            <Card.Title>Pay with</Card.Title>
                                                            <ListGroup horizontal className='my-4 investment-details flex-wrap'>
                                                                <ListGroup.Item className="border-0" variant="light">
                                                                    <Form.Group controlId="bkash">
                                                                        <Form.Check className='payement-gateway' type="radio" >
                                                                            <Form.Check.Input defaultChecked value="bKashMFS" onChange={(e) => this.changeState(e)} type="radio" name="paymentMethod" />
                                                                            <Form.Check.Label className="fw-bold">
                                                                                <Image
                                                                                    src={bkash}
                                                                                    className="d-inline-block align-top logo"
                                                                                    alt="bkash"
                                                                                />
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item className="border-0" variant="light">
                                                                    <Form.Group controlId="rocket">
                                                                        <Form.Check className='payement-gateway' type="radio" >
                                                                            <Form.Check.Input value="MB" onChange={(e) => this.changeState(e)} type="radio" name="paymentMethod" />
                                                                            <Form.Check.Label className="fw-bold">
                                                                                <Image
                                                                                    src={rocket}
                                                                                    className="d-inline-block align-top logo"
                                                                                    alt="rocket"
                                                                                />
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item className="border-0" variant="light">
                                                                    <Form.Group controlId="nexus">
                                                                        <Form.Check className='payement-gateway' type="radio" >
                                                                            <Form.Check.Input value="NEXUS" onChange={(e) => this.changeState(e)} type="radio" name="paymentMethod" />
                                                                            <Form.Check.Label className="fw-bold">
                                                                                <Image
                                                                                    src={nexus}
                                                                                    className="d-inline-block align-top logo"
                                                                                    alt="nexus"
                                                                                />
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item className="border-0" variant="light">
                                                                    <Form.Group controlId="master">
                                                                        <Form.Check className='payement-gateway' type="radio" >
                                                                            <Form.Check.Input value="MC" onChange={(e) => this.changeState(e)} type="radio" name="paymentMethod" />
                                                                            <Form.Check.Label className="fw-bold">
                                                                                <Image
                                                                                    src={master}
                                                                                    className="d-inline-block align-top logo"
                                                                                    alt="master"
                                                                                />
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item className="border-0" variant="light">
                                                                    <Form.Group controlId="visa">
                                                                        <Form.Check className='payement-gateway' type="radio" >
                                                                            <Form.Check.Input value="VC" onChange={(e) => this.changeState(e)} type="radio" name="paymentMethod" />
                                                                            <Form.Check.Label className="fw-bold">
                                                                                <Image
                                                                                    src={visa}
                                                                                    className="d-inline-block align-top logo"
                                                                                    alt="Visa"
                                                                                />
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item className="border-0 hide" variant="light">
                                                                    <Form.Group controlId="amex">
                                                                        <Form.Check className='payement-gateway' type="radio" >
                                                                            <Form.Check.Input value="AMEX" onChange={(e) => this.changeState(e)} type="radio" name="paymentMethod" />
                                                                            <Form.Check.Label className="fw-bold">
                                                                                <Image
                                                                                    src={amex}
                                                                                    className="d-inline-block align-top logo"
                                                                                    alt="amex"
                                                                                />
                                                                            </Form.Check.Label>
                                                                        </Form.Check>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </Card.Body>
                                                    </Card>
                                                </Card.Body>
                                                <ListGroup className='my-4'>
                                                    <Table className='text-center'>
                                                        <thead>
                                                            <th>Gateway</th>
                                                            <th>Service Fee</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td width="50%" >Bkash</td>
                                                                <td width="50%">{this.state.bkash_charge}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" >Rocket</td>
                                                                <td width="50%">{this.state.rocket_charge}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" >NexusPay</td>
                                                                <td width="50%">{this.state.nexus_charge}%</td>
                                                            </tr>
                                                            <tr className='hide'>
                                                                <td width="50%" >American Express</td>
                                                                <td width="50%">{this.state.american_charge}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" >Mastercard</td>
                                                                <td width="50%">{this.state.master_charge}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" >Visa</td>
                                                                <td width="50%">{this.state.visa_charge}%</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </ListGroup>
                                            </Card>
                                            <Form.Group className="mb-3" >
                                                <Form.Check type="radio" >
                                                    <Form.Check.Input defaultChecked value="beftn" onChange={(e) => this.changeState(e)} type="radio" name="paymentType" />
                                                    <Form.Check.Label className="fw-bold">
                                                        Online Bank Deposit
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <p>Must be deposited in the following mentioned account. <span className='fw-bold'>
                                                    Screenshot of Transaction Receipt will be required.</span></p>
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Check className="validation" type="radio" >
                                                    <Form.Check.Input value="cheque" onChange={(e) => this.changeState(e)} type="radio" name="paymentType" />
                                                    <Form.Check.Label className="fw-bold">
                                                        Offline Bank Deposit
                                                    </Form.Check.Label>
                                                    
                                                </Form.Check>
                                                <p>Must be deposited in the following mentioned account.
                                                    <span className='fw-bold'>Scanned/photo of Deposit Slip will be required.</span>
                                                </p>
                                                <Card className={`col-8 mx-auto ${this.state.paymentType == `beftn` ? `show` :(this.state.paymentType == `cheque`?`show`:`hide`) }`}>
                                                    <Card.Body>
                                                        <ListGroup>
                                                            <ListGroup.Item className="border-0" variant="light">
                                                                <span className="col-md-4 text-start" style={{ display: "inline-block" }}>Account No:</span>
                                                                <span className="col-md-8 text-center" style={{ display: "inline-block" }}>{this.state.account_no}</span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0" variant="light">
                                                                <span className="col-md-4 text-start" style={{ display: "inline-block" }}>Bank:</span>
                                                                <span className="col-md-8 text-center" style={{ display: "inline-block" }}>{this.state.bank_name}</span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0" variant="light">
                                                                <span className="col-md-4 text-start" style={{ display: "inline-block" }}>Branch:</span>
                                                                <span className="col-md-8 text-center" style={{ display: "inline-block" }}>{this.state.branch_name}</span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0" variant="light">
                                                                <span className="col-md-4 text-start" style={{ display: "inline-block" }}>Routing No:</span>
                                                                <span className="col-md-8 text-center" style={{ display: "inline-block" }}>{this.state.routing_no}</span>
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Card.Body>
                                                </Card>
                                            </Form.Group>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 center-align">
                                                        <Button
                                                            className='form-button my-4'
                                                            type="submit"
                                                            onClick={(event) => { this.showSipAgain(event) }}
                                                        ><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back </Button>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 center-align">
                                                        <Button
                                                            className='form-button my-4 float-end'
                                                            type="submit"
                                                            id="nextBtn"
                                                            onClick={(event) => { this.payment(event) }}
                                                        >Proceed <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                            :''
                        }
                        
                        {
                            this.state.showOfflineDeposit==true?
                            <Col className="my-4" lg={6} md={8} >
                                <Form className="my-4">
                                    <Card className='tab file'>
                                        <Card.Body>
                                            <Card.Title className='fs-3'>
                                            {
                                                this.state.paymentType=='beftn'?
                                                    'ONLINE BANK DEPOSIT':'OFFLINE BANK DEPOSIT'
                                            } 
                                            </Card.Title>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Please Upload The Deposit Slip</Form.Label>
                                                <div className="file-upload">
                                                    <Form.Control onChange={(e) => { this.changeState(e) }} id="fileUpload" name="deposit_receipt" type="file" />
                                                    <div className="file-text">
                                                        <p>Drag & Drop File</p>
                                                        <h3>Or</h3>
                                                        <a href="#">Browse</a>
                                                    </div>
                                                </div>
                                                <p className={this.state.browseFile.length > 0 ? 'show' : 'hide'} id="fileName">{this.state.browseFile}</p>
                                            </Form.Group>
                                            <Row style={this.state.browseFile.length == 0 ? { marginTop: '290px' } : {}}>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 center-align">
                                                        <Button
                                                            className='form-button'
                                                            type="submit"
                                                            onClick={(event) => { this.showPaymentAgain(event) }}
                                                        ><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back </Button>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 center-align">
                                                        <Button
                                                            className={this.state.browseFile.length > 0 ? 'form-button float-end show' : 'form-button float-end hide'}
                                                            type="submit"
                                                            id="nextBtn"
                                                            onClick={(event) => { this.offlineDeposit(event) }}
                                                        >Upload <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                           
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                            :''
                        }
                        {
                            this.state.showPaymentSuccess==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4">
                                    <Card className='tab'>
                                        <Card.Body id="print">
                                            <p className="faCircleCheck mb-3" align="center" ><FontAwesomeIcon icon={faCircleCheck} /></p>
                                            <h1 className='mb-4' align="center" >Submitted Successfully!</h1>
                                            <ListGroup className='my-4 client-details'>
                                                <Table className='text-center'>
                                                    <tbody>
                                                        <tr>
                                                            <td width="50%" >{operation_mode==1?"Investor Name:":(operation_mode==2?"Investor Name:":"Institution Name")}</td>
                                                            <td width="50%">{operation_mode==1?this.state.investor_name:(operation_mode==2?this.state.investor_name:this.state.institution_name)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%" >Investor ID:</td>
                                                            <td width="50%">{'AIMS-'+this.state.client_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%" >BO Account No:</td>
                                                            <td width="50%">{this.state.boAccountNo}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%" >Fund Name:</td>
                                                            <td width="50%">{this.state.portfolio_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%" >Buy Price Per Unit:</td>
                                                            <td width="50%"><span className='text-danger'>৳</span> {this.state.purchase_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%" >No of Units Purchased:</td>
                                                            <td width="50%">{no_of_unit}</td>
                                                        </tr>
                                                        {
                                                            this.state.investmentType==2?
                                                            <tr>
                                                                <td width="50%" >Investment Amount:</td>
                                                                <td width="50%"><span className='text-danger'>৳</span> {this.state.installment_size}</td>
                                                            </tr>:
                                                             <tr>
                                                                <td width="50%" >Installment Amount:</td>
                                                                <td width="50%"><span className='text-danger'>৳</span> {this.state.installment_size}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td width="50%" >Investor's Balance:</td>
                                                            <td width="50%"><span className='text-danger'>৳</span> {this.state.installment_size}</td>
                                                        </tr>
                                                        {
                                                            this.state.paymentType=='online'?
                                                            <>
                                                                <tr>
                                                                    <td width="50%" >{gateway_title}:</td>
                                                                    <td width="50%">
                                                                        {charge}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="50%" >Adjusted total Amount:</td>
                                                                    <td width="50%">{this.state.installment_size+(this.state.installment_size*(parseFloat(charge)/100))}</td>
                                                                </tr>
                                                            </>:''
                                                        }
                                                        
                                                        <tr>
                                                            <td width="50%" >Payment Method:</td>
                                                            <td width="50%">
                                                                {
                                                                    this.state.paymentType=='beftn'?
                                                                        'Online Bank Deposit':
                                                                    this.state.paymentType=='online'?
                                                                        'Online Payment Gateway'
                                                                    :
                                                                        'Offline Bank Deposit'
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%" >Investment Type:</td>
                                                            <td width="50%">{this.state.investmentType==2?'Single Payment Investment (Non-SIP)':'Systemative Investment Payment (SIP)'}</td>
                                                        </tr>
                                                        {
                                                            this.state.investmentType==1?
                                                            <>
                                                                <tr>
                                                                    <td width="50%" >SIP Tenure(Years):</td>
                                                                    <td width="50%">{this.state.installment_tenure}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="50%" >Installment Date:</td>
                                                                    <td width="50%">{this.state.installment_date}</td>
                                                                </tr>
                                                            </>:''
                                                        }
                                                        <tr>
                                                            <td colSpan={2} onClick={(e)=>this.getPdf(e)} style={{cursor:"pointer"}} className="border-0 pb-3 vanis" variant="light">
                                                                <FontAwesomeIcon icon={faCloudArrowDown} /> Download & Save
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </ListGroup>
                                            <Card className='my-4'>
                                                <Card.Body>
                                                    <Card.Text align="start" className='fw-bold'>
                                                        To complete the application, please ensure you have following documents on you:
                                                    </Card.Text>
                                                    <ListGroup className='my-4'>
                                                        <ListGroup.Item className="border-0" variant="light">
                                                            1.ID Document (Both Applicatant and Nominee).
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className="border-0" variant="light">
                                                            2.Photo (Both Applicatant and Nominee).
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className="border-0" variant="light">
                                                            3.E-Signature (Both Applicatant and Nominee).
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className="border-0" variant="light">
                                                            4.Application's E-tin.
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Card.Body>
                                            </Card>
                                            <div className='printHidden vanis'>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3 center-align">
                                                            <Button
                                                                className='form-button'
                                                                type="submit"
                                                                onClick={(event) => { this.offlineDepositAgain(event) }}
                                                            ><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back </Button>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3 center-align float-end">
                                                            <Button
                                                                className='form-button'
                                                                type="submit"
                                                                id="nextBtn"
                                                                onClick={(event) => { this.loginPurchase(event) }}
                                                            >Complete Application <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                              
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                            :''
                        }
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}