import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './components/investment/login'
import Register from './components/auth/register'
import Logout from './components/auth/logout'
import ApplicationForm from './components/account/applicationForm'
import Forgot from './components/auth/forgot'
import Profile from './components/investor/profile'
import ProfileChange from './components/investor/profileChange'
import Purchase from './components/investor/purchase'
import Surrender from './components/investor/surrender'
import ChangePassword from './components/investor/changePassword'
import PortfolioStatement from './components/reports/portfolioStatement'
import TransactionStatement from './components/reports/transactionStatement'
import UnitAllocationStatement from './components/reports/unitAllocationStatement'
import GainlossStatement from './components/reports/gainlossStatement'
import NavHistoryStatement from './components/reports/navHistoryStatement'
import SurrenderStatement from './components/reports/surrenderStatement'
import TaxCertificateStatement from './components/reports/taxCertificateStatement'
import CreateNewAccount from './components/account/create_new_account'
import PaymentSuccess from './components/investor/payment_success';
import Prospectus from './components/auth/prospectus';
import TermsCond from './components/auth/termscond';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
     <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/logout" element={<Logout/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/application-form" element={<ApplicationForm/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
        <Route path="/investor/profile" element={<Profile/>}/>
        <Route path="/investor/change-profile" element={<ProfileChange/>}/>
        <Route path="/purchase" element={<Purchase/>}/>
        <Route path="/surrender" element={<Surrender/>}/>
        <Route path="/change-password" element={<ChangePassword/>}/>
        <Route path="/report/portfolio-statement" element={<PortfolioStatement/>}/>
        <Route path="/report/transactionStatement" element={<TransactionStatement/>}/>
        <Route path="/report/unit-allocation-statement" element={<UnitAllocationStatement/>}/>
        <Route path="/report/gainloss-statement" element={<GainlossStatement/>}/>
        <Route path="/report/nav-history-statement" element={<NavHistoryStatement/>}/>
        <Route path="/report/surrender-statement" element={<SurrenderStatement/>}/>
        <Route path="/report/tax-certificate-statement" element={<TaxCertificateStatement/>}/>
        <Route path="/create-new-account" element={<CreateNewAccount/>}/>
        <Route path="/payment-success" element={<PaymentSuccess/>}/>
        <Route path="/prospectus" element={<Prospectus/>}/>
        <Route path="/terms-condition" element={<TermsCond/>}/>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
