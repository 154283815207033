module.exports = global.config = {
    data: {
        files: {
            logo: require('../../assets/img/logo-aims.png'),
            bkash: require('../../assets/img/bkash.png'),
            amex: require('../../assets/img/amex.png'),
            master: require('../../assets/img/master.png'),
            visa: require('../../assets/img/visa.png'),
            rocket: require('../../assets/img/rocket.png'),
            nexus: require('../../assets/img/nexus.png'),
        },
        path:{
            //basePath:window.atob('aHR0cDovLzEyNy4wLjAuMTo4MDAw') //Local
            //basePath:window.atob('aHR0cDovLzEwLjEwLjEwLjQyOjgwMDA=') //Local IP
            basePath:window.atob('aHR0cHM6Ly9iYWNrb2ZmaWNlLmFpbXMtYmFuZ2xhZGVzaC5jb206ODAwMQ==')
            
        },
        info:{
            name:window.atob('QUlNUyBvZiBCYW5nbGFkZXNoIExpbWl0ZWQ='),
            phone:window.atob('Kyg4ODAtMikgNDgxIDIwNTU4LTU5'),
            mobile:window.atob('Kzg4MDEzMjcyNjY3Njc='),
            fax:window.atob('Kyg4ODAtMikgNDgxIDEyNjUyLTUz'),
            email:window.atob('aGVsbG9AYWltcy1iYW5nbGFkZXNoLmNvbQ=='),
            address:window.atob('VW5pcXVlIFRyYWRlIENlbnRlciAoVVRDKSwgTGV2ZWwgNiwgOCBQYW50aGFwYXRoLCBLYXJ3YW4gQmF6YWFyLCBEaGFrYSAxMjE1LCBCYW5nbGFkZXNo'),
            web:window.atob('aHR0cHM6Ly93d3cuYWltcy1iYW5nbGFkZXNoLmNvbS8='),
            facebook:window.atob('aHR0cHM6Ly93d3cuZmFjZWJvb2suY29tL2dyb3Vwcy9haW1zLmJhbmdsYWRlc2gvP21pYmV4dGlkPTZOb0NEVw=='),
            instragram:window.atob('aHR0cHM6Ly93d3cuaW5zdGFncmFtLmNvbS8='),
            linkedin:window.atob('aHR0cHM6Ly93d3cubGlua2VkaW4uY29tL2NvbXBhbnkvYWltcy1vZi1iYW5nbGFkZXNoLWxpbWl0ZWQvYWJvdXQv'),
        },
        dev:{
            name:window.atob('dW5pc29mdCBzeXN0ZW1zIGxpbWl0ZWQ='),
            web:window.atob('aHR0cHM6Ly91c2xiZC5jb20v')
        },
        date:{
            date_format:'DD-MM-YYYY',
            date_format_y_m_d:'YYYY-MM-DD'
        }
    }
};