import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Loading from '../loading'
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const logo=global.config.data.files.logo
const baseUrl=global.config.data.path.basePath
const name=global.config.data.info.name
const email=global.config.data.info.email
const mobile=global.config.data.info.mobile
const address=global.config.data.info.address
const web=global.config.data.info.web
const date_format=global.config.data.date.date_format
const date_format_y_m_d=global.config.data.date.date_format_y_m_d
export default class TransactionStatement extends Component {
    state = {
        validated: false,
        authorization:false,
        showTransaction:false,
        token:'',
        portfolios:[],
        user:[],
        account_list:[],

        fund:'',
        portfolio_code:'',
        investor_account_id:'',
        investment_type_id:'',
        date:'',
        e_date:'',

        openingBalanceUnitResult:[],
        fundInfo:[],
        transactionHistoryResult:[],

        fundError:'',
        portfolio_codeError:'',
        dateError:'',
        e_dateError:'',
        isLoading: true
    }
    changeState=(e)=>{
        if(e.target.name=='fund'){
            this.getAccountList(e.target.value,this.state.token)
        }else if(e.target.name=='portfolio_code'){
            const selectedOption = e.target.options[e.target.selectedIndex]
            this.setState({
                investment_type_id:selectedOption.dataset.investmentTypeId,
                investor_account_id:selectedOption.dataset.investorAccountId
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeDate=(name,value)=>{
        this.setState({
            [name]:dayjs(value).format(date_format_y_m_d),
            [name+'Error']:''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    componentDidMount(){
        this.setState({
            fund:''
        })
        document.title = 'Transaction statement'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                authorization:true,
                isLoading: false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    isLoading: false
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.data.length>0)
                this.getAccountList(res.data.data[0].PORTFOLIO_ID,this.state.token)
            this.setState({
                portfolios:res.data.data,
                fund:res.data.data[0].PORTFOLIO_ID
            })
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    portfolio_code:res.data.data[0].PORTFOLIO_CODE,
                    investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID,
                    investment_type_id:res.data.data[0].INVESTMENT_TYPE_ID
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    transactionReport=(e)=>{
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid ||this.state.date=='' ||this.state.e_date==''){
            if(this.state.date==''){
                this.setState({
                    dateError:'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    if(i==0){
                        elements[i].style.border = "1px solid red";
                        break;
                    }
                }
            }
            if(this.state.e_date==''){
                this.setState({
                    e_dateError:'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    if(i==1){
                        elements[i].style.border = "1px solid red";
                        break;
                    }
                }
            }
            return
        }
        this.setState({
            isLoading:true
        })
        let data = {
            fund:this.state.fund,
            portfolio_code:this.state.portfolio_code,
            investor_account_id:this.state.investor_account_id,
            investment_type_id:this.state.investment_type_id,
            date:this.state.date,
            e_date:this.state.e_date,
        }
        console.log(data)
        const url = baseUrl+'/api/transaction-report'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    errorStatus:res.data.status,
                    showTransaction:true,
                    openingBalanceUnitResult:res.data.data.openingBalanceUnitResult[0],
                    fundInfo:res.data.data.fundInfo[0],
                    transactionHistoryResult:res.data.data.transactionHistoryResult
                }) 
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false

    }
    getPdf=(e)=>{
        e.preventDefault()
        this.setState({
            isLoading:true
        })

        const url = baseUrl+'/api/transaction-report'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:this.state.fund, investor_account_id:this.state.investor_account_id,investment_type_id:this.state.investment_type_id,date:this.state.date,e_date:this.state.e_date}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("transactionReport");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    render() {
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        var openingBalanceUnitValue         = this.state.openingBalanceUnitResult.TOTAL_PUR - this.state.openingBalanceUnitResult.TOTAL_SUR
        var summationOfBuyQuantityValue = 0;
        var summationOfBuyAmountTotal = 0;
        var summationOfSurrQuantityValue = 0;
        var summationOfSurrAmountTotal = 0;
        var summationOfDevQuantity = 0;
        var fundName = this.state.fundInfo.PORTFOLIO_NAME;
      
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.transactionReport(e)}>
                                    <Table variant="light" striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td>Name of the Investor</td>
                                                <td>{this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Mutual Fund</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            name="fund" 
                                                            variant="secondary" 
                                                            required
                                                            defaultValue={this.state.fund}
                                                            >
                                                                {/* <option value='' disabled>Select fund</option> */}
                                                            {
                                                                this.state.portfolios.map(portfolio=>{
                                                                    return <option 
                                                                        value={portfolio.PORTFOLIO_ID} 
                                                                        key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                                })
                                                            }

                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fundError==''?'This field is required':this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Registration No.</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control as="select" 
                                                            name="portfolio_code" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            variant="secondary" 
                                                            defaultValue={this.state.portfolio_code}
                                                            required 
                                                            >
                                                                {/* <option value='' disabled>Select reg. no.</option> */}
                                                            {
                                                                this.state.account_list.map(accountlist=>{
                                                                    return <option 
                                                                                value={accountlist.PORTFOLIO_CODE} 
                                                                                key={accountlist.INVESTOR_ACCOUNT_ID}
                                                                                data-investor-account-id={accountlist.INVESTOR_ACCOUNT_ID}
                                                                                data-investment-type-id={accountlist.INVESTMENT_TYPE_ID}
                                                                            >
                                                                                {accountlist.PORTFOLIO_CODE}
                                                                            </option>  
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.portfolio_codeError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.portfolio_codeError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.portfolio_codeError==''?'This field is required':this.state.portfolio_codeError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Transaction Date</td>
                                                <td>
                                                    <Row>
                                                        <Col md={5}>
                                                            <Form.Group controlId="formPlaintextEmail">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        className="formPlaintextEmail"
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('date',value) }} 
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'date'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                <Form.Control.Feedback className={this.state.dateError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.dateError==''?'This field is required':this.state.dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2} align="center" className="fw-bold text-secondary">
                                                            To
                                                        </Col>
                                                        <Col md={5} >
                                                            <Form.Group controlId="formPlaintextEmail">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        className="formPlaintextEmail"
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('e_date',value) }} 
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'e_date'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                
                                                                <Form.Control.Feedback className={this.state.e_dateError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.e_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.e_dateError==''?'This field is required':this.state.e_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="end" colSpan={2}>
                                                    <Button
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                        {
                            this.state.showTransaction==true?
                            <>
                            <div className='text-end vanis'>
                                <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.print(e)} icon={faPrint} /></a> 
                                <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.getPdf(e)} icon={faFilePdf} /></a>
                            </div>
                            <div id="transactionReport">
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <p className="fw-bold">
                                        <Image
                                                src={logo}
                                                className="d-inline-block align-top print-logo"
                                                alt={name}

                                            />
                                        </p>
                                        <Table className="tax-certificate" variant="light" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <td colSpan={7} align="center">Transaction History of {fundName}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} align="start">Investor Name: {this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                                    <td colSpan={3} align="end">
                                                        Investor ID: {this.state.user.client_id}<br/>
                                                        Registration No.: {this.state.portfolio_code}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>Buy Quantity</td>
                                                    <td>Surrender Quantity</td>
                                                    <td>Dividend (CIP)</td>
                                                    <td>Unit Price</td>
                                                    <td>Total Amount</td>
                                                    <td>Ledger Balance of Units</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.transactionHistoryResult.map(transactionHistory=>{
                                                        if(transactionHistory.SELL_SURR_FLAG=='Sell'){
                                                            openingBalanceUnitValue += transactionHistory.Buy_Quantity
                                                            summationOfBuyQuantityValue += transactionHistory.Buy_Quantity
                                                            summationOfBuyAmountTotal += transactionHistory.TOTAL_AMOUNT
                                                        }else if(transactionHistory.SELL_SURR_FLAG=='Surr'){
                                                            openingBalanceUnitValue += transactionHistory.Buy_Quantity
                                                            summationOfSurrQuantityValue += transactionHistory.Buy_Quantity
                                                            summationOfSurrAmountTotal += transactionHistory.TOTAL_AMOUNT
                                                        }else{
                                                            openingBalanceUnitValue += transactionHistory.Buy_Quantity
                                                            summationOfDevQuantity += transactionHistory.Buy_Quantity;
                                                        }
                                                        return <>
                                                                {   (
                                                                        transactionHistory.SELL_SURR_FLAG=='Sell'?
                                                                        <tr>
                                                                            <td>{transactionHistory.TRANSACTION_DATE}</td>
                                                                            <td>{transactionHistory.Buy_Quantity}</td>
                                                                            <td>0</td>
                                                                            <td>0</td>
                                                                            <td>{transactionHistory.RATE}</td>
                                                                            <td>{transactionHistory.TOTAL_AMOUNT}</td>
                                                                            <td>{openingBalanceUnitValue}</td>
                                                                        </tr>:
                                                                        (transactionHistory.SELL_SURR_FLAG=='Surr'?
                                                                            <tr>
                                                                                <td>{transactionHistory.TRANSACTION_DATE}</td>
                                                                                <td>0</td>
                                                                                <td>{transactionHistory.Buy_Quantity}</td>
                                                                                <td>0</td>
                                                                                <td>{transactionHistory.RATE}</td>
                                                                                <td>{transactionHistory.TOTAL_AMOUNT}</td>
                                                                                <td>{openingBalanceUnitValue}</td>
                                                                            </tr>:
                                                                            <tr>
                                                                                <td>{transactionHistory.TRANSACTION_DATE}</td>
                                                                                <td>{transactionHistory.Buy_Quantity}</td>
                                                                                <td>{transactionHistory.RATE}</td>
                                                                                <td>{transactionHistory.Buy_Quantity}</td>
                                                                                <td>{transactionHistory.TOTAL_AMOUNT}</td>
                                                                                <td>0</td>
                                                                                <td>{openingBalanceUnitValue}</td>
                                                                            </tr>
                                                                        )
                                                                    )
                                                                }
                                                            </>
                                                    })
                                                }
                                                <tr>
                                                    <td>Total</td>
                                                    <td>{summationOfBuyQuantityValue}</td>
                                                    <td>{summationOfSurrQuantityValue}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Closing Balance of Units</td>
                                                    <td>{openingBalanceUnitValue}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className='text-end' colSpan={7}>
                                                        <p className="fw-bold text-secondary">{name}</p>
                                                        <p className="text-secondary">{address}</p>
                                                        <p>E-mail: {email}, Website: {web}</p>
                                                        <p>Mobile: {mobile}</p>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </div></>
                            :''
                        }
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}