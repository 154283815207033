import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import '../constants/env';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Header from '../header'
import Footer from '../footer'
import Loading from '../loading'
import Swal from 'sweetalert2'
import Select from 'react-select'
const baseUrl = global.config.data.path.basePath
const date_format = global.config.data.date.date_format
const date_format_y_m_d = global.config.data.date.date_format_y_m_d
export default class CreateNewAccount extends Component {
    state = {
        validated: false,
        authorization: false,
        token: '',
        errorStatus: '',
        isLoading: true,
        portfolios: [],
        sip_exits: false,
        non_sip_exits: false,
        portfolio: '',
        portfolioError: '',
        investment_type: '',
        investment_typeError: '',
        banks: [],
        bank: '',
        bankError: '',
        branches: [],
        branch: '',
        branchError:'',
        account_no: '',
        account_noError: '',
        account_name: '',
        account_nameError: '',
        installment_size: '',
        installment_sizeError: '',
        purchase_price: '',
        purchase_priceError: '',
        no_of_unitError: '',
        installment_tenure: '',
        installment_tenureError: '',
        installment_date: '',
        installment_dateError: '',
        dividend: '',
        dividendError: '',
        cip_percentage: '',
        cip_percentageError: '',
        cash_percentage: '',
        cash_percentageError: '',
        payment_method: '',
        payment_methodError: '',
        bkash_change:1.3,
        visa_charge:1.6,
        master_charge:1.6,
        nexus_charge:1,
        rocket_charge:1,
        american_charge:1,
        payment_instrument_date: '',
        payment_instrument_dateError: '',
        payment_bank:'',
        payment_bankError: '',
        payment_branch:'',
        payment_branchError: '',
        payment_reference_no: '',
        payment_reference_noError: '',
        deposit_recipt: '',
        deposit_reciptError: '',
        nominee_name: Array(1).fill(''),
        nominee_nameError: Array(1).fill(''),
        nominee_dob: Array(1).fill(''),
        nominee_father_name: Array(1).fill(''),
        nominee_father_nameError: Array(1).fill(''),
        nominee_mother_name: Array(1).fill(''),
        nominee_mother_nameError: Array(1).fill(''),
        nominee_share_percentage: Array(1).fill(''),
        relations: [],
        nominee_relationship: Array(1).fill(''),
        nominee_relationshipError: Array(1).fill(''),
        nominee_contact_no: Array(1).fill(''),
        nominee_contact_noError: Array(1).fill(''),
        nominee_nid: Array(1).fill(''),
        nominee_nidError: Array(1).fill(''),
        nominee_profile_photo: Array(1).fill(''),
        nominee_profile_photoError: Array(1).fill(''),
        nominee_sign: Array(1).fill(''),
        nominee_signError: Array(1).fill(''),
        nominee_nid_photo: Array(1).fill(''),
        nominee_nid_photoError: Array(1).fill(''),
        nominee_show: false,
        auto_debit_instructed:false,
        download_page:false,
        investor_account_id:'',
        paymentFrequency:'Monthly',
        paymentFrequencyError:''
    }

    changeState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name == 'bank') {
            this.getBranch(e.target.value, this.state.token)
        } else if (e.target.name == 'payment_bank') {
            this.getBranch(e.target.value, this.state.token)
        } else if (e.target.name == 'deposit_recipt') {
            this.setState({
                [e.target.name]: e.target.files[0]
            })
        } else if (e.target.name == 'installment_size') {
            this.setState({
                no_of_unit: Math.floor(e.target.value / this.state.purchase_price)
            })
        } else if (e.target.name == 'portfolio') {
            this.getAccountList(e.target.value, this.state.token)
            this.getCurrentNav(e.target.value, this.state.token)
        } else if (e.target.name == 'cip_percentage' || e.target.name == 'cash_percentage') {
            if (e.target.name == 'cip_percentage') {
                this.setState({
                    [e.target.name]: e.target.value,
                    cash_percentage: 100 - parseInt(e.target.value)
                })
            } else {
                this.setState({
                    [e.target.name]: e.target.value,
                    cip_percentage: 100 - parseInt(e.target.value)
                })
            }
        }else if(e.target.name == 'auto_debit_instructed'){
            this.setState({
                auto_debit_instructed:!this.state.auto_debit_instructed
            })
        }
    }
    changeState2 = (e) => {
        this.setState({
            [e.name]:e.value,
            [e.name+'Error']:''
        })
        if (e.name == 'bank') {
            this.getBranch(e.value, this.state.token)
        }else if (e.name == 'payment_bank') {
            this.getBranch(e.value, this.state.token)
        }else if(e.name == 'payment_bank'){
            this.getBranch(e.value, this.state.token)
        }
    }
    changeFileState = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    changeDate = (name, value) => {
        this.setState({
            [name]: dayjs(value).format(date_format_y_m_d),
            [name + 'Error']: ''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    changeArrayDate = (name, value, index) => {
        const data = [...this.state[name]];
        data[index] = dayjs(value).format(date_format_y_m_d);
        this.setState({
            [name]: data,
            [name + 'Error']: ''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    changeArrayState = (e, index) => {
        const { name, value } = e.target;
        const data = [...this.state[name]];
        data[index] = value;
        this.setState({
            [name]: data
        });
    }
    changeFileArrayState = (e, index) => {
        const { name, files } = e.target;
        const data = [...this.state[name]];
        data[index] = files[0];
        this.setState({
            [name]: data
        });
    }
    componentDidMount() {
        document.title = 'Create New Account';
        const token = localStorage.getItem('token')
        if (token) {
            this.setState({
                token: token,
                authorization: true,
                isLoading: false
            })
        }
        this.getData(token)
    }
    getAccountList = (portfolio, token) => {

        const url = baseUrl + '/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { fund: portfolio }
        }).then((res) => {
            if (res.data.status == 200) {
                if (res.data.data.length > 0) {
                    res.data.data.map(account_list => {
                        if (account_list.INVESTMENT_TYPE_ID == 1) {
                            this.setState({
                                sip_exits: true
                            })
                        } else if (account_list.INVESTMENT_TYPE_ID == 2) {
                            this.setState({
                                non_sip_exits: true
                            })
                        }
                    })
                }
            }
        }).catch((err) => {
            console.log(err.response.data)
        }).finally(() => {

        })
    }
    getData = (token) => {
        this.setState({
            isLoading: true
        })
        const url = baseUrl + '/api/create-new-account'
        axios({
            url: url,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((response) => {
            this.setState({
                errorStatus: response.data.status,
                portfolios: response.data.data.portfolios,
                banks: response.data.data.banks,
                relations: response.data.data.relations,
            })
        }).catch((error) => {
            this.setState({
                errorStatus: error.response.status
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }
    getBranch = (bank_id, token) => {
        const url = baseUrl + '/api/get-branch'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { bank_id: bank_id },
        }).then((res) => {
            this.setState({
                branches: res.data.data
            })

        }).catch((err) => {
            console.log(err)
        });
    }
    getCurrentNav = (fund, token) => {
        const url = baseUrl + '/api/get-current-nav'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { fund: fund },
        }).then((res) => {
            if (res.data.status == 200) {
                this.setState({
                    purchase_price: res.data.data[0].SELL_PRICE
                })
            }

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    CreateNewAccount = (e) => {
        e.preventDefault()
        const valid = this.checkValidation(e)
        if (!valid) {
            return
        }
        if (this.state.dividend == 'BOTH') {
            if (parseInt(this.state.cip_percentage) + parseInt(this.state.cash_percentage) != 100) {
                this.setState({
                    cash_percentageError: `Cash and Cip percentage should be equal to 100`,
                    cip_percentageError: `Cash and Cip percentage should be equal to 100`
                })
                return
            }
        }
        if(this.state.installment_size<100 || this.state.installment_size%10!=0){
            this.setState({
                installment_sizeError:'Installment amount is either 100 or a multiple of 10'
            })
            return
        }

        this.setState({
            isLoading: true
        })
        var data = new FormData();
        if (this.state.payment_method == 'AMEX' || this.state.payment_method == 'bKashMFS'
            || this.state.payment_method == 'MB' || this.state.payment_method == 'VC'
            || this.state.payment_method == 'NEXUS' || this.state.payment_method == 'MC') {

            var url = baseUrl + '/api/payment-gateway'
        } else {
            var url = baseUrl + '/api/create-new-account'
            data.append('payment_bank', this.state.payment_bank)
            data.append('payment_branch', this.state.payment_branch)
        }
        data.append('createnewaacount', 'createnewaacount')
        data.append('portfolio', this.state.portfolio)
        data.append('investment_type', this.state.investment_type)
        data.append('bank', this.state.bank)
        data.append('branch', this.state.branch)
        data.append('account_no', this.state.account_no)
        data.append('account_name', this.state.account_name)
        data.append('total_purchase_amount', this.state.installment_size)
        data.append('no_of_unit', this.state.no_of_unit)
        data.append('installment_tenure', this.state.installment_tenure)
        data.append('installment_date', this.state.installment_date)
        data.append('dividend', this.state.dividend)
        data.append('cip_percentage', this.state.cip_percentage)
        data.append('cash_percentage', this.state.cash_percentage)
        data.append('auto_debit_instructed',this.state.auto_debit_instructed)
        data.append('paymentMethod', this.state.payment_method)
        data.append('payment_instrument_date', this.state.payment_instrument_date)
        data.append('payment_reference_no', this.state.payment_reference_no)
        data.append('deposit_recipt', this.state.deposit_recipt)
        data.append('nominee_name', this.state.nominee_name)
        data.append('nominee_dob', this.state.nominee_dob)
        data.append('nominee_father_name', this.state.nominee_father_name)
        data.append('nominee_mother_name', this.state.nominee_mother_name)
        data.append('nominee_share_percentage', this.state.nominee_share_percentage)
        data.append('nominee_relationship', this.state.nominee_relationship)
        data.append('nominee_contact_no', this.state.nominee_contact_no)
        data.append('nominee_nid', this.state.nominee_nid)
        data.append('nominee_length', this.state.nominee_name.length)
        for (let i = 0; i < this.state.nominee_profile_photo.length; i++) {
            data.append(`nominee_profile_photo${i}`, this.state.nominee_profile_photo[i]);
        }
        for (let i = 0; i < this.state.nominee_sign.length; i++) {
            data.append(`nominee_sign${i}`, this.state.nominee_sign[i]);
        }
        for (let i = 0; i < this.state.nominee_nid_photo.length; i++) {
            data.append(`nominee_nid_photo${i}`, this.state.nominee_nid_photo[i]);
        }
        axios({
            url: url,
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            data: data,
        }).then((res) => {
            if (res.data.status == 200) {
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
                this.setState({
                    download_page:true,
                    investor_account_id:res.data.data.investor_account_id
                })
            } else if (res.data.status == 400) {
                Object.entries(res.data.data).forEach(([key, value]) => {
                    this.setState({
                        [key + 'Error']: value[0],
                    })
                });
            } else if (res.data.status == 402) {
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                })
            } else if (res.data.status != 200 && res.data.status != 400) {
                if (this.state.payment_method == 'AMEX' || this.state.payment_method == 'bKashMFS'
                    || this.state.payment_method == 'MB' || this.state.payment_method == 'VC'
                    || this.state.payment_method == 'NEXUS' || this.state.payment_method == 'MC') {
                    window.location.href = res.data
                    return
                }
            }
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    errorStatus: err.response.status,
                })
            }
            this.setState({
                isLoading: false
            })
        }).finally(() => {

        })

    }
    nominee_show = (e) => {
        e.preventDefault()
        this.setState({
            nominee_show: true
        })
    }
    checkValidation = (event) => {
        let valid = 1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid = 0
        }

        if(this.state.bank=='' || this.state.branch==''){
            if(this.state.bank==''){
                this.setState({
                    bankError:'This field is required'
                })
            }
            if(this.state.branch==''){
                this.setState({
                    branchError:'This field is required'
                })
            }
            
            valid = 0
        }
        if (this.state.payment_method != 'AMEX' && this.state.payment_method != 'bKashMFS'
            && this.state.payment_method != 'MB' && this.state.payment_method != 'VC'
            && this.state.payment_method != 'NEXUS' && this.state.payment_method != 'MC') {
                
            if(this.state.payment_bank=='' || this.state.payment_branch==''){
                if(this.state.payment_bank==''){
                    this.setState({
                        payment_bankError:'This field is required'
                    })
                }if(this.state.payment_branch==''){
                    this.setState({
                        payment_branchError:'This field is required'
                    })
                }
                if(this.state.payment_instrument_date==''){
                    this.setState({
                        payment_instrument_dateError:'This field is required'
                    })
                }
                
            }
        }
        
        this.setState({
            validated: true
        })
        return valid
    }
    render() {
        const { token = undefined, isLoading,payment_method} = this.state
        const step_check = localStorage.getItem('STEP_CHECK')

        let gateway_title =this.state.payment_method=='bKashMFS'?'Bkash Charge':
                    this.state.payment_method=='AMEX'?'American Express Charge':
                    this.state.payment_method=='MC'?'Mastercard Charge':
                    this.state.payment_method=='VC'?'Visa Charge':
                    this.state.payment_method=='MB'?'Rocket Charge':
                    this.state.payment_method=='NEXUS'?'Nexus Charge':''

        let charge =payment_method=='bKashMFS'?`${this.state.bkash_change }%`:
                    payment_method=='AMEX'?`${this.state.american_charge }%`:
                    payment_method=='MC'?`${this.state.master_charge }%`:
                    payment_method=='VC'?`${this.state.visa_charge }%`:
                    payment_method=='MB'?`${this.state.rocket_charge }%`:
                    payment_method=='NEXUS'?`${this.state.nexus_charge }%`:''

        const installment_date_list = [];
        for (let i = 5; i <=25; i=i+5) {
            installment_date_list.push(<option key={i} value={i}>{i}</option>);
        }
        if (isLoading) {
            return (
                <Loading loading={isLoading} />
            )
        }
        return (
            <>
                {
                    token != undefined ? (this.state.errorStatus == 401 ? <Navigate to="/login" replace={false} /> : '') : <Navigate to="/login" replace={false} />
                }
                {
                    (step_check == 'null' || step_check == 'INVESTMENT' || step_check == 'BOACCOUNT' || step_check == 'PAYMENTOP' ||
                        step_check == 'PAYMENTOP' || step_check == 'PAYMENTOP') ? <Navigate to="/login" replace={false} /> :
                        (
                            step_check != 'FINISH' ?
                                <Navigate to="/application-form" replace={false} /> :
                                ''
                        )
                }
                {
                    this.state.download_page==true &&
                    (
                        <Navigate to={"/payment-success?statusc=200&iia=" + this.state.investor_account_id} replace={false} />
                    )
                }
                <Header auth={this.state.authorization} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} >
                            <Form noValidate validated={this.state.validated} onSubmit={(e) => this.CreateNewAccount(e)}>
                                <div className='tab mt-4'>
                                    <Row>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Name of Fund</Form.Label>
                                                    <Form.Control as='select'
                                                        required
                                                        name="portfolio"
                                                        onChange={(e) => this.changeState(e)}
                                                        defaultValue={this.state.portfolio}
                                                    >
                                                        <option value='' disabled >Select Fund</option>
                                                        {
                                                            this.state.portfolios.map(portfolio => {
                                                                return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID} >{portfolio.PORTFOLIO_NAME}</option>
                                                            })
                                                        }
                                                    </Form.Control>
                                                    <Form.Control.Feedback className={this.state.portfolioError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.portfolioError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.portfolioError == '' ? 'This field is required' : this.state.portfolioError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Investment Type</Form.Label>
                                                    <Form.Control as='select'
                                                        required
                                                        type="text"
                                                        name="investment_type"
                                                        onChange={(e) => this.changeState(e)}
                                                        defaultValue={this.state.investment_type}
                                                    >
                                                        <option value='' disabled>Select Investment Type</option>
                                                        {
                                                            this.state.portfolio ?
                                                                <>
                                                                    <option value='1'>SIP</option>
                                                                    {
                                                                        this.state.non_sip_exits == false ?
                                                                            <option value='2'>NON-SIP</option>
                                                                            : ''
                                                                    }
                                                                </> : ''
                                                        }


                                                    </Form.Control>
                                                    <Form.Control.Feedback className={this.state.investment_typeError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.investment_typeError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.investment_typeError == '' ? 'This field is required' : this.state.investment_typeError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Bank Name</Form.Label>
                                                    {/* <Form.Control as="select"
                                                        name="bank"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        required
                                                        defaultValue={this.state.bank}
                                                    >
                                                        <option value="" disabled>Select Bank</option>
                                                        {
                                                            this.state.banks.map(bank => {
                                                                return <option key={bank.ORG_ID} value={bank.ORG_ID}>{bank.ORG_NAME}</option>
                                                            })
                                                        }
                                                    </Form.Control> */}
                                                    <Select 
                                                        closeMenuOnSelect={true}
                                                        required
                                                        defaultValue={this.state.bank}
                                                        onChange={(e) => { this.changeState2(e) }}
                                                        options=
                                                            {
                                                                this.state.banks.map(bank => 
                                                                    (
                                                                        { 
                                                                            label: bank.ORG_NAME,
                                                                            value: bank.ORG_ID,
                                                                            name:'bank'
                                                                        }
                                                                    ))
                                                            }
                                                            placeholder='Select Bank'
                                                    />
                                                    <Form.Control.Feedback className={this.state.bankError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.bankError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left"><span>*</span>Branch Name</Form.Label>
                                                    {/* <Form.Control as="select"
                                                        name="branch"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        required
                                                        defaultValue={this.state.branch}
                                                    >
                                                        <option value="" disabled>Select Branch</option>
                                                        {
                                                            this.state.branches.map(branch => {
                                                                return <option key={branch.ORG_BRANCH_ID} value={branch.ORG_BRANCH_ID}>{branch.BRANCH_NAME}</option>
                                                            })
                                                        }
                                                    </Form.Control> */}
                                                    <Select 
                                                        closeMenuOnSelect={true}
                                                        required
                                                        defaultValue={this.state.branch}
                                                        onChange={(e) => { this.changeState2(e) }}
                                                        options=
                                                            {
                                                                this.state.branches.map(branch => 
                                                                    (
                                                                        { 
                                                                            label: branch.BRANCH_NAME,
                                                                            value: branch.ORG_BRANCH_ID,
                                                                            name:'branch'
                                                                        }
                                                                    ))
                                                            }
                                                            placeholder='Select Branch'
                                                    />
                                                    <Form.Control.Feedback className={this.state.branchError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.branchError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Account No.</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="account_no"
                                                        onChange={(e) => this.changeState(e)}
                                                        value={this.state.account_no}
                                                    />
                                                    <Form.Control.Feedback className={this.state.account_noError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.account_noError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.account_noError == '' ? 'This field is required' : this.state.account_noError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left"><span>*</span>Account Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="account_name"
                                                        onChange={(e) => this.changeState(e)}
                                                        value={this.state.account_name}
                                                    />
                                                    <Form.Control.Feedback className={this.state.account_nameError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.account_nameError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.account_nameError == '' ? 'This field is required' : this.state.account_nameError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Buy Price/NAV Per Unit</Form.Label>
                                                    <Form.Control
                                                        required
                                                        readOnly
                                                        type="text"
                                                        name="purchase_price"
                                                        onChange={(e) => this.changeState(e)}
                                                        value={this.state.purchase_price}
                                                    />
                                                    <Form.Control.Feedback className={this.state.purchase_priceError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.purchase_priceError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.purchase_priceError == '' ? 'This field is required' : this.state.purchase_priceError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Installment Amount</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        name="installment_size"
                                                        onChange={(e) => this.changeState(e)}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={this.state.installment_size}
                                                    />
                                                    <Form.Control.Feedback className={this.state.installment_sizeError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.installment_sizeError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.installment_sizeError == '' ? 'This field is required' : this.state.installment_sizeError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Number of Unit</Form.Label>
                                                    <Form.Control
                                                        required
                                                        readOnly
                                                        type="text"
                                                        name="no_of_unit"
                                                        onChange={(e) => this.changeState(e)}
                                                        defaultValue={this.state.no_of_unit}
                                                    />
                                                    <Form.Control.Feedback className={this.state.no_of_unitError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.no_of_unitError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.no_of_unitError == '' ? 'This field is required' : this.state.no_of_unitError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        {
                                            this.state.investment_type == 1 ?
                                                <>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Installment Tenure (Year)</Form.Label>
                                                                <Form.Control as="select"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    name="installment_tenure"
                                                                    required
                                                                    defaultValue={this.state.installment_tenure}
                                                                >
                                                                    <option value='' disabled >Select Installment Tenure</option>
                                                                    <option value="01">01</option>
                                                                    <option value="03">03</option>
                                                                    <option value="05">05</option>
                                                                    <option value="07">07</option>
                                                                    <option value="10">10</option>
                                                                    <option value="20">20</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.installment_tenureError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.installment_tenureError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.installment_tenureError == '' ? 'This field is required' : this.state.installment_tenureError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Preferred Installment Date</Form.Label>
                                                                <Form.Control as='select'
                                                                    required
                                                                    type="text"
                                                                    name="installment_date"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    defaultValue={this.state.installment_date}
                                                                >
                                                                    <option value='' disabled>Select Installment Date</option>
                                                                    {installment_date_list}
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.installment_dateError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.installment_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.installment_dateError == '' ? 'This field is required' : this.state.installment_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Select Payment Frequency</Form.Label>
                                                                <Form.Control as="select" 
                                                                    name="paymentFrequency" 
                                                                    required 
                                                                    defaultValue={this.state.paymentFrequency}
                                                                    >
                                                                    <option value="Monthly">Monthly</option>
                                                                    <option value="Quarterly">Quarterly</option>
                                                                    <option value="Yearly">Yearly</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.paymentFrequencyError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.paymentFrequencyError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.paymentFrequencyError==''?'This field is required':this.state.paymentFrequencyError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </> : ''
                                        }
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Dividend Option</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        name="dividend"
                                                        required
                                                        value={this.state.dividend}
                                                    >
                                                        <option value='' disabled>Select Dividend</option>
                                                        <option value="CIP">CIP</option>
                                                        <option value="CASH">CASH</option>
                                                        <option value="BOTH">BOTH</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback className={this.state.dividendError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.dividendError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.dividendError == '' ? 'This field is required' : this.state.dividendError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        {
                                            this.state.dividend == 'BOTH' ?
                                                <>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>CIP Percentage</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="number"
                                                                    placeholder="CIP Percentage"
                                                                    name="cip_percentage"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={this.state.cip_percentage}
                                                                />
                                                                <Form.Control.Feedback className={this.state.cip_percentageError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.cip_percentageError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.cip_percentageError == '' ? 'This field is required' : this.state.cip_percentageError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>CASH Percentage</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="number"
                                                                    placeholder="CASH Percentage"
                                                                    name="cash_percentage"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={this.state.cash_percentage}
                                                                />
                                                                <Form.Control.Feedback className={this.state.cash_percentageError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.cash_percentageError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.cash_percentageError == '' ? 'This field is required' : this.state.cash_percentageError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </> : ''
                                        }
                                        <Col md={12}>
                                            <Form.Group className="mb-3 bo-account-yes " >
                                                <Form.Check type="radio" >
                                                    <Form.Check.Input
                                                        onChange={(e) => this.changeState(e)}
                                                        name="auto_debit_instructed"
                                                        type="checkbox" />
                                                    <Form.Check.Label>
                                                        Auto debit instructed
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <h4>Payment Information</h4>
                                    <Row>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label><span>*</span>Payment Method</Form.Label>
                                                    <Form.Control as="select"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        name="payment_method"
                                                        required
                                                        defaultValue={this.state.payment_method}
                                                    >
                                                        <option value='' disabled>Select Payment Type</option>
                                                        <option value="cheque">Cheque</option>
                                                        <option value="po">Pay Order</option>
                                                        <option value="dd">Demand Draft</option>
                                                        <option value="rtgs">RTGS</option>
                                                        <option value="beftn">BEFTN</option>
                                                        <option value="npsb">NPSB</option>
                                                        <option value="bKashMFS">Bkash</option>
                                                        <option value="MB">Rocket</option>
                                                        <option value="NEXUS">NexusPay</option>
                                                        <option value="MC">MasterCard</option>
                                                        <option value="VC">Visa</option>
                                                        <option className='hide' value="AMEX">AMEX</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback className={this.state.payment_methodError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.payment_methodError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.payment_methodError == '' ? 'This field is required' : this.state.payment_methodError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        {
                                            this.state.payment_method == 'AMEX' || this.state.payment_method == 'bKashMFS'
                                                || this.state.payment_method == 'MB' || this.state.payment_method == 'VC'
                                                || this.state.payment_method == 'NEXUS' || this.state.payment_method == 'MC' ? '' :
                                                <>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Deposited Instrument Date</Form.Label>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        required
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('payment_instrument_date', value) }}
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'payment_instrument_date'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                <Form.Control.Feedback className={this.state.payment_instrument_dateError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.payment_instrument_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.payment_instrument_dateError == '' ? 'This field is required' : this.state.payment_instrument_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Bank Name</Form.Label>
                                                                {/* <Form.Control as='select'
                                                                    required
                                                                    type="text"
                                                                    name="payment_bank"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    defaultValue={this.state.payment_bank}
                                                                >
                                                                    <option value="" disabled>Select Bank</option>
                                                                    {
                                                                        this.state.banks.map(bank => {
                                                                            return <option key={bank.ORG_ID} value={bank.ORG_ID}>{bank.ORG_NAME}</option>
                                                                        })
                                                                    }
                                                                </Form.Control> */}
                                                                <Select 
                                                                    closeMenuOnSelect={true}
                                                                    required
                                                                    defaultValue={this.state.payment_bank}
                                                                    onChange={(e) => { this.changeState2(e) }}
                                                                    options=
                                                                        {
                                                                            this.state.banks.map(bank => 
                                                                                (
                                                                                    { 
                                                                                        label: bank.ORG_NAME,
                                                                                        value: bank.ORG_ID,
                                                                                        name:'payment_bank'
                                                                                    }
                                                                                ))
                                                                        }
                                                                        placeholder='Select Bank'
                                                                />
                                                                <Form.Control.Feedback className={this.state.payment_bankError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.payment_bankError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Branch Name</Form.Label>
                                                                {/* <Form.Control as='select'
                                                                    required
                                                                    name="payment_branch"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    defaultValue={this.state.payment_branch}
                                                                >
                                                                    <option value="" disabled>Select Branch</option>
                                                                    {
                                                                        this.state.branches.map(branch => {
                                                                            return <option key={branch.ORG_BRANCH_ID} value={branch.ORG_BRANCH_ID}>{branch.BRANCH_NAME}</option>
                                                                        })
                                                                    }
                                                                </Form.Control> */}
                                                                 <Select 
                                                                    closeMenuOnSelect={true}
                                                                    required
                                                                    defaultValue={this.state.payment_branch}
                                                                    onChange={(e) => { this.changeState2(e) }}
                                                                    options=
                                                                        {
                                                                            this.state.branches.map(branch => 
                                                                                (
                                                                                    { 
                                                                                        label: branch.BRANCH_NAME,
                                                                                        value: branch.ORG_BRANCH_ID,
                                                                                        name:'payment_branch'
                                                                                    }
                                                                                ))
                                                                        }
                                                                        placeholder='Select Branch'
                                                                />
                                                                <Form.Control.Feedback className={this.state.payment_branchError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.payment_branchError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Reference No.</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="payment_reference_no"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    value={this.state.payment_reference_no}
                                                                />
                                                                <Form.Control.Feedback className={this.state.payment_reference_noError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.payment_reference_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.payment_reference_noError == '' ? 'This field is required' : this.state.payment_reference_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Deposit/Fund Transfer Receipt</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="file"
                                                                    name="deposit_recipt"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.deposit_reciptError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.deposit_reciptError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.deposit_reciptError == '' ? 'This field required' : this.state.deposit_reciptError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </>
                                        }
                                    </Row>
                                    <h4>First Nominee Information</h4>
                                    <Row>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nominee_name"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left">Date of Birth</Form.Label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            required
                                                            format={date_format}
                                                            onChange={(value) => { this.changeArrayDate('nominee_dob', value, 0) }}
                                                            slotProps={{
                                                                textField: {
                                                                    name: 'nominee_dob'
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label>Father's Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nominee_father_name"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left">Mother's Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nominee_mother_name"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label>Share Percentage</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="nominee_share_percentage"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left">Relationship</Form.Label>
                                                    <Form.Control as='select'
                                                        name="nominee_relationship"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                        defaultValue={this.state.nominee_relationship[0]}
                                                    >
                                                        <option value="" disabled>Select Relationship</option>
                                                        {
                                                            this.state.relations.map(relation => {
                                                                return <option key={relation.RELATION_ID} value={relation.RELATION_ID}>{relation.RELATION}</option>
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label>Contact No.</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nominee_contact_no"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left">National ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nominee_nid"
                                                        onChange={(e) => this.changeArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label>Nominee Photo</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="nominee_profile_photo"
                                                        onChange={(e) => this.changeFileArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label align="left">Nominee Sign</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="nominee_sign"
                                                        onChange={(e) => this.changeFileArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='input-field' style={{ width: '90%' }}>
                                                <Form.Group className="mb-3 bo-account-yes " >
                                                    <Form.Label>Nominee National ID Photo</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="nominee_nid_photo"
                                                        onChange={(e) => this.changeFileArrayState(e, 0)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        (!this.state.nominee_show) &&
                                        <Row>
                                            <Col className='ms-auto' sm={3} md={3}>
                                                <Button
                                                    className='form-button my-4'
                                                    type="submit"
                                                    id="nextBtn"
                                                    onClick={(e) => this.nominee_show(e)}
                                                >Add More Nominee<FontAwesomeIcon icon={faPlusCircle} /></Button>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        (this.state.nominee_show) &&
                                        <>
                                            <h4>Second Nominee Information</h4>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nominee_name"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">Date of Birth</Form.Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    required
                                                                    format={date_format}
                                                                    onChange={(value) => { this.changeArrayDate('nominee_dob', value, 1) }}
                                                                    slotProps={{
                                                                        textField: {
                                                                            name: 'nominee_dob'
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Father's Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nominee_father_name"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">Mother's Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nominee_mother_name"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Share Percentage</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nominee_share_percentage"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">Relationship</Form.Label>
                                                            <Form.Control as='select'
                                                                name="nominee_relationship"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                                defaultValue={this.state.nominee_relationship[1]}
                                                            >
                                                                <option value="" disabled>Select Relationship</option>
                                                                {
                                                                    this.state.relations.map(relation => {
                                                                        return <option key={relation.RELATION_ID} value={relation.RELATION_ID}>{relation.RELATION}</option>
                                                                    })
                                                                }
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Contact No.</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nominee_contact_no"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">National ID</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nominee_nid"
                                                                onChange={(e) => this.changeArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Nominee Photo</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="nominee_profile_photo"
                                                                onChange={(e) => this.changeFileArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">Nominee Sign</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="nominee_sign"
                                                                onChange={(e) => this.changeFileArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Nominee National ID Photo</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="nominee_nid_photo"
                                                                onChange={(e) => this.changeFileArrayState(e, 1)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    <Row>
                                        <Col className='mx-auto' md={3}>
                                            <Button
                                                className='form-button my-4'
                                                type="submit"
                                                id="nextBtn"
                                            >Submit <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}