import React, { Component } from 'react';
import { Container, Row, Col, ListGroup, Table } from 'react-bootstrap';
import { Link,Navigate } from "react-router-dom";
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import Loading from '../loading'
const baseUrl=global.config.data.path.basePath
export default class Profile extends Component {
    state={
        validated: false,
        authorization:false,
        token: '',
        errorStatus:'',
        investor:[],
        investorDetails:[],
        isLoading: true
    }
    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const status = urlParams.get('status');
        if(status==200){
            Swal.fire({
                position: 'top-right',
                text: 'Your Request Successfully received',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        }else if(status!=null){
            Swal.fire({
                position: 'top-right',
                text: 'Invalid Payment Request',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            })
        }

        document.title = 'Profile';
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true,
            })
        }
        this.profile(token);
    }
    profile=(token)=>{
        const url=baseUrl+'/api/investor/profile'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    errorStatus:200,
                    authorization:true,
                    investor:res.data.users,
                    investorDetails:res.data.investorBankInfo,
                    isLoading: false
                }) 
            }
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    authorization:false,
                    isLoading: false
                })
            }
         });
        
    }
    render() {
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        let date=this.state.investor.created_at!=undefined?this.state.investor.created_at.toString():''
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    token!=undefined?
                        (this.state.errorStatus==401?
                            <Navigate to="/login" replace={false} />:''):
                        <Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row>
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Table variant="light" striped bordered hover>
                                    <tbody>
                                        <tr>
                                            <td>Investor ID Number</td>
                                            <td>{this.state.investor.client_id}</td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{this.state.investor.name==null?this.state.investor.institution_name:this.state.investor.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Account Opening Date</td>
                                            <td>{date.split('-')[2].slice(0,2)} {month[parseInt(date.split('-')[1]-1)]}, {date.split('-')[0]}</td>
                                        </tr>
                                        <tr>
                                            <td>Mailing Address</td>
                                            <td>{this.state.investorDetails.MAILING_ADDRESS}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile No.</td>
                                            <td>{this.state.investor.mobile_no}</td>
                                        </tr>
                                        <tr>
                                            <td>Email ID</td>
                                            <td>{this.state.investor.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Bank Information</td>
                                            <td>
                                            <ListGroup className="profile">
                                                <ListGroup.Item>
                                                    <span className="col-md-6" style={{display:"inline-block"}}>Investment Type</span>
                                                    <span className="col-md-6" style={{display:"inline-block"}}>: {this.state.investorDetails.INVESTMENT_TYPE}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="col-md-6" style={{display:"inline-block"}}>Bank Name</span>
                                                    <span className="col-md-6" style={{display:"inline-block"}}>: {this.state.investorDetails.ORG_NAME}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item >
                                                    <span className="col-md-6" style={{display:"inline-block"}}>Branch</span>
                                                    <span className="col-md-6" style={{display:"inline-block"}}>: {this.state.investorDetails.BRANCH_NAME}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item >
                                                    <span className="col-md-6" style={{display:"inline-block"}}>Account No.</span>
                                                    <span className="col-md-6" style={{display:"inline-block"}}>: {this.state.investorDetails.BANK_ACCOUNT_NO}</span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col className='ms-auto text-center'  md={3}>
                                <ListGroup className="profile">
                                    <ListGroup.Item>
                                        <Link className="nav-link" to="/purchase">Buy Units</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Link className="nav-link" to="/surrender">Surrender Units</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Link className="dropdown-item" to="/report/portfolio-statement">See Portfolio Summary</Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}