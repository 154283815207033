import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Loading from '../loading'
import { Navigate } from "react-router-dom";
const logo=global.config.data.files.logo
const baseUrl=global.config.data.path.basePath
const name=global.config.data.info.name
const email=global.config.data.info.email
const mobile=global.config.data.info.mobile
const address=global.config.data.info.address
const web=global.config.data.info.web
export default class TaxCertificateStatement extends Component {
    state = {
        validated: false,
        authorization:false,
        showTaxReport:false,
        token:'',
        portfolios:[],
        user:[],
        account_list:[],
        fisacl_years:[],

        fetchCombineSearchData:[],
        fetchGainLoss:[],
        fetchDividend:[],

        fund:'',
        reg_no:'',
        fiscal_year:'',
        fiscal_year_id:'',
        start_date:'',
        end_date:'',

        fundError:'',
        reg_noError:'',
        fiscal_yearError:'',
        isLoading: true
    }
    changeState=(e)=>{
        if(e.target.name=='fund'){
            this.getAccountList(e.target.value,this.state.token)
        }else if(e.target.name=='fiscal_year'){
           const selectedOption =  e.target.options[e.target.selectedIndex]
           this.setState({
                fiscal_year_id:selectedOption.dataset.fiscalYearId
           })
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    componentDidMount(){
        this.setState({
            fund:''
        })
        document.title = 'Tax certificate statement'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
        this.getFiscalYear(token)
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                authorization:true,
                isLoading: false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    isLoading: false
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.data.length>0)
                this.getAccountList(res.data.data[0].PORTFOLIO_ID,this.state.token)
            this.setState({
                portfolios:res.data.data,
                fund:res.data.data[0].PORTFOLIO_ID
            })
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    reg_no:res.data.data[0].INVESTOR_REG_NO
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getFiscalYear=(token)=>{
        const url = baseUrl+'/api/get-fiscal-year'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    fisacl_years:res.data.data
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getTaxReport=(e)=>{
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        this.setState({
            isLoading:true
        })
        let data = {
            fund:this.state.fund,
            fiscal_year:this.state.fiscal_year,
            fiscal_year_id:this.state.fiscal_year_id,
            reg_no:this.state.reg_no,
        }
        console.log(data)
        const url = baseUrl+'/api/tax-certificate-report'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    errorStatus:res.data.status,
                    showTaxReport:true,
                    fetchCombineSearchData:res.data.data.fetchCombineSearchData,
                    fetchGainLoss:res.data.data.fetchGainLoss,
                    fetchDividend:res.data.data.fetchDividend,
                    start_date:res.data.data.fiscal_start,
                    end_date:res.data.data.fiscal_end,
                    
                }) 
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })

            }
            this.setState({
                isLoading:false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false

    }
    getPdf=(e)=>{
        e.preventDefault()
        this.setState({
            isLoading:true
        })
        const url = baseUrl+'/api/tax-certificate-report'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:this.state.fund,fiscal_year:this.state.fiscal_year,fiscal_year_id:this.state.fiscal_year_id,reg_no:this.state.reg_no}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("taxReport");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    render() {
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        
        let totalGainLoss = 0
        let honorText  = '';
        let honorType  = this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].HONOR_NAME:''
        let operation_mode=this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].OPERATION_MODE_ID:''
        let spouseName = this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].SPOUSE_NAME:''
        let fatherName = this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].FATHER_NAME:''
        let motherName = this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].MOTHER_NAME:''
        let jointInvestorName = this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].JOINT_INVESTOR_NAME:''
        if(operation_mode == 2 && honorType!='') {
            if(honorType == 'Mrs') {
                if(spouseName!='') {
                    honorText = spouseName
                }else if (fatherName!='') {
                    honorText = fatherName
                }
            }else {
                if (fatherName!='') {
                    honorText = fatherName
                }
            }
        }
        let INVESTOR_NAME=this.state.fetchCombineSearchData.length>0?this.state.fetchCombineSearchData[0].INVESTOR_NAME:''
        INVESTOR_NAME = operation_mode == 2 ? honorType+' '+INVESTOR_NAME : INVESTOR_NAME
        let OPENING_BLANCE         = 0
        let CLOSING_BLANCE         = 0
        let INVESTMENT_THIS_YEAR   = 0
        let REDEMPTION_THIS_YEAR   = 0
        let COST_VALUE             = 0
        let MARKET_VALUE            = 0
        let dividendIncome         = 0
        let dividendTax            = 0
        let UNREALIZED_GAIN=0
        let MAILING_ADDRESS=''
        let INVESTOR_ID=''
        let PORTFOLIO_NAME=''
        let PORT_REG_NO=''
        let SPONSOR=''
        let ASSET_MANAGER=''
        let TRUSTEE_NAME=''
        let CUSTODIAN_NAME=''
        
        if(this.state.fetchCombineSearchData.length>0){
            OPENING_BLANCE         = this.state.fetchCombineSearchData[0].OPENING_TOTAL_NO_OF_UNITS * this.state.fetchCombineSearchData[0].OPENING_AVG_AMOUNT
            CLOSING_BLANCE        = (this.state.fetchCombineSearchData[0].CLOSING_TOTAL_NO_OF_UNITS * this.state.fetchCombineSearchData[0].CLOSING_AVG_AMOUNT) + this.state.fetchCombineSearchData[0].CASH_BALANCE_THIS_YEAR
            INVESTMENT_THIS_YEAR   = this.state.fetchCombineSearchData[0].INVESTMENT_THIS_YEAR + this.state.fetchCombineSearchData[0].CASH_BALANCE_THIS_YEAR

            REDEMPTION_THIS_YEAR   = this.state.fetchCombineSearchData[0].REDEMPTION_THIS_YEAR
            REDEMPTION_THIS_YEAR=REDEMPTION_THIS_YEAR==null?0:REDEMPTION_THIS_YEAR
            COST_VALUE             = this.state.fetchCombineSearchData[0].CLOSING_TOTAL_NO_OF_UNITS * this.state.fetchCombineSearchData[0].CLOSING_AVG_AMOUNT
            MARKET_VALUE           = this.state.fetchCombineSearchData[0].CLOSING_TOTAL_NO_OF_UNITS * this.state.fetchCombineSearchData[0].NAV_AMOUNT
            UNREALIZED_GAIN        = (MARKET_VALUE - COST_VALUE)
            MAILING_ADDRESS   = this.state.fetchCombineSearchData[0].MAILING_ADDRESS
            INVESTOR_ID   = this.state.fetchCombineSearchData[0].INVESTOR_ID
            PORTFOLIO_NAME=this.state.fetchCombineSearchData[0].PORTFOLIO_NAME
            PORT_REG_NO=this.state.fetchCombineSearchData[0].PORT_REG_NO
            SPONSOR=this.state.fetchCombineSearchData[0].SPONSOR
            ASSET_MANAGER=this.state.fetchCombineSearchData[0].ASSET_MANAGER
            TRUSTEE_NAME=this.state.fetchCombineSearchData[0].TRUSTEE_NAME
            CUSTODIAN_NAME=this.state.fetchCombineSearchData[0].CUSTODIAN_NAME
        }
        if(this.state.fetchDividend.length>0){
            dividendIncome         = this.state.fetchDividend[0].TOTAL_DIVIDEND
            dividendIncome=dividendIncome==null?0:dividendIncome
            dividendTax            = this.state.fetchDividend[0].TAX
            dividendTax=dividendTax==null?0:dividendTax
        }
        
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.getTaxReport(e)}>
                                    <Table variant="light" striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td>Name of the Investor</td>
                                                <td>{this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Mutual Fund</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            name="fund" 
                                                            variant="secondary" 
                                                            required
                                                            defaultValue={this.state.fund}
                                                            >
                                                                {/* <option value='' disabled>Select fund</option> */}
                                                            {
                                                                this.state.portfolios.map(portfolio=>{
                                                                    return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                                })
                                                            }

                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fundError==''?'This field is required':this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Registration No.</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control as="select" 
                                                            name="reg_no" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            variant="secondary" 
                                                            defaultValue={this.state.reg_no}
                                                            required 
                                                            >
                                                                {/* <option value='' disabled>Select reg. no.</option> */}
                                                            {
                                                                this.state.account_list.map(accountlist=>{
                                                                    return <option value={accountlist.INVESTOR_REG_NO} key={accountlist.INVESTOR_REG_NO}>{accountlist.INVESTOR_REG_NO}</option>  
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.reg_noError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.reg_noError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.reg_noError==''?'This field is required':this.state.reg_noError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fiscal Year</td>
                                                <td>
                                                <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control as="select" 
                                                            name="fiscal_year" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            variant="secondary" 
                                                            defaultValue={this.state.fiscal_year}
                                                            required 
                                                            >
                                                                <option value='' disabled>Select fiscal year</option>
                                                            {
                                                                this.state.fisacl_years.map(fisacl_year=>{
                                                                    return <option 
                                                                        value={fisacl_year.FISCAL_YEAR} 
                                                                        key={fisacl_year.FISCAL_YEAR_ID}
                                                                        data-fiscal-year-id={fisacl_year.FISCAL_YEAR_ID}
                                                                    >{fisacl_year.FISCAL_YEAR}</option>  
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fiscal_yearError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fiscal_yearError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fiscal_yearError==''?'This field is required':this.state.fiscal_yearError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="end" colSpan={2}>
                                                    <Button
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                        {
                            this.state.showTaxReport==true?
                            <>
                                <div className='text-end vanis'>
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.print(e)} icon={faPrint} /></a> 
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.getPdf(e)} icon={faFilePdf} /></a>
                                </div>
                                <div id="taxReport">
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <p className="fw-bold">
                                            <Image
                                                src={logo}
                                                className="d-inline-block align-top print-logo"
                                                alt={name}

                                            />
                                        </p>
                                        <Table className="tax-certificate" variant="light" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <p>{INVESTOR_NAME+''+(jointInvestorName!=''?'':jointInvestorName)}</p>
                                                        <p>
                                                            {MAILING_ADDRESS}
                                                        </p>
                                                    </td>
                                                    <td align="right">
                                                        {/* <p>Date: 03-09-2022</p> */}
                                                        <p>Registration No:{INVESTOR_ID}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" colSpan={2}>
                                                        <h1 className="text-secondary" >{PORTFOLIO_NAME}</h1>
                                                        <p>Registered under the Bangladesh Securities & Exchange Commission (Mutual Fund) Rules, 2001</p>
                                                        <p>Investor ID: {this.state.user.client_id}</p>
                                                        <p>Registration No.: {PORT_REG_NO}</p>
                                                        <p>Sponsor: {SPONSOR}</p>
                                                        <p>Asset Manager: {ASSET_MANAGER}</p>
                                                        <p>Trustee: {TRUSTEE_NAME}</p>
                                                        <p>Custodian: {CUSTODIAN_NAME}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" colSpan={2}>
                                                        <h3 className="text-secondary">To Whom It May Concern</h3>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} ><p>This is to certify that {INVESTOR_NAME+''+(jointInvestorName!=''?'':jointInvestorName)}
                                                    is a registered unit-holder of {PORTFOLIO_NAME +''+operation_mode}. His/Her details information is given below:</p></td>
                                                </tr>
                                                <tr>
                                                    <td>Investment Period:</td>
                                                    <td className='text-end'>{this.state.start_date} to {this.state.end_date}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span>Opening Investment Balance:</span><span className="d-inline-block float-end" >{OPENING_BLANCE}</span></td>
                                                    <td><span>Closing Investment Balance:</span><span className="d-inline-block float-end" >{CLOSING_BLANCE}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Investment During this Year:</span><span className="d-inline-block float-end" >{INVESTMENT_THIS_YEAR}</span></td>
                                                    <td><span>Redemption During this Year:</span><span className="d-inline-block float-end" >{REDEMPTION_THIS_YEAR}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Cost Value of Units:</span><span className="d-inline-block float-end" >{COST_VALUE}</span></td>
                                                    <td><span>Market value of Units:</span><span className="d-inline-block float-end" >{MARKET_VALUE}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Realized Gain/Loss:</span><span className="d-inline-block float-end" >{totalGainLoss}</span></td>
                                                    <td><span>Unrealized Gain/Loss:</span><span className="d-inline-block float-end" >{UNREALIZED_GAIN}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Dividend Income:</span><span className="d-inline-block float-end" >{dividendIncome}</span></td>
                                                    <td><span>Tax Deducted on Dividend:</span><span className="d-inline-block float-end" >{dividendTax}</span></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><i>(All figures in Bangladeshi Taka)</i></td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <p className="fw-bold text-secondary">Note:</p>
                                                        <p>This Certificate is a confidential and legal document of subscribing {PORTFOLIO_NAME}. Unauthorized use,
                                                            disclosure or copying of this document is unlawful and strictly prohibited, and shall be treated with appropriate
                                                            measures of law. This Certificate will be void as and when any new “Certificate” is issued to the same investor.
                                                            As this is a system-generated certificate, it does not require any authorized signature.</p>
                                                        <p>For any query or changes to your personal details, please contact us at 01327266767 or send email to
                                                        hello@aims-bangladesh.com for any assistance at any time.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='text-end' colSpan={2}>
                                                        <p className="fw-bold text-secondary">{name}</p>
                                                        <p className="text-secondary">{address}</p>
                                                        <p>E-mail: {email}, Website: {web}</p>
                                                        <p>Mobile: {mobile}</p>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </div></>:''
                        }
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}