import logo from '../assets/img/logo.svg';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, ListGroup, Image, Navbar, Nav, NavDropdown, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFax } from '@fortawesome/free-solid-svg-icons';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { Link } from 'react-router-dom';

const date= new Date()
const phone=global.config.data.info.phone
const mobile=global.config.data.info.mobile
const fax=global.config.data.info.fax
const name=global.config.data.info.name
const address=global.config.data.info.address
const facebook=global.config.data.info.facebook
const instragram=global.config.data.info.instragram
const linkedin=global.config.data.info.linkedin
const dev_name=global.config.data.dev.name
export default class Footer extends Component {
    render() {
        return (
            <Container className='vanis' fluid={true}>
                <Row  className='footer' >
                    <Col md="12">
                        <Container>
                            <Row>
                                <Col className="mb-3" md={3} >
                                    <ListGroup>
                                        <ListGroup.Item className="border-0">
                                            <h5>AIMS OF BANGLADESH</h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <p>
                                            Unique Trade Center (UTC), Level 6<br/>8 Panthapath, Karwan Bazaar<br/>Dhaka 1215, Bangladesh
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <FontAwesomeIcon icon={faPhone} /> {phone}
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <FontAwesomeIcon icon={faMobile} /> {mobile}
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <FontAwesomeIcon icon={faFax} /> {fax}
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0 brand-icons">
                                            <ListGroup horizontal>
                                                <ListGroup.Item className="border-0">
                                                    <a href={facebook} target="blank"><FontAwesomeIcon icon={faFacebook} /></a>
                                                </ListGroup.Item>
                                                {/* <ListGroup.Item className="border-0">
                                                    <a href={instragram} target="blank"><FontAwesomeIcon icon={faInstagram} /></a>
                                                </ListGroup.Item>  */}
                                                <ListGroup.Item className="border-0">
                                                    <a href={linkedin} target="blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col className="mb-3" md={3} >
                                    <ListGroup>
                                        <ListGroup.Item className="border-0">
                                            <h5>QUICK LINKS</h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0"><a href='https://www.aims-bangladesh.com/'>home</a></ListGroup.Item>
                                        <ListGroup.Item className="border-0"><a href='https://www.aims-bangladesh.com/about-us/mission-vision-goal/'>about us</a></ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.aims-bangladesh.com/fund/grameen-bank-aims-first-unit-fund/'>our funds</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.aims-bangladesh.com/publication/weekly-publication/'>publications</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.aims-bangladesh.com/associates/associate-partner-client/'>associates</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://aims-bangladesh.com/forms/'>editable forms</a>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col className="mb-3" md={3} >
                                    <ListGroup>
                                        <ListGroup.Item className="border-0">
                                            <h5>OTHER INFORMATION</h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.aims-bangladesh.com/about-us/in-the-media/'>in the media</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.aims-bangladesh.com/associates/associate-partner-client/'>associates, partners & clients</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a>learning centers</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.aims-bangladesh.com/about-us/activity-album/'>activity album</a>
                                        </ListGroup.Item>
                                        
                                    </ListGroup>
                                </Col>
                                <Col className="mb-3" md={3} >
                                    <ListGroup>
                                        <ListGroup.Item className="border-0">
                                            <h5>USEFUL LINKS</h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.sec.gov.bd/'>bangladesh securities and exchange commission</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.cdbl.com.bd/'>central depository bangladesh limited</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.bb.org.bd/en/index.php'>bangladesh bank</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.dsebd.org/'>dhaka stock exchange</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0">
                                            <a href='https://www.cse.com.bd/'>chittagong stock exchange</a>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className='bottom-footer'>
                    <Col align="center" >
                        © {date.getFullYear()} ALL RIGHTS RESERVED BY {name}. | DEVELOPED BY {dev_name}.
                    </Col>
                </Row>
            </Container>
        );
    }
}