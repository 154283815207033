import React, { Component } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, Navigate } from "react-router-dom";
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import Loading from '../loading'
export default class Prospectus extends Component {
    state = {
        validated: false,
        authorization: false,
        token: '',
        errorStatus: '',
        isLoading: true
    }
    componentDidMount() {
        document.title = 'Prospectus';
        const token = localStorage.getItem('token')
        if (token) {
            this.setState({
                token: token,
                authorization: true,
                isLoading: false
            })
        }
    }
    render() {
        const { token = undefined, isLoading } = this.state;
        if (isLoading) {
            return (
                <Loading loading={isLoading} />
            )
        }
        return (
            <>
                {
                    this.state.errorStatus == 401 ? <Navigate to="/login" replace={false} /> : ''
                }
                <Header auth={this.state.authorization} />
                <Container className="my-4">
                    <Row>
                        <Col md={12}>
                            <ListGroup className="my-4">
                                <ListGroup.Item>
                                    I/We have read and understood the contents of the Prospectus of the Micro-Savers Special Purpose Growth Scheme of Grameen Bank-AIMS First Unit Fund and agree to be bound and governed by the same on availing/using the offer. I/We hereby apply for allotment/purchase of Units in the Scheme and agree to abide by the terms and conditions applicable thereto. I/We hereby declare that I/We are qualified and authorized to make this investment in the Scheme and that the amount invested is through legitimate sources only and does not involve and is not designed for the purpose of any contravention or evasion of an Act, Rules, Regulations, Notifications or Directions of the provisions of Income Tax Acts, Money Laundering Prevention Act, AntiCorruption Commission Act or any other applicable laws enacted by the Government of Bangladesh from time to time or any international law or sanction. I/We hereby authorize the Fund, its Asset Manager and its Agents to disclose details of my/our investment to my/our investment advisor and/or my/our bank/bankers of Grameen Bank-AIMS First Unit Fund. I/We have neither received nor been induced by any rebate or gifts, directly or indirectly in making this investment.
                                    <br/><br/>I/We confirm that the issuer/distributor has disclosed all commission and/or cost (in the form of trail commission or any mode) payable to the issuer/distributor, if any, for the different competing Schemes of various Mutual Funds from amongst which the Scheme is being recommended to me/us. I/We have examined the information provided by me/us on this form and to the best of my/our knowledge and belief it is true, correct and complete.
                                    <br/><br/><strong>Applicable to NRBs seeking repatriation of redemption proceeds:</strong> I/We confirm that I am/We are Non-Resident(s) Bangladeshi Nationality/Origin and that I/We have remitted funds from abroad through approved banking channels or from funds in my/our NITA account. I/We understand that Foreign investors (individual or corporate) shall have to route funds through a custodial bank in order to qualify for repatriation.
                                    <br/><br/>I/We have understood the information requirements of this Form and hereby confirm that the information provided by me/us on this Form are true, correct and complete. I/We also confirm that I/We have read and understood all the Terms and Conditions mentioned in this Form and hereby accept the same.
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        )
    }
}