import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Loading from '../loading'
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const logo=global.config.data.files.logo
const baseUrl=global.config.data.path.basePath
const name=global.config.data.info.name
const email=global.config.data.info.email
const mobile=global.config.data.info.mobile
const address=global.config.data.info.address
const web=global.config.data.info.web
const date_format=global.config.data.date.date_format
const date_format_y_m_d=global.config.data.date.date_format_y_m_d
export default class PortfolioStatement extends Component {
    state = {
        validated: false,
        authorization:false,
        showPortfolio:false,
        token:'',
        portfolios:[],
        user:[],
        account_list:[],

        fund:'',
        portfolio_code:'',
        investor_reg_no:'',
        date:'',

        fetchDividend:[],
        portfolioStatementResult:[],

        fundError:'',
        portfolio_codeError:'',
        dateError:'',
        isLoading: true
    }
    changeState=(e)=>{
        if(e.target.name=='fund'){
            this.getAccountList(e.target.value,this.state.token)
            const selectedOption = e.target.options[e.target.selectedIndex];
            const investor_reg_no = selectedOption.dataset.investorRegNo;
            this.setState({
                investor_reg_no:investor_reg_no
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeDate=(name,value)=>{
        this.setState({
            [name]:dayjs(value).format(date_format_y_m_d),
            [name+'Error']:''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    componentDidMount(){
        document.title = 'Portfolio statement'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
    }
    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/portfolio-report'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:this.state.fund,portfolio_code:this.state.portfolio_code,investor_reg_no:this.state.investor_reg_no,date:this.state.date}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                authorization:true,
                isLoading: false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    authorization:false,
                    isLoading: false
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.data.length>0)
                this.getAccountList(res.data.data[0].PORTFOLIO_ID,this.state.token)
            this.setState({
                portfolios:res.data.data,
                fund:res.data.data[0].PORTFOLIO_ID
            })
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    portfolio_code:res.data.data[0].PORTFOLIO_CODE,
                    investor_reg_no:res.data.data[0].INVESTOR_REG_NO
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    portfolioReport=(e)=>{
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid || this.state.date==''){
            if(this.state.date==''){
                this.setState({
                    dateError:'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.border = "1px solid red";
                }
                return
            }
            return
        }
        
        this.setState({
            isLoading:true
        })

        let data = {
            fund:this.state.fund,
            portfolio_code:this.state.portfolio_code,
            investor_reg_no:this.state.investor_reg_no,
            date:this.state.date
        }
        console.log(data)
        const url = baseUrl+'/api/portfolio-report'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    errorStatus:res.data.status,
                    showPortfolio:true,
                    fetchDividend:res.data.data.fetchDividend,
                    portfolioStatementResult:res.data.data.portfolioStatementResult
                }) 

            }
            this.setState({
                isLoading:false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false

    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("portfolioReport");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    render() {
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }

        let TOTAL_DIVIDEND          = 0
        let NO_OF_CIP_UNITS         = 0
        let BALANCE                 = 0
        let TOTAL_DEPOSIT           = 0
        let BALANCE_AMOUNT          = 0
        let UNREALIZED_GAIN_LOSS    = 0
        let TOTAL_WITHDRAW			= 0
        let TOTAL_PROFIT_LOSS 		= 0
        let TOTAL_BUY_PRICE		    = 0
        let CURRENT_MARKET_VALUE	= 0

        if (Array.isArray(this.state.fetchDividend)) {
            this.state.fetchDividend.map(fetchDividend=>{
                TOTAL_DIVIDEND      = fetchDividend.TOTAL_DIVIDEND
                NO_OF_CIP_UNITS     = fetchDividend.NO_OF_CIP_UNITS
                BALANCE             = fetchDividend.BALANCE
            })
        }
        
        
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?
                        <Navigate to="/login" replace={false} />:''):
                        <Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.portfolioReport(e)}>
                                    <Table variant="light" striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td>Name of the Investor</td>
                                                <td>{this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Mutual Fund</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            name="fund" 
                                                            variant="secondary" 
                                                            required
                                                            defaultValue={this.state.fund}
                                                            >
                                                                {/* <option value='' disabled>Select fund</option> */}
                                                            {
                                                                this.state.portfolios.map(portfolio=>{
                                                                    return <option 
                                                                        value={portfolio.PORTFOLIO_ID} 
                                                                        data-investor_reg_no={portfolio.INVESTOR_REG_NO} 
                                                                        key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                                })
                                                            }

                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fundError==''?'This field is required':this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Registration No.</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            name="portfolio_code" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            variant="secondary" 
                                                            defaultValue={this.state.portfolio_code}
                                                            required 
                                                            >
                                                                {/* <option value='' disabled>Select reg. no.</option> */}
                                                            {
                                                                this.state.account_list.map(accountlist=>{
                                                                    return <option 
                                                                        value={accountlist.PORTFOLIO_CODE} 
                                                                        key={accountlist.INVESTOR_ACCOUNT_ID}>{accountlist.PORTFOLIO_CODE}</option>  
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.portfolio_codeError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.portfolio_codeError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.portfolio_codeError==''?'This field is required':this.state.portfolio_codeError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>As On</td>
                                                <td>
                                                
                                                    <Form.Group >
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                className="formPlaintextEmail"
                                                                format={date_format}
                                                                onChange={(value) => { this.changeDate('date',value) }} 
                                                                slotProps={{
                                                                    textField: {
                                                                        name: 'date'
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                       
                                                        <Form.Control.Feedback className={this.state.dateError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.dateError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.dateError==''?'This field is required':this.state.dateError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="end" colSpan={2}>
                                                    <Button 
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                        {
                            this.state.showPortfolio==true?
                            <>
                                <div className='text-end vanis'>
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.print(e)} icon={faPrint} /></a> 
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.getPdf(e)} icon={faFilePdf} /></a>
                                </div>
                            <div id="portfolioReport">
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <p className="fw-bold">
                                        <Image
                                            src={logo}
                                            className="d-inline-block align-top print-logo"
                                            alt={name}

                                        />
                                        </p>
                                        <Table variant="light" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <td colSpan={10} align="center">Portfolio History of {this.state.portfolioStatementResult.length>0?this.state.portfolioStatementResult[0].PORTFOLIO_NAME:''}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} align="start">
                                                        {this.state.portfolioStatementResult.length>0?this.state.portfolioStatementResult[0].INVESTOR_NAME:''}<br/>
                                                        {this.state.portfolioStatementResult.length>0?this.state.portfolioStatementResult[0].MAILING_ADDRESS:''}
                                                    </td>
                                                    <td colSpan={5} align="end">
                                                            Date: {dayjs(this.state.date).format(date_format)}<br/>
                                                            Investor ID: {this.state.user.client_id}<br/>
                                                            Registration No.: {this.state.portfolio_code}<br/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Name of Fund</td>
                                                    <td>Investment Type</td>
                                                    <td>Units Held</td>
                                                    <td>Available for Sale/Surrender</td>
                                                    <td>Avg. Investment at Cost</td>
                                                    <td>Total Investment</td>
                                                    <td>NAV at Mkt</td>
                                                    <td>Current Investment at Market Value</td>
                                                    <td>Unrealized Gain</td>
                                                    <td>Unrealized Gain %</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(this.state.portfolioStatementResult) ? (
                                                    this.state.portfolioStatementResult.map((portfolio_statement_data,index)=>{
                                                        TOTAL_DEPOSIT           +=portfolio_statement_data.TOTAL_DEPOSIT
                                                        BALANCE_AMOUNT          +=portfolio_statement_data.BALANCE_AMOUNT
                                                        UNREALIZED_GAIN_LOSS    += portfolio_statement_data.UNREALIZED_GAIN_LOSS
                                                        TOTAL_WITHDRAW			+= portfolio_statement_data.TOTAL_WITHDRAW
                                                        TOTAL_PROFIT_LOSS 		+= portfolio_statement_data.REALIZED_GAIN_LOSS
                                                        TOTAL_BUY_PRICE		    += portfolio_statement_data.TOTAL_BUY_PRICE
                                                        CURRENT_MARKET_VALUE	+= portfolio_statement_data.CURRENT_MARKET_VALUE;
                                                        return <tr key={index}>
                                                                <td>{portfolio_statement_data.PORTFOLIO_NAME}</td>
                                                                <td>{portfolio_statement_data.INVESTMENT_STYLE}</td>
                                                                <td>{portfolio_statement_data.CURRENT_NO_OF_UNITS}</td>
                                                                <td>{portfolio_statement_data.FREE_UNITS}</td>
                                                                <td>{portfolio_statement_data.AVERAGE_BUY_PRICE}</td>
                                                                <td>{portfolio_statement_data.TOTAL_BUY_PRICE}</td>
                                                                <td>{portfolio_statement_data.NAV}</td>
                                                                <td>{portfolio_statement_data.CURRENT_MARKET_VALUE}</td>
                                                                <td>{portfolio_statement_data.UNREALIZED_GAIN_LOSS}</td>
                                                                <td>{portfolio_statement_data.GAIN_LOSS_PERCENT}</td>
                                                            </tr>
                                                        
                                                    })
                                                ):(
                                                    <tr>
                                                        <td className='text-center' colSpan={10}>Data not found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Table className="tax-certificate" variant="light" striped bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td>Total Deposit</td>
                                                    <td>{TOTAL_DEPOSIT+BALANCE_AMOUNT}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Witddrawal</td>
                                                    <td>{TOTAL_WITHDRAW}</td>
                                                </tr>
                                                <tr>
                                                    <td>Investment at Cost</td>
                                                    <td>{TOTAL_BUY_PRICE}</td>
                                                </tr>
                                                <tr>
                                                    <td>Investment at Market</td>
                                                    <td>{CURRENT_MARKET_VALUE}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cash Balance (if any)</td>
                                                    <td>{BALANCE_AMOUNT}</td>
                                                </tr>
                                                <tr>
                                                    <td>Unrealized Gain</td>
                                                    <td>{UNREALIZED_GAIN_LOSS}</td>
                                                </tr>
                                                <tr>
                                                    <td>Realized Gain</td>
                                                    <td>{TOTAL_PROFIT_LOSS}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Gain</td>
                                                    <td>{parseFloat(TOTAL_PROFIT_LOSS) + parseFloat(UNREALIZED_GAIN_LOSS) + parseFloat(TOTAL_DIVIDEND)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Dividend Income</td>
                                                    <td>{TOTAL_DIVIDEND}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Cash Dividend</td>
                                                    <td>{BALANCE}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total CIP (Units)</td>
                                                    <td>{NO_OF_CIP_UNITS}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className='text-end' colSpan={2}>
                                                        <p className="fw-bold text-secondary">{name}</p>
                                                        <p className="text-secondary">{address}</p>
                                                        <p>E-mail: {email}, Website: {web}</p>
                                                        <p>Mobile: {mobile}</p>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </div></>
                            :''
                            
                        }
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}