import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button,InputGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Link,Navigate } from "react-router-dom";
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import Loading from '../loading'
const baseUrl=global.config.data.path.basePath
export default class Forgot extends Component {
    state = {
        validated: false,
        errorStatus:'',
        client_id:'',
        client_idError:'',
        isLoading: false
    }
    componentDidMount(){
        document.title = 'Forgot';
    }
    changeState=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    forgotPassword=(e)=>{
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        e.preventDefault()
        let data = {
            client_id:'AIMS-'+this.state.client_id,
        }
        this.setState({
            isLoading:true
        })
        const url = baseUrl+'/api/forgot-password'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data),
        }).then((res) => {
            this.setState({
                client_idError:''
            }) 
            if(res.data.errors){
                Object.entries(res.data.errors).forEach(([key, value]) => {
                    this.setState({
                        [key+'Error']: value,
                    })
                });
            }else{
                this.setState({
                    errorStatus:res.data.status
                })
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
        }).catch((err) => {
            console.log(err.data)
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    render() {
        const {
            isLoading
        } = this.state
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    this.state.errorStatus==200?<Navigate to="/login" replace={false} />:''
                } 
                <Header/>
                    <Container style={{height:"60vh"}} fluid={true}>
                        <Row className="justify-content-center" >
                            <Col className="my-4" md={6} >
                                <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.forgotPassword(e)}>
                                    <Card className='tab'>
                                        <Card.Body className='my-3'>
                                            <Card.Title>FORGOT PASSWORD</Card.Title>
                                                <Form.Group className="mb-3" controlId="validationCustom01">
                                                    <Form.Label htmlFor="investorId"><span>*</span>Investor ID</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="addition">
                                                            AIMS-
                                                        </InputGroup.Text>
                                                        <Form.Control 
                                                            id="investorId" 
                                                            aria-describedby="addition" 
                                                            placeholder="Investor ID"
                                                            name="client_id"
                                                            type="number"
                                                            onChange={(e) => { this.changeState(e) }}
                                                            required
                                                        />
                                                        <Form.Control.Feedback className={this.state.client_idError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.client_idError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.client_idError==''?'This field is required only number':this.state.client_idError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Button
                                                    className='form-button'
                                                    type="submit"
                                                    id="nextBtn"
                                                >Send <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                <Footer/>
            </>
        );
    }
}