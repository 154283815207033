import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import Loading from '../loading'
import Select from 'react-select'
const baseUrl = global.config.data.path.basePath
const date_format = global.config.data.date.date_format
const date_format_y_m_d = global.config.data.date.date_format_y_m_d
export default class Purchase extends Component {
    state = {
        validated: false,
        authorization: false,
        token: '',
        errorStatus: '',
        otptoken: '',
        showPurchase: true,
        showOtp: false,
        showPurchaseAckTrf: false,

        portfolios: [],
        user: [],
        userPortfolio: [],
        fund: '',
        investmentType: 2,
        account_list: [],
        reg_no: '',
        account_id:'',
        purchase_price:'',
        total_purchase_amount: '',
        no_of_unit: '',
        payment_method: '',
        getBank: [],
        bank_name: '',
        getBranch: [],
        branch_name: '',
        instrument_date: '',
        reference_no: '',
        deposit_receipt: '',
        paymentFrequency: 'Monthly',
        installment_tenure: '03',
        installment_date: '07',

        fundError: '',
        reg_noError: '',
        no_of_unitError: '',
        total_purchase_amountError:'',
        purchase_priceError:'',
        payment_methodError: '',
        bank_nameError: '',
        branch_nameError: '',
        instrument_dateError: '',
        reference_noError: '',
        deposit_receiptError: '',

        otptoken: '',
        otpone: '',
        otptwo: '',
        otpthree: '',
        otpfour: '',
        otpError: '',
        investor_unit_app_id: '',
        fund_name: '',
        isLoading: true,
        sipEnable:false

    }
    componentDidMount() {
        document.title = 'Purchase';
        const token = localStorage.getItem('token')
        if (token) {
            this.setState({
                token: token,
                authorization: true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
        this.getUserPortfolio(token)
        this.getBank(token)
    }
    changeState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name == 'fund') {
            const selectedOption = e.target.options[e.target.selectedIndex];
            this.setState({
                fund_name: selectedOption.textContent
            })
            this.getAccountList(e.target.value, this.state.token)
            this.getCurrentNav(e.target.value, this.state.token)
        } else if (e.target.name == 'no_of_unit') {
            if(this.state.purchase_price==''){
                this.setState({
                    reg_noError:'This field required',
                    total_purchase_amount:'',
                    no_of_unit:''
                })
                return
            }
            this.setState({
                total_purchase_amount: Math.floor(e.target.value * this.state.purchase_price),
                fundError:''
            })
        } else if (e.target.name == 'bank_name') {
            this.getBranch(this.state.bank_name, this.state.token)
        } else if (e.target.name == 'deposit_receipt') {
            this.setState({
                [e.target.name]: e.target.files[0]
            })
        } else if (e.target.name == 'reg_no') {
            if(e.target.value=='sip'){
                this.setState({
                    sipEnable:true
                })
            }else{
                this.setState({
                    investmentType: e.target.selectedOptions[0].getAttribute('data-tag'),
                    account_id:e.target.selectedOptions[0].getAttribute('data-account_id'),
                    reg_noError:''
                })
            }
            
        } 
    }
    changeState2 = (e) => {
        this.setState({
            [e.name]:e.value,
            [e.name+'Error']:''
        })
        if (e.name == 'bank_name') {
            this.getBranch(e.value, this.state.token)
        }
    }
    changeDate = (name, value) => {
        this.setState({
            [name]: dayjs(value).format(date_format_y_m_d),
            [name + 'Error']: ''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    authUser = (token) => {
        const url = baseUrl + '/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                user: res.data.data,
                isLoading: false
            })

        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    errorStatus: err.response.status,
                    isLoading: false
                })
            }
        });
    }
    getPortfolio = (token) => {
        const url = baseUrl + '/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                portfolios: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getUserPortfolio = (token) => {
        const url = baseUrl + '/api/get-user-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                userPortfolio: res.data.data[0]
            })
        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getAccountList = (fund, token) => {
        const url = baseUrl + '/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { fund: fund }
        }).then((res) => {
            if (res.data.status == 200) {
                this.setState({
                    account_list: res.data.data,
                    reg_no: res.data.data[0].INVESTOR_REG_NO
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getCurrentNav = (fund, token) => {
        const url = baseUrl + '/api/get-current-nav'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { fund: fund },
        }).then((res) => {
            if (res.data.status == 200) {
                this.setState({
                    purchase_price: res.data.data[0].SELL_PRICE
                })
            }

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getBank = (token) => {
        const url = baseUrl + '/api/get-bank'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                getBank: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getBranch = (bank_id, token) => {
        const url = baseUrl + '/api/get-branch'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { bank_id: bank_id },
        }).then((res) => {
            this.setState({
                branch_name: res.data.data[0].ORG_BRANCH_ID,
                getBranch: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    otpCheck = (e) => {
        if (e.target.value.length >= 2) {
            this.setState({
                [e.target.name]: '',
            })
        } else {
            if (e.target.name == 'otpone') {
                document.querySelector('input[name="otptwo"]')?.focus()
            } else if (e.target.name == 'otptwo') {
                document.querySelector('input[name="otpthree"]')?.focus()
            } else if (e.target.name == 'otpthree') {
                document.querySelector('input[name="otpfour"]')?.focus()
            }
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    sendOTP = (e) => {
        e.preventDefault();
        const valid = this.checkValidation(e)
        if (!valid) {
            return
        }
        this.setState({
            isLoading: true
        })

        const url = baseUrl + '/api/send-otp'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            params: { otp_type: 'PURCHASE' }
        }).then((res) => {
            this.setState({
                otptoken: res.data.data,
                showOtp: !this.state.showOtp,
                showPurchase: !this.state.showPurchase,
                validated: !this.state.validated
            })
            Swal.fire({
                position: 'top-right',
                text: res.data.message,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        }).catch((err) => {
            
        }).finally(()=>{
            this.setState({
                isLoading: false
            })
        })
    }
    resendOTP = (e) => {
        e.preventDefault();
        const url = baseUrl + '/api/send-otp'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            params: { otp_type: 'PURCHASE' }
        }).then((res) => {
            this.setState({
                otptoken: res.data.data
            })
            Swal.fire({
                position: 'top-right',
                text: res.data.message,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        }).catch((err) => {

        });
    }
    verifyOtp = (e) => {
        e.preventDefault();
        const valid = this.checkValidation(e)
        if (!valid) {
            return
        }
        let data = {
            otptoken: this.state.otptoken,
            otp: String(this.state.otpone) + String(this.state.otptwo) + String(this.state.otpthree) + String(this.state.otpfour),
            otp_type: 'PURCHASE'
        }
        const url = baseUrl + '/api/verifyotp'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }).then((res) => {
            if (res.data.status == 200) {
                this.purchase(e)
            } else {
                this.setState({
                    errorStatus: 400,
                    otpError: res.data.message
                })
            }


        }).catch((err) => {

        });
    }
    purchase = (e) => {
        e.preventDefault()
        const formData = new FormData();
        if (this.state.payment_method == 'AMEX' || this.state.payment_method == 'bKashMFS'
            || this.state.payment_method == 'MB' || this.state.payment_method == 'VC'
            || this.state.payment_method == 'NEXUS' || this.state.payment_method == 'MC') {
            var url = baseUrl + '/api/payment-gateway'
            formData.append('payment_method','online')
        } else {
            var url = baseUrl + '/api/investor-purchase'
            formData.append('payment_method','offline')
            formData.append('bank_name', this.state.bank_name)
            formData.append('branch_name', this.state.branch_name)
        }

        this.setState({
            isLoading: true
        })
        formData.append('investmentType', this.state.investmentType)
        formData.append('fund', this.state.fund)
        formData.append('reg_no', this.state.reg_no)
        formData.append('account_id',this.state.account_id)
        formData.append('total_purchase_amount', this.state.total_purchase_amount)
        formData.append('paymentMethod', this.state.payment_method)
        formData.append('instrument_date', this.state.instrument_date)
        formData.append('reference_no', this.state.reference_no)
        formData.append('no_of_unit', parseInt(this.state.no_of_unit))
        formData.append('deposit_receipt', this.state.deposit_receipt)

        if (this.state.investmentType == 1) {
            formData.append('paymentFrequency', this.state.paymentFrequency)
            formData.append('installment_tenure', this.state.installment_tenure)
            formData.append('installment_date', this.state.installment_date)
        }

        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
        })
            .then((response) => {
                if (response.data.errors) {
                    Object.entries(response.data.errors).forEach(([key, value]) => {
                        this.setState({
                            [key + 'Error']: value,
                        })
                    });
                    this.setState({
                        showOtp: !this.state.showOtp,
                        showPurchase: !this.state.showPurchase,
                    })
                } else if (response.data.status == 402) {
                    Swal.fire({
                        position: 'top-right',
                        text: response.data.message,
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                    this.setState({
                        isLoading: false
                    })
                } else if (this.state.payment_method == 'AMEX' || this.state.payment_method == 'bKashMFS'
                    || this.state.payment_method == 'MB' || this.state.payment_method == 'VC'
                    || this.state.payment_method == 'NEXUS' || this.state.payment_method == 'MC') {
                    window.location.href = response.data
                    return
                } else {
                    if (response.data.status == 200) {
                        this.setState({
                            errorStatus: response.data.status,
                            showOtp: !this.state.showOtp,
                            showPurchaseAckTrf: !this.state.showPurchaseAckTrf,
                            investor_unit_app_id: response.data.data.investor_unit_app_id
                        })
                        Swal.fire({
                            position: 'top-right',
                            text: response.data.message,
                            icon: 'success',
                            timer: 2000,
                            showConfirmButton: false,
                        })
                    }
                }
                this.setState({
                    isLoading: false
                })
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    this.setState({
                        authorization: false,
                        errorStatus: err.response.status,
                    })
                    Swal.fire({
                        position: 'top-right',
                        text: err.response.statusText,
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                }
                this.setState({
                    isLoading: false
                })
            });

    }
    CDBL_form = (e) => {
        e.preventDefault()
        const url = baseUrl + '/api/cdbl-transfer-form'
        this.setState({
            isLoading: true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            params: { investor_unit_app_id: this.state.investor_unit_app_id }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `CDBL-${this.state.user.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            this.setState({
                isLoading: false
            })
        });
    }
    ACK_form = (e) => {
        e.preventDefault()
        const url = baseUrl + '/api/purchase-download'
        this.setState({
            loading: true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            params: {
                fund_name: this.state.fund_name, reg_no: this.state.reg_no, no_of_unit: this.state.no_of_unit,
                purchase_price: this.state.purchase_price, total_purchase_amount: this.state.total_purchase_amount,
                client_id: this.state.user.client_id
            }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Ack-${this.state.user.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            this.setState({
                isLoading: false
            })
        });
    }
    checkValidation = (event) => {
        let valid = 1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid = 0
        }
        if (this.state.payment_method != 'AMEX' && this.state.payment_method != 'bKashMFS'
            && this.state.payment_method != 'MB' && this.state.payment_method != 'VC'
            && this.state.payment_method != 'NEXUS' && this.state.payment_method != 'MC') {
            if(this.state.bank_name=='' || this.state.branch_name=='' || this.state.instrument_date==''){
                if(this.state.bank_name==''){
                    this.setState({
                        bank_nameError:'This field is required'
                    })
                }if(this.state.branch_name==''){
                    this.setState({
                        branch_nameError:'This field is required'
                    })
                }if(this.state.instrument_date==''){
                    this.setState({
                        instrument_dateError:'This field is required'
                    })
                }
                valid = 0
            }
        }
        this.setState({
            validated: true
        })
        return valid
    }

    render() {
        const { token = undefined, isLoading,sipEnable } = this.state;
        const step_check = localStorage.getItem('STEP_CHECK')
        let all_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
        let available_days=['Sun', 'Mon', 'Tue', 'Wed','Thu']
        if (isLoading) {
            return (
                <Loading loading={isLoading} />
            )
        }
        return (
            <>
                {
                    token != undefined ? (this.state.errorStatus == 401 ? <Navigate to="/login" replace={false} /> : '') : <Navigate to="/login" replace={false} />
                }
                {
                    (step_check == 'null' || step_check == 'INVESTMENT' || step_check == 'BOACCOUNT' || step_check == 'PAYMENTOP' ||
                        step_check == 'PAYMENTOP' || step_check == 'PAYMENTOP') ? <Navigate to="/login" replace={false} /> :
                        (
                            step_check != 'FINISH' ?
                                <Navigate to="/application-form" replace={false} /> :
                                ''
                        )
                }
                {   sipEnable &&
                    <Navigate to="/create-new-account" replace={false} />
                }
                <Header auth={this.state.authorization} />
                <Container className='content-area' fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center">
                            {
                                this.state.showPurchase == true ?
                                    <><Col md={6}>
                                        <p align="start" className="fw-bold">Purchase Request Form</p>
                                        <p align="start" className="fw-light">
                                            You are requested to deposit Cheque / Pay Order / Demand Draft / Online Reference No. within Sunday to Wednesday.
                                        </p>
                                        <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e) => this.sendOTP(e)}>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                                <Form.Label column sm="3">
                                                    Investor's Name<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control disabled type="text" defaultValue={
                                                        this.state.user.name == null ? this.state.user.institution_name : this.state.user.name} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="3">
                                                    Investor ID<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control disabled type="text" defaultValue={this.state.user.client_id} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="3">
                                                    Investment Vehicle<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        as="select"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        name="fund"
                                                        variant="secondary"
                                                        required
                                                        defaultValue={this.state.fund}
                                                    >
                                                        <option value='' disabled>Select Investment Vehicle</option>
                                                        {
                                                            this.state.portfolios.map(portfolio => {
                                                                return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>
                                                            })
                                                        }

                                                    </Form.Control>
                                                    <Form.Control.Feedback className={this.state.fundError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.fundError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.fundError == '' ? 'This field is required' : this.state.fundError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="3">
                                                    Investment Type<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control as="select"
                                                        name="reg_no"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        variant="secondary"
                                                        defaultValue={this.state.reg_no}
                                                        required
                                                    >
                                                        <option value='' disabled>Select Investment Type</option>
                                                        {
                                                            this.state.account_list.map(accountlist => {
                                                                if (accountlist.INVESTMENT_TYPE_ID == 2) {
                                                                    return <option
                                                                        data-tag={accountlist.INVESTMENT_TYPE_ID}
                                                                        data-account_id={accountlist.INVESTOR_ACCOUNT_ID}
                                                                        value={accountlist.INVESTOR_REG_NO}
                                                                        key={accountlist.INVESTOR_REG_NO}>
                                                                        {accountlist.INVESTMENT_TYPE_ID == 1 ? accountlist.INVESTOR_REG_NO : "Non-SIP"}
                                                                    </option>
                                                                }
                                                            })
                                                        }
                                                        <option value='sip'>SIP</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback className={this.state.reg_noError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.reg_noError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.reg_noError == '' ? 'This field is required' : this.state.reg_noError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            {
                                                (this.state.investmentType == 1) ?
                                                    <>
                                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                            <Form.Label column sm="3">
                                                                Installment Tenure<span>*</span>
                                                            </Form.Label>
                                                            <Col sm="8">
                                                                <Form.Control as="select"
                                                                    name="installment_tenure"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    variant="secondary"
                                                                    required
                                                                    defaultValue={this.state.installment_tenure}
                                                                >
                                                                    <option value="03">03</option>
                                                                    <option value="05">05</option>
                                                                    <option value="07">07</option>
                                                                    <option value="10">10</option>
                                                                </Form.Control>

                                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                <Form.Control.Feedback className="validation" type="invalid">
                                                                    This field is required
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                            <Form.Label column sm="3">
                                                                Installment Date<span>*</span>
                                                            </Form.Label>
                                                            <Col sm="8">
                                                                <Form.Control as="select"
                                                                    name="installment_date"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    variant="secondary"
                                                                    required
                                                                    defaultValue={this.state.installment_date}
                                                                >
                                                                    <option value="07">07</option>
                                                                    <option value="17">17</option>
                                                                    <option value="27">27</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                <Form.Control.Feedback className="validation" type="invalid">
                                                                    This field is required
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>

                                                    </> : ''
                                            }
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="3">
                                                    Buy Units<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        type="number"
                                                        onChange={(e) => { this.changeState(e) }}
                                                        onWheel={(e) => e.target.blur()}
                                                        name="no_of_unit"
                                                        value={this.state.no_of_unit}
                                                        required
                                                    />
                                                    <Form.Control.Feedback className={this.state.no_of_unitError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.no_of_unitError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.no_of_unitError == '' ? 'This field is required' : this.state.no_of_unitError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="3">
                                                    Buy Price/NAV Per Unit<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control 
                                                        className='auto-fill-bg'
                                                        required
                                                        onKeyDown ={(e)=>e.preventDefault()}  
                                                        type="text" 
                                                        defaultValue={this.state.purchase_price} 
                                                    />
                                                    <Form.Control.Feedback className={this.state.purchase_priceError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.purchase_priceError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.purchase_priceError == '' ? 'This field is required' : this.state.purchase_priceError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="3">
                                                    Investment Amount<span>*</span>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control 
                                                        className='auto-fill-bg'
                                                        required
                                                        onKeyDown ={(e)=>e.preventDefault()} 
                                                        type="text" 
                                                        defaultValue={this.state.total_purchase_amount} 
                                                    />
                                                    <Form.Control.Feedback className={this.state.total_purchase_amountError != '' ? 'show' : ''} type="invalid">
                                                        {
                                                            this.state.total_purchase_amountError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.total_purchase_amountError == '' ? 'This field is required' : this.state.total_purchase_amountError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Col sm={11}>
                                                    <fieldset className="border p-2">
                                                        <legend className="float-none w-auto">Payment Details</legend>
                                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                                            <Form.Label column sm="3">
                                                                Payment Method<span>*</span>
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control
                                                                    as="select"
                                                                    variant="secondary"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    required
                                                                    name="payment_method"
                                                                    defaultValue={this.state.payment_method}
                                                                >
                                                                    <option value='' disabled>Select Payment Type</option>
                                                                    <option value="cheque">Cheque</option>
                                                                    <option value="po">Pay Order</option>
                                                                    <option value="dd">Demand Draft</option>
                                                                    <option value="rtgs">RTGS</option>
                                                                    <option value="beftn">BEFTN</option>
                                                                    <option value="npsb">NPSB</option>
                                                                    <option value="bKashMFS">Bkash</option>
                                                                    <option value="MB">Rocket</option>
                                                                    <option value="NEXUS">NexusPay</option>
                                                                    <option value="MC">MasterCard</option>
                                                                    <option value="VC">Visa</option>
                                                                    <option className='hide' value="AMEX">AMEX</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.payment_methodError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.payment_methodError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.payment_methodError == '' ? 'This field is required' : this.state.payment_methodError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                        {
                                                            this.state.payment_method == 'AMEX' || this.state.payment_method == 'bKashMFS'
                                                                || this.state.payment_method == 'MB' || this.state.payment_method == 'VC'
                                                                || this.state.payment_method == 'NEXUS' || this.state.payment_method == 'MC' ? '' :
                                                                <>
                                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                                        <Form.Label column sm="3">
                                                                            Bank Name<span>*</span>
                                                                        </Form.Label>
                                                                        <Col sm="9">
                                                                            {/* <Form.Control
                                                                                as="select"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="bank_name"
                                                                                variant="secondary"
                                                                                className="validation"
                                                                                required
                                                                                value={this.state.bank_name}
                                                                            >
                                                                                <option value='' disabled>Select Bank Name</option>
                                                                                {
                                                                                    this.state.getBank.map(bank => {
                                                                                        return <option value={bank.ORG_ID} key={bank.ORG_ID}>{bank.ORG_NAME}</option>
                                                                                    })
                                                                                }

                                                                            </Form.Control> */}

                                                                            <Select 
                                                                                closeMenuOnSelect={true}
                                                                                required
                                                                                defaultValue={this.state.bank_name}
                                                                                onChange={(e) => { this.changeState2(e) }}
                                                                                options=
                                                                                    {
                                                                                        this.state.getBank.map(bank => 
                                                                                            (
                                                                                                { 
                                                                                                    label: bank.ORG_NAME,
                                                                                                    value: bank.ORG_ID,
                                                                                                    name:'bank_name'
                                                                                                }
                                                                                            ))
                                                                                    }
                                                                                    placeholder='Select Bank Name'
                                                                            />

                                                                            <Form.Control.Feedback className={this.state.bank_nameError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.bank_nameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                                        <Form.Label column sm="3">
                                                                            Branch Name<span>*</span>
                                                                        </Form.Label>
                                                                        <Col sm="9">
                                                                            {/* <Form.Control
                                                                                as="select"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="branch_name"
                                                                                variant="secondary"
                                                                                className="validation"
                                                                                required
                                                                                value={this.state.branch_name}
                                                                            >
                                                                                <option value='' disabled>Select Branch Name</option>
                                                                                {
                                                                                    this.state.getBranch.map(branch => {
                                                                                        return <option value={branch.ORG_BRANCH_ID} key={branch.ORG_BRANCH_ID}>{branch.BRANCH_NAME}</option>
                                                                                    })
                                                                                }

                                                                            </Form.Control> */}
                                                                            <Select 
                                                                                closeMenuOnSelect={true}
                                                                                defaultValue={this.state.branch_name}
                                                                                onChange={(e) => { this.changeState2(e) }}
                                                                                options=
                                                                                    {
                                                                                        this.state.getBranch.map(branch => 
                                                                                            (
                                                                                                { 
                                                                                                    label: branch.BRANCH_NAME,
                                                                                                    value: branch.ORG_BRANCH_ID,
                                                                                                    name:'branch_name'
                                                                                                }
                                                                                            ))
                                                                                    }
                                                                                placeholder='Select Branch Name'
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.branch_nameError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.branch_nameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                                        <Form.Label column sm="3">
                                                                            Deposited Instrument Date<span>*</span>
                                                                        </Form.Label>
                                                                        <Col sm="9">
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    className="formPlaintextEmail"
                                                                                    format={date_format}
                                                                                    onChange={(value) => { this.changeDate('instrument_date', value) }}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            name: 'instrument_date'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                            <Form.Control.Feedback className={this.state.instrument_dateError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.instrument_dateError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                                        <Form.Label column sm="3">
                                                                            Reference No.<span>*</span>
                                                                        </Form.Label>
                                                                        <Col sm="9">
                                                                            <Form.Control
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="reference_no"
                                                                                type="text"
                                                                                required
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.reference_noError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.reference_noError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.reference_noError == '' ? 'This field is required' : this.state.reference_noError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                                        <Form.Label column sm="3">
                                                                            Deposit/Fund Transfer Receipt<span>*</span>
                                                                        </Form.Label>
                                                                        <Col sm="9">
                                                                            <Form.Control
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="deposit_receipt"
                                                                                type="file"
                                                                                required
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.deposit_receiptError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.deposit_receiptError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.deposit_receiptError == '' ? 'This field is required' : this.state.deposit_receiptError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                    </Form.Group>
                                                                </>
                                                        }

                                                    </fieldset>
                                                </Col>
                                            </Form.Group>
                                            {
                                                available_days.includes(all_days) &&(
                                                    <Row className='justify-content-end' >
                                                        <Col align="start" md={4}>
                                                            <Button
                                                                className='form-button my-4'
                                                                type="submit"
                                                                id="nextBtn"
        
                                                            >Submit <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Form>
                                    </Col>
                                        <Col md={4}>
                                            <p align="start" className="fw-bold">Fund Transfer and Deposit Details
                                            </p>
                                            <ListGroup>
                                                <ListGroup.Item variant="danger">
                                                    <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>Fund Transfer/Deposit to Account</span>
                                                    <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>GB - AIMS FIRST UF - SALE</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>Account Number</span>
                                                    <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>1310000102465</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item variant="danger">
                                                    <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>Name of the Bank	</span>
                                                    <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>Mutual Trust Bank Ltd.</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item >
                                                    <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>Branch</span>
                                                    <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>Panthapath Branch</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item variant="danger" >
                                                    <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>Routing Number</span>
                                                    <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>145263610</span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col></> : ''
                            }
                            {
                                this.state.showOtp == true ?
                                    <Col className="my-4" md={6} >
                                        <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e) => this.verifyOtp(e)}>
                                            <Card>
                                                <Card.Body className='my-3'>
                                                    <Card.Title>OTP CONFIRMATION</Card.Title>
                                                    <Card.Text>
                                                        Provide The 4 Digit OTP Sent To Your Mobile Number/Email
                                                    </Card.Text>
                                                    <Form.Group className="mb-3" controlId="validationCustom01">
                                                        <Row>
                                                            <Col className='me-2' md={1}>
                                                                <Form.Control
                                                                    required
                                                                    minLength="1"
                                                                    maxLength="1"
                                                                    type="number"

                                                                    name="otpone"
                                                                    value={this.state.otpone}
                                                                    onChange={(e) => { this.otpCheck(e) }}
                                                                />
                                                            </Col>

                                                            <Col className='me-2' md={1}>
                                                                <Form.Control
                                                                    required
                                                                    minLength="1"
                                                                    maxLength="1"
                                                                    type="number"

                                                                    name="otptwo"
                                                                    value={this.state.otptwo}
                                                                    onChange={(e) => { this.otpCheck(e) }}
                                                                />
                                                            </Col>
                                                            <Col className='me-2' md={1}>
                                                                <Form.Control
                                                                    required
                                                                    minLength="1"
                                                                    maxLength="1"
                                                                    type="number"

                                                                    name="otpthree"
                                                                    value={this.state.otpthree}
                                                                    onChange={(e) => { this.otpCheck(e) }}
                                                                />
                                                            </Col>
                                                            <Col className='me-2' md={1}>
                                                                <Form.Control
                                                                    required
                                                                    minLength="1"
                                                                    maxLength="1"
                                                                    type="number"

                                                                    name="otpfour"
                                                                    value={this.state.otpfour}
                                                                    onChange={(e) => { this.otpCheck(e) }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Form.Control.Feedback className={this.state.otpError != '' ? 'show' : ''} type="invalid">
                                                            {
                                                                this.state.otpError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Card.Text className='danger' align="right">
                                                        <a onClick={(e) => this.resendOTP(e)}>RESEND OTP</a>
                                                    </Card.Text>
                                                    <Button
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Verify <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                </Card.Body>
                                            </Card>
                                        </Form>
                                    </Col> : ''
                            }
                            {
                                this.state.showPurchaseAckTrf == true ?
                                    <>
                                        <Col className=' text-end' md={12}>
                                            <a className='btn btn-danger' onClick={(e) => { this.ACK_form(e) }} >Acknowledgement Download</a>
                                            {/* <a className='btn btn-danger ms-2' onClick={(e) => { this.CDBL_form(e) }} >CDBL Form Download</a> */}
                                        </Col>
                                        <Col md={12}>
                                            <Card className='mt-4'>
                                                <Card.Body className='my-3'>
                                                    <p>Dear Valued Investor, thank you for submitting your purchase request and necessary information. Your purchase request is under process now. Once your payment is cleared, we will notify you and allocate the units to your BO accounts within 7 working days.
                                                        Please Download or Print Acknowledgement Slip and keep it for your reference.</p>
                                                </Card.Body>
                                            </Card>
                                        </Col></> : ''

                            }
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}