import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import '../constants/env';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Header from '../header'
import Footer from '../footer'
import Loading from '../loading'
import Swal from 'sweetalert2'
import Select from 'react-select'
const baseUrl = global.config.data.path.basePath
const date_format = global.config.data.date.date_format
const date_format_y_m_d = global.config.data.date.date_format_y_m_d
export default class ApplicationForm extends Component {
    state = {
        validated: false,
        authorization: false,
        addAnother: true,
        showApplicationForm: false,
        showNomineeForm: false,
        showDocumentForm: false,
        showPaymentInstruction: false,
        showProfile: false,
        token: '',
        errorStatus: '',
        user: [],
        investor: [],
        joint_investor: [],
        name: '',
        dob: '',
        gender: 'Male',
        residency: 'resident',
        fname: '',
        mname: '',
        sname: '',
        getOcupation: [],
        occupation: "4",
        id_type: '',
        id_no: '',
        passport_no: '',
        issue_place: '',
        issue_date: '',
        expiry_date: '',
        country: '1',
        getNationality: [],
        address: '',
        post_code: '',
        division: '',
        district: '',
        thana: '',
        primary_same_present: '',
        secondary_same_present: '',
        mailing_country: '1',
        mailing_address: '',
        mailing_post_code: '',
        mailing_division: '',
        mailing_district: '',
        mailing_thana: '',
        mobile: '',
        email: '',
        e_tin: '',
        source_fund_salary: '',
        source_fund_business: '',
        source_fund_saving: '',
        source_fund_inheritance: '',
        source_fund_spouse: '',
        asset: '',
        investment: '',
        rental: '',
        other: '',


        secondary_name: '',
        secondary_dob: '',
        secondary_gender: 'Male',
        secondary_residency: 'resident',
        secondary_fname: '',
        secondary_mname: '',
        secondary_sname: '',
        getOcupation: [],
        secondary_occupation: "4",
        secondary_id_no: '',
        secondary_passport_no: '',
        secondary_issue_place: '',
        secondary_issue_date: '',
        secondary_expiry_date: '',
        secondary_country: '1',
        secondary_getNationality: [],
        secondary_address: '',
        secondary_post_code: '',
        secondary_division: '',
        secondary_district: '',
        secondary_thana: '',
        secondary_mailing_country: '1',
        mailing_address: '',
        secondary_mailing_post_code: '',
        secondary_mailing_division: '',
        secondary_mailing_district: '',
        secondary_mailing_thana: '',
        secondary_mobile: '',
        secondary_email: '',
        secondary_e_tin: '',
        secondary_source_fund_salary: '',
        secondary_source_fund_business: '',
        secondary_source_fund_saving: '',
        secondary_source_fund_inheritance: '',
        secondary_source_fund_spouse: '',
        secondary_asset: '',
        secondary_investment: '',
        secondary_rental: '',
        secondary_other: '',

        nameError: '',
        dobError: '',
        genderError: '',
        residencyError: '',
        fnameError: '',
        mnameError: '',
        snameError: '',
        occupationError: '',
        id_typeError: '',
        id_noError: '',
        passport_noError: '',
        issue_placeError: '',
        issue_dateError: '',
        expiry_dateError: '',
        countryError: '',
        addressError: '',
        post_codeError: '',
        divisionError: '',
        districtError: '',
        thanaError: '',
        mailing_countryError: '',
        mailing_addressError: '',
        mailing_post_codeError: '',
        mailing_divisionError: '',
        mailing_districtError: '',
        mailing_thanaError: '',
        mobileError: '',
        emailError: '',
        e_tinError: '',

        secondary_nameError: '',
        secondary_dobError: '',
        secondary_genderError: '',
        secondary_residencyError: '',
        secondary_fnameError: '',
        secondary_mnameError: '',
        secondary_snameError: '',
        secondary_occupationError: '',
        secondary_countryError: '',
        secondary_addressError: '',
        secondary_post_codeError: '',
        secondary_divisionError: '',
        secondary_districtError: '',
        secondary_thanaError: '',
        secondary_mailing_countryError: '',
        secondary_mailing_addressError: '',
        secondary_mailing_post_codeError: '',
        secondary_mailing_divisionError: '',
        secondary_mailing_districtError: '',
        secondary_mailing_thanaError: '',
        secondary_mobileError: '',
        secondary_emailError: '',
        secondary_e_tinError: '',



        skipNominee: false,
        n_name: '',
        n_dob: '',
        n_gender: 'Male',
        n_residency: 'resident',
        n_fname: '',
        n_mname: '',
        n_sname: '',
        n_occupation: '4',
        n_id_no: '',
        n_passport_no: '',
        n_issue_place: '',
        n_issue_date: '',
        n_expiry_date: '',
        n_country: '1',
        n_address: '',
        n_post_code: '',
        n_division: '',
        n_district: '',
        n_thana: '',
        n_mailing_country: '1',
        n_mailing_post_code: '',
        n_mailing_division: '',
        n_mailing_district: '',
        n_mailing_thana: '',
        n_mobile: '',
        n_email: '',
        getRelation: [],
        n_relationship: '1',
        n_paymentType: '',


        n_nameError: '',
        n_dobError: '',
        n_genderError: '',
        n_residencyError: '',
        n_fnameError: '',
        n_mnameError: '',
        n_snameError: '',
        n_occupationError: '',
        n_countryError: '',
        n_addressError: '',
        n_post_codeError: '',
        n_divisionError: '',
        n_districtError: '',
        n_thanaError: '',
        n_mailing_countryError: '',
        n_mailing_post_codeError: '',
        n_mailing_divisionError: '',
        n_mailing_districtError: '',
        n_mailing_thanaError: '',
        n_mobileError: '',
        n_emailError: '',
        n_relationshipError: '',
        n_mailing_addressError: '',

        p_a_nid: '',
        p_a_photo: '',
        p_a_signature: '',
        p_a_tin: '',
        j_a_nid: '',
        j_a_photo: '',
        j_a_signature: '',
        j_a_tin: '',
        nominee_nid: '',
        nominee_photo: '',
        nominee_signature: '',

        p_a_nidError: '',
        p_a_photoError: '',
        p_a_signatureError: '',
        p_a_tinError: '',
        j_a_nidError: '',
        j_a_photoError: '',
        j_a_signatureError: '',
        j_a_tinError: '',
        nominee_nidError: '',
        nominee_photoError: '',
        nominee_signatureError: '',

        bank_ac_name: '',
        bank_ac_no: '',
        getBank: [],
        bank_name:'',
        getBranch: [],
        branch_name: '',
        routing_no: '',
        installment_method: '',
        dividend_payment: '',
        payment_date: '',
        tenure_payment: '',
        from_date: '',
        to_date: '',

        bank_ac_nameError: '',
        bank_ac_noError: '',
        bank_nameError: '',
        branch_nameError: '',
        routing_noError: '',
        installment_methodError: '',
        dividend_paymentError: '',

        registration_no: '',
        institution_name: '',
        type_of_institution: 'Local Company',
        inst_short_name: '',
        inst_tin_no: '',
        inst_ceo_name: '',
        mode_of_operation: 'Jointly',
        inst_bo_account_no: '',
        inst_bo_account_name: '',
        inst_telephone_office: '',
        inst_fax: '',
        inst_mailing_address: '',
        auth_persons: [''],
        auth_per_name: Array(5).fill(''),
        auth_per_desg: Array(5).fill(''),
        auth_per_nid: Array(5).fill(),
        auth_per_photo: Array(5).fill(''),
        auth_per_sign: Array(5).fill(''),
        inst_ceo_photo: '',


        registration_noError: '',
        institution_nameError: '',
        type_of_institutionError: '',
        inst_short_nameError: '',
        inst_tin_noError: '',
        inst_ceo_nameError: '',
        mode_of_operationError: '',
        inst_bo_account_noError: '',
        inst_bo_account_nameError: '',
        inst_telephone_officeError: '',
        inst_faxError: '',
        inst_mailing_addressError: '',
        inst_ceo_photoError: '',

        operation_mode: '',
        isLoading: true
    }

    changeState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name == 'bank_name') {
            this.getBranch(this.state.bank_name, this.state.token)
        } else if (e.target.name == 'branch_name') {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const customAttrValue = selectedOption.dataset.customAttr;
            this.setState({
                routing_no: customAttrValue
            })
        } else if (e.target.name == 'n_paymentType') {
            if (e.target.value != this.state.n_paymentType) {
                this.setState({
                    n_paymentType: e.target.value,
                    n_mailing_country: this.state.n_country,
                    n_mailing_address: this.state.n_address
                })
            } else {
                this.setState({
                    n_paymentType: 'off',
                    n_mailing_country: 1,
                    n_mailing_address: ''
                })
            }
        } else if (e.target.name == 'primary_same_present') {
            if (e.target.value != this.state.primary_same_present) {
                this.setState({
                    primary_same_present: e.target.value,
                    mailing_country: this.state.country,
                    mailing_address: this.state.address
                })
            } else {
                this.setState({
                    primary_same_present: 'off',
                    mailing_country: 1,
                    mailing_address: ''
                })
            }
        } else if (e.target.name == 'secondary_same_present') {
            if (e.target.value != this.state.secondary_same_present) {
                this.setState({
                    secondary_same_present: e.target.value,
                    secondary_mailing_country: this.state.secondary_country,
                    secondary_mailing_address: this.state.secondary_address
                })
            } else {
                this.setState({
                    secondary_same_present: 'off',
                    secondary_mailing_country: 1,
                    secondary_mailing_address: ''
                })
            }
        }
    }
    changeState2 = (e) => {
        this.setState({
            [e.name]:e.value,
            [e.name+'Error']:''
        })
        if (e.name == 'bank_name') {
            this.getBranch(e.value, this.state.token)
        }
    }
    changeFileState = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    changeDate = (name, value) => {
        this.setState({
            [name]: dayjs(value).format(date_format_y_m_d),
            [name + 'Error']: ''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }

    changeAuthorizedState = (e, index) => {
        const data = [...this.state[e.target.name]]
        data[index] = e.target.value
        this.setState({
            [e.target.name]: data
        })
    }
    changeAuthorizedFileState = (e, index) => {
        const data = [...this.state[e.target.name]]
        data[index] = e.target.files[0]
        this.setState({
            [e.target.name]: data
        })
    }

    handleAddField = (e) => {
        e.preventDefault()
        this.setState((prevState) => ({
            auth_persons: [...prevState.auth_persons, '']
        }));
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const status = urlParams.get('status');
        if (status == 200) {
            Swal.fire({
                position: 'top-right',
                text: 'Your Request Successfully Received',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        } else if (status != null) {
            Swal.fire({
                position: 'top-right',
                text: 'Invalid Payment Request',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            })
        }

        document.title = 'Application Form';
        const token = localStorage.getItem('token')
        if (token) {
            this.setState({
                token: token,
                authorization: true
            })
        }
        this.checkStep(token)
        this.authUser(token)
        this.getOcupation(token)
        this.getNationality(token)
        this.getRelation(token)
        this.getBank(token)
        this.getBranch(this.state.bank_name, token)
    }
    checkStep = (token) => {
        const url = baseUrl + '/api/get-step-check'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                isLoading: false
            })
            if (res.data.status == 200) {
                if (res.data.data[0].STEP_CHECK == 'INVESTMENT_COMPLETE') {
                    this.setState({
                        showApplicationForm: !this.state.showApplicationForm,
                        operation_mode: res.data.data[0].OPERATION_MODE_ID,
                        issue_place: res.data.data[0].PP_ISSUE_PLACE,
                        inst_bo_account_no: res.data.data[0].INST_BO_ACCOUNT_NO,
                        issue_date: res.data.data[0].PP_DATE_OF_ISSUE,
                        // expiry_date: res.data.data[0].PP_VALIDITY
                    })
                } else if (res.data.data[0].STEP_CHECK == "APPLICATIONFORM") {
                    if (res.data.data[0].OPERATION_MODE_ID == 3) {
                        this.setState({
                            showPaymentInstruction: !this.state.showPaymentInstruction,
                            operation_mode: res.data.data[0].OPERATION_MODE_ID,
                            issue_place: res.data.data[0].PP_ISSUE_PLACE,
                            issue_date: res.data.data[0].PP_DATE_OF_ISSUE,
                            // expiry_date: res.data.data[0].PP_VALIDITY
                        })
                    } else {
                        this.setState({
                            showNomineeForm: !this.state.showNomineeForm,
                            operation_mode: res.data.data[0].OPERATION_MODE_ID,
                            issue_place: res.data.data[0].PP_ISSUE_PLACE,
                            issue_date: res.data.data[0].PP_DATE_OF_ISSUE,
                            // expiry_date: res.data.data[0].PP_VALIDITY
                        })
                    }
                } else if (res.data.data[0].STEP_CHECK == "DOCUMENTSUBMISSION") {
                    this.setState({
                        showDocumentForm: !this.state.showDocumentForm,
                        operation_mode: res.data.data[0].OPERATION_MODE_ID,
                        issue_place: res.data.data[0].PP_ISSUE_PLACE,
                        issue_date: res.data.data[0].PP_DATE_OF_ISSUE,
                        // expiry_date: res.data.data[0].PP_VALIDITY
                    })
                } else if (res.data.data[0].STEP_CHECK == "BANKDETAILS") {
                    this.setState({
                        showPaymentInstruction: !this.state.showPaymentInstruction,
                        operation_mode: res.data.data[0].OPERATION_MODE_ID,
                        issue_place: res.data.data[0].PP_ISSUE_PLACE,
                        issue_date: res.data.data[0].PP_DATE_OF_ISSUE,
                        // expiry_date: res.data.data[0].PP_VALIDITY
                    })
                } else if (res.data.data[0].STEP_CHECK == "FINISH") {
                    this.setState({
                        showProfile: !this.state.showProfile
                    })
                } else if (res.data.data[0].STEP_CHECK == null) {
                    this.setState({
                        errorStatus: 401
                    })
                }
            }

        }).catch((err) => {
            this.setState({
                isLoading: false
            })
        });
    }
    authUser = (token) => {
        const url = baseUrl + '/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                user: res.data.data,
                name: res.data.data.name,
                id_no: res.data.data.nid != null ? res.data.data.nid : '1122',
                passport_no: res.data.data.passport_no,
                mobile: res.data.data.mobile_no,
                email: res.data.data.email,
                registration_no: res.data.data.registration_no,
                institution_name: res.data.data.institution_name
            })

        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    errorStatus: err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
        });
    }
    getOcupation = (token) => {
        const url = baseUrl + '/api/get-ocupation'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                getOcupation: res.data.data
            })

        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    authorization: false,
                    errorStatus: err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
        });
    }
    getNationality = (token) => {
        const url = baseUrl + '/api/get-nationality'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                getNationality: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getRelation = (token) => {
        const url = baseUrl + '/api/get-relation'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                getRelation: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    applicantForm = (e) => {
        e.preventDefault()
        const valid = this.checkValidation(e)
        if (!valid) {
            return
        }

        if (this.state.dob == '' && this.state.operation_mode != 3) {
            if (this.state.dob == '') {
                this.setState({
                    dobError: 'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.border = "1px solid red";
                }
                return
            }
        }

        if (this.state.secondary_dob == '' && this.state.operation_mode == 2) {
            this.setState({
                secondary_dobError: 'This field is required'
            })
            var elements = document.getElementsByClassName("MuiInputBase-root");
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.border = "1px solid red";
            }
            return
        }

        this.setState({
            isLoading: true
        })

        if (this.state.operation_mode == 3) {
            var data = new FormData();
            data.append('type_of_institution', this.state.type_of_institution)
            data.append('inst_short_name', this.state.inst_short_name)
            data.append('inst_tin_no', this.state.inst_tin_no)
            data.append('inst_ceo_name', this.state.inst_ceo_name)
            data.append('mode_of_operation', this.state.mode_of_operation)
            data.append('inst_bo_account_name', this.state.inst_bo_account_name)
            data.append('inst_telephone_office', this.state.inst_telephone_office)
            data.append('inst_fax', this.state.inst_fax)
            data.append('inst_mailing_address', this.state.inst_mailing_address)
            data.append('inst_ceo_photo', this.state.inst_ceo_photo);

            data.append('authorized_list_length', this.state.auth_per_name.length);
            data.append('auth_per_name', this.state.auth_per_name);
            data.append('auth_per_desg', this.state.auth_per_desg);
            data.append('auth_per_nid', this.state.auth_per_nid);
            for (let i = 0; i < this.state.auth_per_photo.length; i++) {
                data.append(`auth_per_photo${i}`, this.state.auth_per_photo[i]);
            }
            for (let i = 0; i < this.state.auth_per_sign.length; i++) {
                data.append(`auth_per_sign${i}`, this.state.auth_per_sign[i]);
            }

            var content_type = 'multipart/form-data'
            var accept = ''
        } else {
            var data = {
                name: this.state.name,
                dob: this.state.dob,
                gender: this.state.gender,
                residency: this.state.residency,
                fname: this.state.fname,
                mname: this.state.mname,
                sname: this.state.sname,
                occupation: this.state.occupation,
                id_type: this.state.id_type,
                id_no: this.state.id_no,
                issue_place: this.state.issue_place,
                issue_date: this.state.issue_date,
                expiry_date: this.state.expiry_date,
                country: this.state.country,
                address: this.state.address,
                post_code: this.state.post_code,
                division: this.state.division,
                district: this.state.district,
                thana: this.state.thana,
                mailing_address: this.state.mailing_address,
                mailing_country: this.state.mailing_country,
                mailing_post_code: this.state.mailing_post_code,
                mailing_division: this.state.mailing_division,
                mailing_district: this.state.mailing_district,
                mailing_thana: this.state.mailing_thana,
                mobile: this.state.mobile,
                email: this.state.email,
                e_tin: this.state.e_tin,
                source_fund_salary: this.state.source_fund_salary,
                source_fund_business: this.state.source_fund_business,
                source_fund_saving: this.state.source_fund_saving,
                source_fund_inheritance: this.state.source_fund_inheritance,
                source_fund_spouse: this.state.source_fund_spouse,
                asset: this.state.asset,
                investment: this.state.investment,
                rental: this.state.rental,
                other: this.state.other,

                // secondary:this.state.addAnother,
                secondary: this.state.operation_mode == 2 ? true : false,

                secondary_name: this.state.name,
                secondary_dob: this.state.dob,
                secondary_gender: this.state.gender,
                secondary_residency: this.state.residency,
                secondary_fname: this.state.fname,
                secondary_mname: this.state.mname,
                secondary_sname: this.state.sname,
                secondary_occupation: this.state.occupation,
                secondary_id_no: this.state.secondary_id_no,
                secondary_passport_no: this.state.secondary_passport_no,
                secondary_issue_place: this.state.secondary_issue_place,
                secondary_issue_date: this.state.secondary_issue_date,
                secondary_expiry_date: this.state.secondary_expiry_date,
                secondary_country: this.state.country,
                secondary_address: this.state.address,
                secondary_post_code: this.state.post_code,
                secondary_division: this.state.division,
                secondary_district: this.state.district,
                secondary_thana: this.state.thana,
                secondary_mailing_address: this.state.mailing_address,
                secondary_mailing_country: this.state.mailing_country,
                secondary_mailing_post_code: this.state.mailing_post_code,
                secondary_mailing_division: this.state.mailing_division,
                secondary_mailing_district: this.state.mailing_district,
                secondary_mailing_thana: this.state.mailing_thana,
                secondary_mobile: this.state.mobile,
                secondary_email: this.state.email,
                secondary_e_tin: this.state.e_tin,
                secondary_source_fund_salary: this.state.source_fund_salary,
                secondary_source_fund_business: this.state.source_fund_business,
                secondary_source_fund_saving: this.state.source_fund_saving,
                secondary_source_fund_inheritance: this.state.source_fund_inheritance,
                secondary_source_fund_spouse: this.state.source_fund_spouse,
                secondary_asset: this.state.asset,
                secondary_investment: this.state.investment,
                secondary_rental: this.state.rental,
                secondary_other: this.state.other,
            }

            data = JSON.stringify(data)
            var content_type = 'application/json'
            var accept = "'Accept': 'application/json'"
        }
        const url = baseUrl + '/api/applicant-form'
        axios({
            url: url,
            method: "POST",
            headers: {
                accept,
                'Content-Type': content_type,
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            data: data,
        }).then((res) => {
            if (res.data.status == 400) {
                Object.entries(res.data.data).forEach(([key, value]) => {
                    if (this.state.operation_mode == 3) {
                        this.setState({
                            [key + 'Error']: value,
                        })
                    } else {
                        this.setState({
                            [key + 'Error']: value[0],
                        })
                    }

                });
            } else {
                if (this.state.operation_mode == 3) {
                    this.setState({
                        errorStatus: res.data.status,
                        showApplicationForm: !this.state.showApplicationForm,
                        showPaymentInstruction: !this.state.showPaymentInstruction,
                        validated: !this.state.validated,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        errorStatus: res.data.status,
                        showApplicationForm: !this.state.showApplicationForm,
                        showNomineeForm: !this.state.showNomineeForm,
                        validated: !this.state.validated,
                    })
                }
            }
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    authorization: false,
                    errorStatus: err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading: false
            })
        });
        return false
    }
    addAnother = (e) => {
        this.setState({
            addAnother: !this.state.addAnother
        })
    }
    removeAnother = (e) => {
        this.setState({
            addAnother: !this.state.addAnother
        })
    }
    nomineeForm = (e) => {
        e.preventDefault()
        const valid = this.checkValidation(e)
        if (!valid || this.state.n_dob == '') {
            if (this.state.n_dob == '') {
                this.setState({
                    n_dobError: 'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.border = "1px solid red";
                }
                return
            }
            return
        }
        this.setState({
            isLoading: true
        })
        let data = {
            n_name: this.state.n_name,
            n_dob: this.state.n_dob,
            n_gender: this.state.n_gender,
            n_residency: this.state.n_residency,
            n_fname: this.state.n_fname,
            n_mname: this.state.n_mname,
            n_sname: this.state.n_sname,
            n_occupation: this.state.n_occupation,
            n_id_no: this.state.n_id_no,
            n_passport_no: this.state.n_passport_no,
            n_issue_place: this.state.n_issue_place,
            n_issue_date: this.state.n_issue_date,
            n_expiry_date: this.state.n_expiry_date,
            n_country: this.state.n_country,
            n_address: this.state.n_address,
            n_post_code: this.state.n_post_code,
            n_division: this.state.n_division,
            n_district: this.state.n_district,
            n_thana: this.state.n_thana,
            n_mailing_country: this.state.n_mailing_country,
            n_mailing_address: this.state.n_mailing_address,
            n_mailing_post_code: this.state.n_mailing_post_code,
            n_mailing_division: this.state.n_mailing_division,
            n_mailing_district: this.state.n_mailing_district,
            n_mailing_thana: this.state.n_mailing_thana,
            n_mobile: this.state.n_mobile,
            n_email: this.state.n_email,

        }
        const url = baseUrl + '/api/nominee-form'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => {
            if (res.data.status == 400) {
                Object.entries(res.data.data).forEach(([key, value]) => {
                    this.setState({
                        [key + 'Error']: value[0],
                    })
                });
            } else {
                this.setState({
                    errorStatus: res.data.status,
                    showNomineeForm: !this.state.showNomineeForm,
                    showDocumentForm: !this.state.showDocumentForm,
                    validated: !this.state.validated,
                })
            }
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    authorization: false,
                    errorStatus: err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading: false
            })
        });
        return false
    }
    skipNominee = (e) => {
        e.preventDefault()
        this.setState({
            skipNominee: true,
            errorStatus: 200,
            showNomineeForm: !this.state.showNomineeForm,
            showDocumentForm: !this.state.showDocumentForm,
            validated: !this.state.validated,
        })
    }
    documentForm = (e) => {
        e.preventDefault()
        const valid = this.checkValidation(e)
        if (!valid) {
            return
        }

        this.setState({
            isLoading: true
        })

        const url = baseUrl + '/api/document-form'
        const formData = new FormData();
        formData.append('nid_copy', this.state.p_a_nid)
        formData.append('applicant_photo', this.state.p_a_photo)
        formData.append('applicant_sign', this.state.p_a_signature)
        formData.append('tin', this.state.p_a_tin)
        formData.append('joint_applicant_photo', this.state.j_a_photo)
        formData.append('joint_applicant_sign', this.state.j_a_signature)
        formData.append('joint_tin', this.state.j_a_tin)
        formData.append('nominee_nid_applicant_photo', this.state.nominee_nid)
        formData.append('nominee_applicant_photo', this.state.nominee_photo)
        formData.append('nominee_applicant_sign', this.state.nominee_signature)
        formData.append('operation_mode', this.state.operation_mode)

        axios({
            method: "POST",
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
        })
            .then((response) => {
                if (response.data.errors) {
                    Object.entries(response.data.errors).forEach(([key, value]) => {
                        this.setState({
                            [key + 'Error']: value,
                            isLoading: false
                        })
                    });
                } else {
                    this.setState({
                        errorStatus: response.data.status,
                        showDocumentForm: !this.state.showDocumentForm,
                        showPaymentInstruction: !this.state.showPaymentInstruction,
                        validated: !this.state.validated,
                        isLoading: false
                    })
                }
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    this.setState({
                        authorization: false,
                        errorStatus: err.response.status,
                        isLoading: false
                    })
                    Swal.fire({
                        position: 'top-right',
                        text: err.response.statusText,
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                }
            });

        return false
    }
    getBank = (token) => {
        const url = baseUrl + '/api/get-bank'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            }
        }).then((res) => {
            this.setState({
                getBank: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    getBranch = (bank_id, token) => {
        const url = baseUrl + '/api/get-branch'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token)
            },
            params: { bank_id: bank_id },
        }).then((res) => {
            this.setState({
                branch_name: res.data.data[1].ORG_BRANCH_ID,
                routing_no: res.data.data[1].ROUTING_NO,
                getBranch: res.data.data
            })

        }).catch((err) => {
            console.log(err.response.data)
        });
    }
    paymentInstruction = (e) => {
        e.preventDefault()
        const valid = this.checkValidation(e)
        if (!valid) {
            return
        }
        this.setState({
            isLoading: true
        })
        let data = {
            bank_ac_name: this.state.bank_ac_name,
            bank_ac_no: this.state.bank_ac_no,
            bank_name: this.state.bank_name,
            branch_name: this.state.branch_name,
            routing_no: this.state.routing_no
        }
        const url = baseUrl + '/api/payment-instruction'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => {
            if (res.data.status == 400) {
                Object.entries(res.data.data).forEach(([key, value]) => {
                    this.setState({
                        [key + 'Error']: value[0],
                    })
                });
            } else {
                localStorage.setItem('STEP_CHECK', 'FINISH')
                this.setState({
                    errorStatus: res.data.status,
                    showProfile: !this.state.showProfile,
                    validated: !this.state.validated,
                })
            }
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            if (err.response.status == 401) {
                this.setState({
                    authorization: false,
                    errorStatus: err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading: false
            })
        });
        return false
    }
    checkValidation = (event) => {
        let valid = 1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid = 0
        }
        if(this.state.showPaymentInstruction==true && (this.state.bank_name=='' || this.state.branch_name=='')){
            if(this.state.bank_name==''){
                this.setState({
                    bank_nameError:'This field is required'
                })
            }if(this.state.branch_name==''){
                this.setState({
                    branch_nameError:'This field is required'
                })
            }
            valid = 0
        }
        this.setState({
            validated: true
        })
        return valid
    }
    render() {
        const { token = undefined, isLoading } = this.state
        if (isLoading) {
            return (
                <Loading loading={isLoading} />
            )
        }
        return (
            <>
                {
                    token != undefined ? (this.state.errorStatus == 401 ?
                        <Navigate to="/login" replace={false} /> : (this.state.showProfile == true ? <Navigate to="/investor/profile" replace={false} /> : '')) :
                        <Navigate to="/login" replace={false} />
                }
                <Header auth={this.state.authorization} />
                <Container>
                    <Row className="justify-content-center" >
                        <Col className="my-4 applicant-form-list" sm={12} md={12} lg={12} >
                            <ListGroup horizontal>
                                <ListGroup.Item align="center" className={`${this.state.operation_mode != '3' ? 'col-md-3' : 'col-md-6'} fw-bold step ${this.state.showApplicationForm == true ? 'active' : ''}`} variant="primary">
                                    Applicant's Form
                                </ListGroup.Item>
                                {
                                    this.state.operation_mode != '3' ?
                                        <>
                                            <ListGroup.Item align="center" className={`col-md-3 fw-bold step ${this.state.showNomineeForm == true ? 'active' : ''}`}>
                                                Nominee's Form
                                            </ListGroup.Item>
                                            <ListGroup.Item align="center" className={`col-md-3 fw-bold step ${this.state.showDocumentForm == true ? 'active' : ''}`}>
                                                Documents Submission
                                            </ListGroup.Item>
                                        </>
                                        : ''
                                }
                                <ListGroup.Item align="center" className={`${this.state.operation_mode != '3' ? 'col-md-3' : 'col-md-6'} fw-bold step ${this.state.showPaymentInstruction == true ? 'active' : ''}`}>
                                    Bank Details & Payment Instruction
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    {
                        this.state.showApplicationForm == true ?
                            (this.state.operation_mode != '3' ?
                                <Row className="justify-content-center" >
                                    <Col md={12} >
                                        <Form noValidate validated={this.state.validated} onSubmit={(e) => this.applicantForm(e)} >
                                            <div className='tab'>
                                                <h1 align="center" >PRINCIPAL APPLICANT</h1>
                                                <h5>Basic Personal Information</h5>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Full Name(as per ID)</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="name"
                                                                    value={this.state.name}
                                                                    onChange={(e) => this.changeState(e)}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.nameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.nameError == '' ? 'This field is required' : this.state.nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Date Of Birth</Form.Label>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        className="formPlaintextEmail"
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('dob', value) }}
                                                                        value={this.state.dob}
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'dob'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                <Form.Control.Feedback className={this.state.dobError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.dobError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.dobError == '' ? 'This field is required' : this.state.dobError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Gender</Form.Label>
                                                                <Form.Control as="select"
                                                                    name="gender"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    required
                                                                    defaultValue={this.state.gender}
                                                                >
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                    <option value="Others">Others</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.genderError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.genderError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.genderError == '' ? 'This field is required' : this.state.genderError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Residency</Form.Label>
                                                                <Form.Control as="select"
                                                                    name="residency"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    required
                                                                    defaultValue={this.state.residency}
                                                                >
                                                                    <option value="resident">Resident</option>
                                                                    <option value="non-resident">Non-Resident</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.residencyError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.residencyError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.residencyError == '' ? 'This field is required' : this.state.residencyError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Father's Name</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="fname"
                                                                    value={this.state.fname}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.fnameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.fnameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.fnameError == '' ? 'This field is required' : this.state.fnameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Mother's Name</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mname"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.mnameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.mnameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mnameError == '' ? 'This field is required' : this.state.mnameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label>Spouse's Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="sname"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.snameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.snameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.snameError == '' ? 'This field is required' : this.state.snameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Occupation</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    name="occupation"
                                                                    variant="secondary"
                                                                    required
                                                                    value={this.state.occupation}
                                                                >
                                                                    {
                                                                        this.state.getOcupation.map(occupation => {
                                                                            return <option value={occupation.OCCUPATION_ID} key={occupation.OCCUPATION_ID}>{occupation.OCCUPATION}</option>
                                                                        })
                                                                    }

                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.occupationError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.occupationError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.occupationError == '' ? 'This field is required' : this.state.occupationError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <h5>ID Information</h5> */}
                                                {/* <Row>
                                                    <Col md={12}>
                                                        <div style={{width:'45%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>ID Type</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="id_type"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.id_typeError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.id_typeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.id_typeError==''?'This field is required':this.state.id_typeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <Row>
                                                    {
                                                        this.state.id_no != '' ?
                                                            <Col md={6}>
                                                                <div className='input-field' style={{ width: '90%' }}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>NID No</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="id_no"
                                                                            readOnly
                                                                            disabled
                                                                            value={this.state.id_no}
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.id_noError != '' ? 'show' : ''} type="invalid">
                                                                            {
                                                                                this.state.id_noError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.id_noError == '' ? 'This field is required' : this.state.id_noError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col> :
                                                            <>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Passport No</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="passport_no"
                                                                                readOnly
                                                                                disabled
                                                                                value={this.state.passport_no}
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.passport_noError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.passport_noError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.passport_noError == '' ? 'This field is required' : this.state.passport_noError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Issue Date</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="issue_date"
                                                                                readOnly
                                                                                disabled
                                                                                value={this.state.issue_date}
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.issue_dateError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.issue_dateError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.issue_dateError == '' ? 'This field is required' : this.state.issue_dateError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Expiry Date</Form.Label>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    className="formPlaintextEmail"
                                                                                    format={date_format}
                                                                                    onChange={(value) => { this.changeDate('expiry_date', value) }}
                                                                                    value={this.state.expiry_date}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            name: 'expiry_date'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                            <Form.Control.Feedback className={this.state.expiry_dateError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.expiry_dateError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.expiry_dateError == '' ? 'This field is required' : this.state.expiry_dateError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left">Issue Place</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="issue_place"
                                                                                value={this.state.issue_place}
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.issue_placeError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.issue_placeError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.issue_placeError == '' ? 'This field is required' : this.state.issue_placeError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </>


                                                    }
                                                </Row>

                                                <h5>Address & Contact Information</h5>
                                                <h6>Present Address</h6>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Country</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    name="country"
                                                                    variant="secondary"
                                                                    className="validation"
                                                                    required
                                                                    value={this.state.country}
                                                                >
                                                                    {
                                                                        this.state.getNationality.map(nationality => {
                                                                            return <option value={nationality.NATIONALITY_ID} key={nationality.NATIONALITY_ID}>{nationality.NATIONALITY}</option>
                                                                        })
                                                                    }

                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.countryError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.countryError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.countryError == '' ? 'This field is required' : this.state.countryError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Address</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="address"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.addressError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.addressError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.addressError == '' ? 'This field is required' : this.state.addressError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Post Code</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="post_code"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.post_codeError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.post_codeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.post_codeError==''?'This field is required':this.state.post_codeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Division</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="division"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.divisionError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.divisionError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.divisionError==''?'This field is required':this.state.divisionError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>District/City</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="district"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.districtError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.districtError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.districtError==''?'This field is required':this.state.districtError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Upazilla/Thana</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="thana"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.thanaError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.thanaError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.thanaError==''?'This field is required':this.state.thanaError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <Row>
                                                    <Col md={6}>
                                                        <h6>Mailing Address</h6>
                                                    </Col>
                                                    <Col className="ms-auto" md={3}>
                                                        <div>
                                                            <Form.Group className="bo-account-yes " >
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => { this.changeState(e) }} type="checkbox" name="primary_same_present" />
                                                                    <Form.Check.Label>
                                                                        Same as present address
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Country</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    name="mailing_country"
                                                                    variant="secondary"
                                                                    className="validation"
                                                                    required
                                                                    value={this.state.mailing_country}
                                                                >
                                                                    {
                                                                        this.state.getNationality.map(nationality => {
                                                                            return <option value={nationality.NATIONALITY_ID} key={nationality.NATIONALITY_ID}>{nationality.NATIONALITY}</option>
                                                                        })
                                                                    }

                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.mailing_countryError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.mailing_countryError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mailing_countryError == '' ? 'This field is required' : this.state.mailing_countryError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Address</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mailing_address"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    value={this.state.mailing_address}
                                                                />
                                                                <Form.Control.Feedback className={this.state.mailing_addressError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.mailing_addressError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mailing_addressError == '' ? 'This field is required' : this.state.mailing_addressError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Post Code</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mailing_post_code"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.mailing_post_codeError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.mailing_post_codeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mailing_post_codeError==''?'This field is required':this.state.mailing_post_codeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Division</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mailing_division"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.mailing_divisionError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.mailing_divisionError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mailing_divisionError==''?'This field is required':this.state.mailing_divisionError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>District/City</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mailing_district"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.mailing_districtError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.mailing_districtError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mailing_districtError==''?'This field is required':this.state.mailing_districtError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div style={{width:'90%'}}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Upazilla/Thana</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mailing_thana"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.mailing_thanaError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.mailing_thanaError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mailing_thanaError==''?'This field is required':this.state.mailing_thanaError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <h6>Contact Information</h6>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Mobile No.</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mobile"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    value={this.state.mobile}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.mobileError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.mobileError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mobileError == '' ? 'This field is required' : this.state.mobileError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label align="left"><span>*</span>Email</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="email"
                                                                    name="email"
                                                                    onChange={(e) => this.changeState(e)}
                                                                    value={this.state.email}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.emailError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.emailError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.emailError == '' ? 'This field is required' : this.state.emailError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <h6>Other Information</h6>
                                                <Row>
                                                    <Col md={12}>
                                                        <div style={{ width: '45%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label>E-Tin No</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="e_tin"
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.e_tinError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.e_tinError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.e_tinError == '' ? 'This field is required' : this.state.e_tinError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <h6>Source/s Of Fund</h6>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="source_fund_salary" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Salary/Earning from service
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="source_fund_business" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Earning from business
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="source_fund_saving" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Personal Savings
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="source_fund_inheritance" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Inheritance
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="source_fund_spouse" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Earning given by spouse
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="asset" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Sale of asset (e.g. Car)
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="lottery" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Winning lottery/price money
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="investment" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Return on investment/investment matured
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                <Form.Check type="radio" >
                                                                    <Form.Check.Input onChange={(e) => this.changeState(e)} name="rental" value="Y" type="checkbox" />
                                                                    <Form.Check.Label>
                                                                        Rental income
                                                                    </Form.Check.Label>

                                                                </Form.Check>
                                                                {/* <Form.Check.Input onChange={(e) => this.changeState(e)}  disabled type="text"/> */}
                                                                <Form.Label>Other</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="other"
                                                                    placeholder="Describe here."
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    {
                                                        this.state.mode_of_operation==1?
                                                            <Col>
                                                                {
                                                                    this.state.addAnother==false?<a onClick={(e)=>this.addAnother(e)} ><FontAwesomeIcon icon={faPlusCircle} /> Add another</a>:
                                                                    <a onClick={(e)=>this.removeAnother(e)} ><FontAwesomeIcon icon={faPlusCircle} /> Remove</a>
                                                                }
                                                            </Col>:''
                                                    }
                                                    
                                                </Row> */}
                                                {
                                                    this.state.operation_mode == 2 ?
                                                        <div className='tab'>
                                                            <h1 align="center" >JOINT APPLICANT</h1>
                                                            <h5>Basic Personal Information</h5>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Full Name(as per ID)</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="secondary_name"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_nameError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_nameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_nameError == '' ? 'This field is required' : this.state.secondary_nameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Date Of Birth</Form.Label>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    className="formPlaintextEmail"
                                                                                    format={date_format}
                                                                                    onChange={(value) => { this.changeDate('secondary_dob', value) }}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            name: 'secondary_dob'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                            <Form.Control.Feedback className={this.state.secondary_dobError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_dobError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_dobError == '' ? 'This field is required' : this.state.secondary_dobError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Gender</Form.Label>
                                                                            <Form.Control as="select"
                                                                                name="secondary_gender"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                required
                                                                                defaultValue={this.state.gender}
                                                                            >
                                                                                <option value="Male">Male</option>
                                                                                <option value="Female">Female</option>
                                                                                <option value="Others">Others</option>
                                                                            </Form.Control>
                                                                            <Form.Control.Feedback className={this.state.secondary_genderError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_genderError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_genderError == '' ? 'This field is required' : this.state.secondary_genderError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Residency</Form.Label>
                                                                            <Form.Control as="select"
                                                                                name="secondary_residency"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                required
                                                                                defaultValue={this.state.residency}
                                                                            >
                                                                                <option value="resident">Resident</option>
                                                                                <option value="non-resident">Non-Resident</option>
                                                                            </Form.Control>
                                                                            <Form.Control.Feedback className={this.state.secondary_residencyError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_residencyError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_residencyError == '' ? 'This field is required' : this.state.secondary_residencyError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Father's Name</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="secondary_fname"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_fnameError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_fnameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_fnameError == '' ? 'This field is required' : this.state.secondary_fnameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Mother's Name</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="secondary_mname"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_mnameError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_mnameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_mnameError == '' ? 'This field is required' : this.state.secondary_mnameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Spouse's Name</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="secondary_sname"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_snameError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_snameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_snameError == '' ? 'This field is required' : this.state.secondary_snameError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Occupation</Form.Label>
                                                                            <Form.Control
                                                                                as="select"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="secondary_occupation"
                                                                                variant="secondary"
                                                                                className="validation"
                                                                                required
                                                                                value={this.state.secondary_occupation}
                                                                            >
                                                                                {
                                                                                    this.state.getOcupation.map(occupation => {
                                                                                        return <option value={occupation.OCCUPATION_ID} key={occupation.OCCUPATION_ID}>{occupation.OCCUPATION}</option>
                                                                                    })
                                                                                }

                                                                            </Form.Control>
                                                                            <Form.Control.Feedback className={this.state.secondary_occupationError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_occupationError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_occupationError == '' ? 'This field is required' : this.state.secondary_occupationError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>NID No</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="secondary_id_no"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Passport No</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="secondary_passport_no"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left">Passport Issue Place</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="secondary_issue_place"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Passport Issue Date</Form.Label>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    className="formPlaintextEmail"
                                                                                    format={date_format}
                                                                                    onChange={(value) => { this.changeDate('secondary_issue_date', value) }}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            name: 'secondary_issue_date'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left">Passport Expiry Date</Form.Label>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    className="formPlaintextEmail"
                                                                                    format={date_format}
                                                                                    onChange={(value) => { this.changeDate('secondary_expiry_date', value) }}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            name: 'secondary_expiry_date'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <h5>Address & Contact Information</h5>
                                                            <h6>Present Address</h6>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Country</Form.Label>
                                                                            <Form.Control
                                                                                as="select"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="secondary_country"
                                                                                variant="secondary"
                                                                                className="validation"
                                                                                required
                                                                                value={this.state.country}
                                                                            >
                                                                                {
                                                                                    this.state.getNationality.map(nationality => {
                                                                                        return <option value={nationality.NATIONALITY_ID} key={nationality.NATIONALITY_ID}>{nationality.NATIONALITY}</option>
                                                                                    })
                                                                                }

                                                                            </Form.Control>
                                                                            <Form.Control.Feedback className={this.state.secondary_countryError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_countryError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_countryError == '' ? 'This field is required' : this.state.secondary_countryError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Address</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="secondary_address"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_addressError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_addressError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_addressError == '' ? 'This field is required' : this.state.secondary_addressError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>Post Code</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_post_code"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_post_codeError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_post_codeError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_post_codeError==''?'This field is required':this.state.secondary_post_codeError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label align="left"><span>*</span>Division</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_division"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_divisionError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_divisionError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_divisionError==''?'This field is required':this.state.secondary_divisionError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>District/City</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_district"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_districtError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_districtError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_districtError==''?'This field is required':this.state.secondary_districtError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label align="left"><span>*</span>Upazilla/Thana</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_thana"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_thanaError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_thanaError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_thanaError==''?'This field is required':this.state.secondary_thanaError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                            <Row>
                                                                <Col md={6}>
                                                                    <h6>Mailing Address</h6>
                                                                </Col>
                                                                <Col className="ms-auto" md={3}>
                                                                    <div>
                                                                        <Form.Group className="bo-account-yes " >
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => { this.changeState(e) }} type="checkbox" name="secondary_same_present" />
                                                                                <Form.Check.Label>
                                                                                    Same as present address
                                                                                </Form.Check.Label>
                                                                            </Form.Check>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Country</Form.Label>
                                                                            <Form.Control
                                                                                as="select"
                                                                                onChange={(e) => { this.changeState(e) }}
                                                                                name="secondary_mailing_country"
                                                                                variant="secondary"
                                                                                className="validation"
                                                                                required
                                                                                value={this.state.secondary_mailing_country}
                                                                            >
                                                                                {
                                                                                    this.state.getNationality.map(nationality => {
                                                                                        return <option value={nationality.NATIONALITY_ID} key={nationality.NATIONALITY_ID}>{nationality.NATIONALITY}</option>
                                                                                    })
                                                                                }

                                                                            </Form.Control>
                                                                            <Form.Control.Feedback className={this.state.secondary_mailing_countryError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_mailing_countryError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_mailing_countryError == '' ? 'This field is required' : this.state.secondary_mailing_countryError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Address</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="secondary_mailing_address"
                                                                                onChange={(e) => this.changeState(e)}
                                                                                defaultValue={this.state.secondary_mailing_address}

                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_mailing_addressError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_mailing_addressError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_mailing_addressError == '' ? 'This field is required' : this.state.secondary_mailing_addressError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>Post Code</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_mailing_post_code"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_mailing_post_codeError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_post_codeError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_post_codeError==''?'This field is required':this.state.secondary_mailing_post_codeError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label align="left"><span>*</span>Division</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_mailing_division"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_mailing_divisionError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_divisionError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_divisionError==''?'This field is required':this.state.secondary_mailing_divisionError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>District/City</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_mailing_district"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_mailing_districtError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_districtError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_districtError==''?'This field is required':this.state.secondary_mailing_districtError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div style={{width:'90%'}}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label align="left"><span>*</span>Upazilla/Thana</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            name="secondary_mailing_thana"
                                                                            onChange={(e) => this.changeState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.secondary_mailing_thanathanaError!=''?'show':''} type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_thanathanaError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.secondary_mailing_thanathanaError==''?'This field is required':this.state.secondary_mailing_thanathanaError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                            <h6>Contact Information</h6>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label><span>*</span>Mobile No.</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                name="secondary_mobile"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_mobileError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_mobileError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_mobileError == '' ? 'This field is required' : this.state.secondary_mobileError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label align="left"><span>*</span>Email</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="email"
                                                                                name="secondary_email"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_emailError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_emailError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_emailError == '' ? 'This field is required' : this.state.secondary_emailError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <h6>Other Information</h6>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div style={{ width: '45%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>E-Tin No</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="secondary_e_tin"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                            <Form.Control.Feedback className={this.state.secondary_e_tinError != '' ? 'show' : ''} type="invalid">
                                                                                {
                                                                                    this.state.secondary_e_tinError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {
                                                                                    this.state.secondary_e_tinError == '' ? 'This field is required' : this.state.secondary_e_tinError
                                                                                }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <h6>Source/s Of Fund</h6>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_source_fund_salary" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Salary/Earning from service
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_source_fund_business" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Earning from business
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_source_fund_saving" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Personal Savings
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_source_fund_inheritance" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Inheritance
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_source_fund_spouse" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Earning given by spouse
                                                                                </Form.Check.Label>
                                                                            </Form.Check>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_asset" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Sale of asset (e.g. Car)
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_lottery" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Winning lottery/price money
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_investment" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Return on investment/investment matured
                                                                                </Form.Check.Label>

                                                                            </Form.Check>
                                                                            <Form.Check type="radio" >
                                                                                <Form.Check.Input onChange={(e) => this.changeState(e)} name="secondary_rental" value="Y" type="checkbox" />
                                                                                <Form.Check.Label>
                                                                                    Rental income
                                                                                </Form.Check.Label>

                                                                            </Form.Check>

                                                                            {/* <Form.Check.Input onChange={(e) => this.changeState(e)}  disabled type="text"/> */}
                                                                            <Form.Label>Other</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="secondary_other"
                                                                                placeholder="Describe here."
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div> : ''
                                                }

                                                <Row>

                                                    <Col className='mx-auto' md={3}>
                                                        <Button
                                                            className='form-button my-4'
                                                            type="submit"
                                                            id="nextBtn"
                                                        >Save & Continue <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                                :
                                <Row className="justify-content-center" >
                                    <Col md={12} >
                                        <Form noValidate validated={this.state.validated} onSubmit={(e) => this.applicantForm(e)} >
                                            <div className='tab'>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Name of Institution</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="institution_name"
                                                                    value={this.state.institution_name}
                                                                    onChange={(e) => this.changeState(e)}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.institution_nameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.institution_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.institution_nameError == '' ? 'This field is required' : this.state.institution_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Type of Institution</Form.Label>
                                                                <Form.Control as="select"
                                                                    name="type_of_institution"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    required
                                                                    defaultValue={this.state.type_of_institution}
                                                                >
                                                                    <option value="Local Company"> Local Company   </option>
                                                                    <option value="Foreign Company"> Foreign Company </option>
                                                                    <option value="Society">Society</option>
                                                                    <option value="Trust">Trust</option>
                                                                    <option value="Other">Other</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.type_of_institutionError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.type_of_institutionError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.type_of_institutionError == '' ? 'This field is required' : this.state.type_of_institutionError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Institution Short Name</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="inst_short_name"
                                                                    value={this.state.inst_short_name}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_short_nameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_short_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_short_nameError == '' ? 'This field is required' : this.state.inst_short_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Company Registration No</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="registration_no"
                                                                    value={this.state.registration_no}
                                                                    onChange={(e) => this.changeState(e)}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.registration_noError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.registration_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.registration_noError == '' ? 'This field is required' : this.state.registration_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>TIN No</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="inst_tin_no"
                                                                    value={this.state.inst_tin_no}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_tin_noError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_tin_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_tin_noError == '' ? 'This field is required' : this.state.inst_tin_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>CEO/MD Name</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="inst_ceo_name"
                                                                    value={this.state.inst_ceo_name}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_ceo_nameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_ceo_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_ceo_nameError == '' ? 'This field is required' : this.state.inst_ceo_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Mode of Operation</Form.Label>
                                                                <Form.Control as="select"
                                                                    name="mode_of_operation"
                                                                    onChange={(e) => { this.changeState(e) }}
                                                                    required
                                                                    defaultValue={this.state.mode_of_operation}
                                                                >
                                                                    <option value="Jointly"> Jointly </option>
                                                                    <option value="Singly"> Singly </option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback className={this.state.mode_of_operationError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.mode_of_operationError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mode_of_operationError == '' ? 'This field is required' : this.state.mode_of_operationError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span> BO Account No</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="inst_bo_account_no"
                                                                    value={this.state.inst_bo_account_no}
                                                                    onChange={(e) => this.changeState(e)}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_bo_account_noError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_bo_account_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_bo_account_noError == '' ? 'This field is required' : this.state.inst_bo_account_noError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span> BO Account Name</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="inst_bo_account_name"
                                                                    value={this.state.inst_bo_account_name}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_bo_account_nameError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_bo_account_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_bo_account_nameError == '' ? 'This field is required' : this.state.inst_bo_account_nameError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label>Telephone Office</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="inst_telephone_office"
                                                                    value={this.state.inst_telephone_office}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_telephone_officeError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_telephone_officeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_telephone_officeError == '' ? 'This field is required' : this.state.inst_telephone_officeError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="email"
                                                                    value={this.state.email}
                                                                    onChange={(e) => this.changeState(e)}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.emailError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.emailError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.emailError == '' ? 'This field is required' : this.state.emailError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span> Mobile</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="mobile"
                                                                    value={this.state.mobile}
                                                                    onChange={(e) => this.changeState(e)}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                <Form.Control.Feedback className={this.state.mobileError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.mobileError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.mobileError == '' ? 'This field is required' : this.state.mobileError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label>Fax</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="inst_fax"
                                                                    value={this.state.inst_fax}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_faxError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_faxError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_faxError == '' ? 'This field is required' : this.state.inst_faxError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span> Mailing Address</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="inst_mailing_address"
                                                                    value={this.state.inst_mailing_address}
                                                                    onChange={(e) => this.changeState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_mailing_addressError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_mailing_addressError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_mailing_addressError == '' ? 'This field is required' : this.state.inst_mailing_addressError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <h6>Details of Authorized Person (s), if any</h6>
                                                <Row>


                                                    {
                                                        this.state.auth_persons.map((value, index) => {
                                                            return <Row key={index}>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Name</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="auth_per_name"
                                                                                defaultValue={this.state.auth_per_name[index]}
                                                                                onChange={(e) => this.changeAuthorizedState(e, index)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Designation</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="auth_per_desg"
                                                                                defaultValue={this.state.auth_per_desg[index]}
                                                                                onChange={(e) => this.changeAuthorizedState(e, index)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>NID</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="auth_per_nid"
                                                                                defaultValue={this.state.auth_per_nid[index]}
                                                                                onChange={(e) => this.changeAuthorizedState(e, index)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Photo</Form.Label>
                                                                            <Form.Control
                                                                                type="file"
                                                                                placeholder="BO Account No"
                                                                                className="custom-file-input validation"
                                                                                name="auth_per_photo"
                                                                                onChange={(e) => this.changeAuthorizedFileState(e, index)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className='input-field' style={{ width: '90%' }}>
                                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                                            <Form.Label>Signature</Form.Label>
                                                                            <Form.Control
                                                                                type="file"
                                                                                placeholder="BO Account No"
                                                                                className="custom-file-input validation"
                                                                                name="auth_per_sign"
                                                                                onChange={(e) => this.changeAuthorizedFileState(e, index)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        })
                                                    }

                                                    <Row>
                                                        <Col className='ms-auto' sm={3} md={3}>
                                                            <Button
                                                                className='form-button my-4'
                                                                type="submit"
                                                                id="nextBtn"
                                                                onClick={(e) => this.handleAddField(e)}
                                                            >Add More <FontAwesomeIcon icon={faPlusCircle} /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Col md={6}>
                                                        <div className='input-field' style={{ width: '90%' }}>
                                                            <Form.Group className="mb-3 bo-account-yes " >
                                                                <Form.Label><span>*</span>Photograph of CEO/MD</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="file"
                                                                    placeholder="BO Account No"
                                                                    className="custom-file-input validation"
                                                                    name="inst_ceo_photo"
                                                                    onChange={(e) => this.changeFileState(e)}
                                                                />
                                                                <Form.Control.Feedback className={this.state.inst_ceo_photoError != '' ? 'show' : ''} type="invalid">
                                                                    {
                                                                        this.state.inst_ceo_photoError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.inst_ceo_photoError == '' ? 'This field is required' : this.state.inst_ceo_photoError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='mx-auto' md={3}>
                                                        <Button
                                                            className='form-button my-4'
                                                            type="submit"
                                                            id="nextBtn"
                                                        >Save & Continue <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>

                            )
                            : ''

                    }
                    {
                        this.state.showNomineeForm == true ?
                            <Row className="justify-content-center" >
                                <Col md={12} >
                                    <Form noValidate validated={this.state.validated} onSubmit={(e) => this.nomineeForm(e)}>
                                        <div className='tab'>
                                            <h1 align="center" >NOMINEE APPLICATION</h1>
                                            <p className='text-danger' align="center" >This form optional. You can skip this by clicking on the skip button.</p>
                                            <p align="center" ><a onClick={(e) => this.skipNominee(e)} className='btn btn-dark px-5  py-2' href=''>SKIP</a></p>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Full Name(as per ID)</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_name"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_nameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_nameError == '' ? 'This field is required' : this.state.n_nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Date Of Birth</Form.Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    className="formPlaintextEmail"
                                                                    format={date_format}
                                                                    onChange={(value) => { this.changeDate('n_dob', value) }}
                                                                    slotProps={{
                                                                        textField: {
                                                                            name: 'n_dob'
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                            <Form.Control.Feedback className={this.state.n_dobError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_dobError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_dobError == '' ? 'This field is required' : this.state.n_dobError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Gender</Form.Label>
                                                            <Form.Control as="select"
                                                                name="n_gender"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                required
                                                                defaultValue={this.state.gender}
                                                            >
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Others">Others</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.n_genderError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_genderError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_genderError == '' ? 'This field is required' : this.state.n_genderError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Residency</Form.Label>
                                                            <Form.Control as="select"
                                                                name="n_residency"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                required
                                                                defaultValue={this.state.residency}
                                                            >
                                                                <option value="resident">Resident</option>
                                                                <option value="non-resident">Non-Resident</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.n_residencyError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_residencyError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_residencyError == '' ? 'This field is required' : this.state.n_residencyError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Father's Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_fname"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_fnameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_fnameError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_fnameError == '' ? 'This field is required' : this.state.n_fnameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Mother's Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mname"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mnameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_mnameError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mnameError == '' ? 'This field is required' : this.state.n_mnameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Spouse's Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="n_sname"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_snameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_snameError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_snameError == '' ? 'This field is required' : this.state.n_snameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Occupation</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                name="n_occupation"
                                                                variant="secondary"
                                                                className="validation"
                                                                required
                                                                value={this.state.n_occupation}
                                                            >
                                                                {
                                                                    this.state.getOcupation.map(occupation => {
                                                                        return <option value={occupation.OCCUPATION_ID} key={occupation.OCCUPATION_ID}>{occupation.OCCUPATION}</option>
                                                                    })
                                                                }

                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.n_occupationError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_occupationError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_occupationError == '' ? 'This field is required' : this.state.n_occupationError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h5>ID Information</h5>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>NID No</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="n_id_no"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Passport No</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="n_passport_no"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">Passport Issue Place</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="n_issue_place"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Passport Issue Date</Form.Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    className="formPlaintextEmail"
                                                                    format={date_format}
                                                                    onChange={(value) => { this.changeDate('n_issue_date', value) }}
                                                                    slotProps={{
                                                                        textField: {
                                                                            name: 'n_issue_date'
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left">Passport Expiry Date</Form.Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    className="formPlaintextEmail"
                                                                    format={date_format}
                                                                    onChange={(value) => { this.changeDate('n_expiry_date', value) }}
                                                                    slotProps={{
                                                                        textField: {
                                                                            name: 'n_expiry_date'
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h5>Address & Contact Information</h5>
                                            <h6>Present Address</h6>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Country</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                name="n_country"
                                                                variant="secondary"
                                                                className="validation"
                                                                required
                                                                value={this.state.n_country}
                                                            >
                                                                {
                                                                    this.state.getNationality.map(nationality => {
                                                                        return <option value={nationality.NATIONALITY_ID} key={nationality.NATIONALITY_ID}>{nationality.NATIONALITY}</option>
                                                                    })
                                                                }

                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.n_countryError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_countryError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_countryError == '' ? 'This field is required' : this.state.n_countryError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Address</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_address"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_addressError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_addressError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_addressError == '' ? 'This field is required' : this.state.n_addressError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Post Code</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_post_code"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_post_codeError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_post_codeError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_post_codeError==''?'This field is required':this.state.n_post_codeError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Division</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_division"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_divisionError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_divisionError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_divisionError==''?'This field is required':this.state.n_divisionError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>District/City</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_district"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_districtError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_districtError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_districtError==''?'This field is required':this.state.n_districtError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Upazilla/Thana</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_thana"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_thanaError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_thanaError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_thanaError==''?'This field is required':this.state.n_thanaError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col md={6}>
                                                    <h6>Mailing Address</h6>
                                                </Col>
                                                <Col className="ms-auto" md={2}>
                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                        <Form.Check type="radio" >
                                                            <Form.Check.Input onChange={(e) => { this.changeState(e) }} type="checkbox" name="n_paymentType" />
                                                            <Form.Check.Label>
                                                                Same as present address
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Country</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                name="n_mailing_country"
                                                                variant="secondary"
                                                                className="validation"
                                                                required
                                                                defaultValue={this.state.n_mailing_country}
                                                            >
                                                                {
                                                                    this.state.getNationality.map(nationality => {
                                                                        return <option value={nationality.NATIONALITY_ID} key={nationality.NATIONALITY_ID}>{nationality.NATIONALITY}</option>
                                                                    })
                                                                }

                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.n_mailing_countryError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_mailing_countryError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mailing_countryError == '' ? 'This field is required' : this.state.n_mailing_countryError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Address</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mailing_address"
                                                                defaultValue={this.state.n_mailing_address}
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mailing_addressError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_mailing_addressError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mailing_addressError == '' ? 'This field is required' : this.state.n_mailing_addressError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Post Code</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mailing_post_code"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mailing_post_codeError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_mailing_post_codeError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mailing_post_codeError==''?'This field is required':this.state.n_mailing_post_codeError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Division</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mailing_division"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mailing_divisionError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_mailing_divisionError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mailing_divisionError==''?'This field is required':this.state.n_mailing_divisionError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>District/City</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mailing_district"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mailing_districtError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_mailing_districtError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mailing_districtError==''?'This field is required':this.state.n_mailing_districtError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Upazilla/Thana</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mailing_thana"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mailing_thanaError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.n_mailing_thanaError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mailing_thanaError==''?'This field is required':this.state.n_mailing_thanaError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <h6>Contact Information</h6>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Mobile No.</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="n_mobile"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_mobileError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_mobileError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_mobileError == '' ? 'This field is required' : this.state.n_mobileError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Email</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="email"
                                                                name="n_email"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.n_emailError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_emailError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_emailError == '' ? 'This field is required' : this.state.n_emailError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h6>Other Information</h6>
                                            <Row>
                                                <Col md={12}>
                                                    <div style={{ width: '45%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Relationship With Principal Applicant</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                name="n_relationship"
                                                                variant="secondary"
                                                                className="validation"
                                                                required
                                                                value={this.state.n_relationship}
                                                            >
                                                                {
                                                                    this.state.getRelation.map(relation => {
                                                                        return <option value={relation.RELATION_ID} key={relation.RELATION_ID}>{relation.RELATION}</option>
                                                                    })
                                                                }

                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.n_relationshipError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.n_relationshipError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.n_relationshipError == '' ? 'This field is required' : this.state.n_relationshipError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='mx-auto' md={3}>
                                                    <Button
                                                        className='form-button my-4'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Save & Continue <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Col>
                            </Row> : ''
                    }
                    {
                        this.state.showDocumentForm == true ?
                            <Row className="justify-content-center" >
                                <Col md={12} >
                                    <Form noValidate validated={this.state.validated} onSubmit={(e) => this.documentForm(e)}>
                                        <div className='tab'>
                                            <h1 align="center" >DOCUMENT SUBMISSION</h1>
                                            <h5>Documents Related to Applicant/s</h5>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Principal Applicant's ID (NID/Smart Card/Passport/Birth Certificate)</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="p_a_nid"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.p_a_nidError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.p_a_nidError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.p_a_nidError == '' ? 'This field is required' : this.state.p_a_nidError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Principal Applicant's Photo</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="p_a_photo"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.p_a_photoError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.p_a_photoError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.p_a_photoError == '' ? 'This field is required' : this.state.p_a_photoError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Photo/scanned copy of Primary Applicant's signature</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="p_a_signature"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.p_a_signatureError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.p_a_signatureError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.p_a_signatureError == '' ? 'This field is required' : this.state.p_a_signatureError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Primary Applicant's E-Tin</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="p_a_tin"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.p_a_tinError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.p_a_tinError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.p_a_tinError == '' ? 'This field is required' : this.state.p_a_tinError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {
                                                this.state.operation_mode == 2 ?
                                                    <>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className='input-field' style={{ width: '90%' }}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>Joint Applicant's ID (NID/Smart Card/Passport/Birth Certificate)</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="file"
                                                                            placeholder="BO Account No"
                                                                            className="custom-file-input validation"
                                                                            name="j_a_nid"
                                                                            onChange={(e) => this.changeFileState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.j_a_nidError != '' ? 'show' : ''} type="invalid">
                                                                            {
                                                                                this.state.j_a_nidError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.j_a_nidError == '' ? 'This field is required' : this.state.j_a_nidError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className='input-field' style={{ width: '90%' }}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>Joint Applicant's Photo</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="file"
                                                                            placeholder="BO Account No"
                                                                            className="custom-file-input validation"
                                                                            name="j_a_photo"
                                                                            onChange={(e) => this.changeFileState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.j_a_photoError != '' ? 'show' : ''} type="invalid">
                                                                            {
                                                                                this.state.j_a_photoError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.j_a_photoError == '' ? 'This field is required' : this.state.j_a_photoError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className='input-field' style={{ width: '90%' }}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label><span>*</span>Photo/scanned copy of Joint Applicant's signature</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="file"
                                                                            placeholder="BO Account No"
                                                                            className="custom-file-input validation"
                                                                            name="j_a_signature"
                                                                            onChange={(e) => this.changeFileState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.j_a_signatureError != '' ? 'show' : ''} type="invalid">
                                                                            {
                                                                                this.state.j_a_signatureError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.j_a_signatureError == '' ? 'This field is required' : this.state.j_a_signatureError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className='input-field' style={{ width: '90%' }}>
                                                                    <Form.Group className="mb-3 bo-account-yes " >
                                                                        <Form.Label>Joint Applicant's E-Tin</Form.Label>
                                                                        <Form.Control
                                                                            type="file"
                                                                            placeholder="BO Account No"
                                                                            className="custom-file-input validation"
                                                                            name="j_a_tin"
                                                                            onChange={(e) => this.changeFileState(e)}
                                                                        />
                                                                        <Form.Control.Feedback className={this.state.j_a_tinError != '' ? 'show' : ''} type="invalid">
                                                                            {
                                                                                this.state.j_a_tinError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {
                                                                                this.state.j_a_tinError == '' ? 'This field is required' : this.state.j_a_tinError
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    : ''
                                            }
                                            <Row>
                                                <Col md={6}>
                                                    <h5>Documents Related to Nominee</h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Nominee's ID (NID/Smart Card/Passport/Birth Certificate)</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="nominee_nid"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.nominee_nidError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.nominee_nidError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.nominee_nidError == '' ? 'This field is required' : this.state.nominee_nidError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Nominee's Photo</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="nominee_photo"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.nominee_photoError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.nominee_photoError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.nominee_photoError == '' ? 'This field is required' : this.state.nominee_photoError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label>Nominee's E-signature</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                placeholder="BO Account No"
                                                                className="custom-file-input validation"
                                                                name="nominee_signature"
                                                                onChange={(e) => this.changeFileState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.nominee_signatureError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.nominee_signatureError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.nominee_signatureError == '' ? 'This field is required' : this.state.nominee_signatureError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='mx-auto' md={3}>
                                                    <Button
                                                        className='form-button my-4'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Save & Continue <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Col>
                            </Row> : ''
                    }
                    {
                        this.state.showPaymentInstruction == true ?
                            <Row className="justify-content-center" >
                                <Col md={12} >
                                    <Form noValidate validated={this.state.validated} onSubmit={(e) => this.paymentInstruction(e)}>
                                        <div className='tab'>
                                            <h1 align="center" >BANK DETAILS & PAYMENT INSTRUCTION</h1>
                                            <h5>Bank Details</h5>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Bank A/C Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"

                                                                name="bank_ac_name"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.bank_ac_nameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.bank_ac_nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.bank_ac_nameError == '' ? 'This field is required' : this.state.bank_ac_nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Bank A/C No</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="bank_ac_no"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.bank_ac_noError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.bank_ac_noError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.bank_ac_noError == '' ? 'This field is required' : this.state.bank_ac_noError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Bank Name</Form.Label>
                                                            {/* <Form.Control
                                                                as="select"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                name="bank_name"
                                                                variant="secondary"
                                                                className="validation"
                                                                required
                                                                value={this.state.bank_name}
                                                            >
                                                                {
                                                                    this.state.getBank.map(bank => {
                                                                        return <option value={bank.ORG_ID} key={bank.ORG_ID}>{bank.ORG_NAME}</option>
                                                                    })
                                                                }

                                                            </Form.Control> */}
                                                            <Select 
                                                                closeMenuOnSelect={true}
                                                                required
                                                                defaultValue={this.state.bank_name}
                                                                onChange={(e) => { this.changeState2(e) }}
                                                                options=
                                                                    {
                                                                        this.state.getBank.map(bank => 
                                                                            (
                                                                                { 
                                                                                    label: bank.ORG_NAME,
                                                                                    value: bank.ORG_ID,
                                                                                    name:'bank_name'
                                                                                }
                                                                            ))
                                                                    }
                                                                    placeholder='Select Bank Name'
                                                            />
                                                            <Form.Control.Feedback className={this.state.bank_nameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.bank_nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label align="left"><span>*</span>Branch Name</Form.Label>
                                                            {/* <Form.Control
                                                                as="select"
                                                                onChange={(e) => { this.changeState(e) }}
                                                                name="branch_name"
                                                                variant="secondary"
                                                                className="validation"
                                                                required
                                                                value={this.state.branch_name}
                                                            >
                                                                {
                                                                    this.state.getBranch.map(branch => {
                                                                        return <option value={branch.ORG_BRANCH_ID} data-custom-attr={branch.ROUTING_NO} key={branch.ORG_BRANCH_ID}>{branch.BRANCH_NAME}</option>
                                                                    })
                                                                }

                                                            </Form.Control> */}
                                                            <Select 
                                                                closeMenuOnSelect={true}
                                                                defaultValue={this.state.branch_name}
                                                                onChange={(e) => { this.changeState2(e) }}
                                                                options=
                                                                    {
                                                                        this.state.getBranch.map(branch => 
                                                                            (
                                                                                { 
                                                                                    label: branch.BRANCH_NAME,
                                                                                    value: branch.ORG_BRANCH_ID,
                                                                                    name:'branch_name'
                                                                                }
                                                                            ))
                                                                    }
                                                                placeholder='Select Branch Name'
                                                            />
                                                            <Form.Control.Feedback className={this.state.branch_nameError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.branch_nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='input-field' style={{ width: '90%' }}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Routing No</Form.Label>
                                                            <Form.Control
                                                                required
                                                                readOnly
                                                                disabled
                                                                type="text"
                                                                name="routing_no"
                                                                onChange={(e) => this.changeState(e)}
                                                                value={this.state.routing_no}
                                                            />
                                                            <Form.Control.Feedback className={this.state.routing_noError != '' ? 'show' : ''} type="invalid">
                                                                {
                                                                    this.state.routing_noError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.routing_noError == '' ? 'This field is required' : this.state.routing_noError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col>
                                                    <h5>Install Payment Instruction</h5>
                                                </Col>
                                            </Row> */}
                                            {/* <Row>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Select Your Installment Payment Method</Form.Label>
                                                            <Form.Control as="select" name="installment_method" onChange={(e) => this.changeState(e)} className="validation" required type="select">
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Quarterly">Quarterly</option>
                                                                <option value="Yearly">Yearly</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.installment_methodError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.installment_methodError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.installment_methodError==''?'This field is required':this.state.installment_methodError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    Provide Installment Amount, Tenure of Payment and From Date. Installment amount will be auto debited from 
                                                                    the your account (according to the above details).
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <p>Auto Debit Instruction Details</p>
                                            </Row>
                                            <Row>
                                                <Col md={3}>
                                                    <div style={{width:'80%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Installment Payment Date</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="payment_date"
                                                                disabled 
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div style={{width:'80%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Tenure Of Payment</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="tenure_payment"
                                                                disabled 
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3}>
                                                    <div style={{width:'80%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>From Date</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="from_date"
                                                                disabled  
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div style={{width:'80%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>To Date</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="to_date"
                                                                disabled 
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            {/* <h5>Dividend Payment Instruction</h5> */}
                                            {/* <Row>
                                                <Col md={6}>
                                                    <div style={{width:'90%'}}>
                                                        <Form.Group className="mb-3 bo-account-yes " >
                                                            <Form.Label><span>*</span>Select your preferred method for dividend payment:</Form.Label>
                                                            <Form.Control as="select" name="dividend_payment" onChange={(e) => this.changeState(e)} className="validation" required type="select">
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Quarterly">Quarterly</option>
                                                                <option value="Yearly">Yearly</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback className={this.state.dividend_paymentError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.dividend_paymentError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.dividend_paymentError==''?'This field is required':this.state.dividend_paymentError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className='mx-auto' md={3}>
                                                    <Button
                                                        className='form-button my-4'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Save</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Col>
                            </Row> : ''
                    }
                </Container>
                <Footer />
            </>
        );
    }
}