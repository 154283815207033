import React, { Component } from 'react';
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import { Navigate } from "react-router-dom";
import Loading from '../loading'
import Select from 'react-select'
const baseUrl=global.config.data.path.basePath

export default class ProfileChange extends Component {
    state={
        validated: false,
        authorization:false,
        token: '',
        errorStatus:'',

        portfolios:[],
        getBank:[],
        getBranch:[],

        fund_name:'',
        investment_type:'',
        mailing_address:'',
        bank_name:'',
        branch_name:'',
        account_no:'',
        dividend_option:'cash',
        cip_percentage:'',
        cash_percentage:'',
        bo_account_no:'',
        others:'',

        fund_nameError:'',
        investment_typeError:'',
        mailing_addressError:'',
        bank_nameError:'',
        branch_nameError:'',
        account_noError:'',
        dividend_optionError:'',
        cash_percentageError:'',
        cip_percentageError:'',
        bo_account_noError:'',
        othersError:'',
        isLoading: true

    }
    changeState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if(e.target.name=='bank_name'){
            this.getBranch(e.target.value,this.state.token)
        }else if(e.target.name=='cip_percentage' || e.target.name=='cash_percentage'){
            if(e.target.name=='cip_percentage'){
                this.setState({
                    [e.target.name]: e.target.value,
                    cash_percentage:100-parseInt(e.target.value)
                })
            }else{
                this.setState({
                    [e.target.name]: e.target.value,
                    cip_percentage:100-parseInt(e.target.value)
                })
            }
        }
    }
    changeState2 = (e) => {
        this.setState({
            [e.name]:e.value,
            [e.name+'Error']:''
        })
        if (e.name == 'bank_name') {
            this.getBranch(e.value, this.state.token)
        }
    }
    componentDidMount() {
        document.title = 'Profile Change';
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.profile(token);
        this.getPortfolio(token)
        this.getBank(token)
    }
    profile=(token)=>{
        const url=baseUrl+'/api/investor/profile'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    errorStatus:200,
                    fund_name:res.data.investorBankInfo.PORTFOLIO_ID,
                    investment_type:res.data.investorBankInfo.INVESTMENT_TYPE_ID,
                    mailing_address:res.data.investorBankInfo.MAILING_ADDRESS,
                    bank_name:res.data.investorBankInfo.ORG_ID,
                    branch_name:res.data.investorBankInfo.ORG_BRANCH_ID,
                    account_no:res.data.investorBankInfo.BANK_ACCOUNT_NO,
                    bo_account_no:res.data.investorBankInfo.BO_ACCOUNT_NO,
                    isLoading: false
                })
            }
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    isLoading: false
                })
            }
         });
        
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                portfolios:res.data.data
            })
            
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getBank=(token)=>{
        const url = baseUrl+'/api/get-bank'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.getBranch(res.data.data[0].ORG_ID,token)
            this.setState({
                bank_name:res.data.data[0].ORG_ID,
                getBank:res.data.data
            })
            
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getBranch=(bank_id,token)=>{
        const url = baseUrl+'/api/get-branch'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params: {bank_id:bank_id},
        }).then((res) => {
            this.setState({
                branch_name:res.data.data[0].ORG_BRANCH_ID,
                getBranch:res.data.data
            })
            
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    profileChange=(e)=>{
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        this.setState({
            isLoading:true
        })
        e.preventDefault()
        if(this.state.dividend_option=='BOTH'){
            if(parseInt(this.state.cip_percentage)+parseInt(this.state.cash_percentage)!=100){
                this.setState({
                    cip_percentageError:`Cash and Cip percentage should be equal to 100`,
                    cash_percentageError:`Cash and Cip percentage should be equal to 100`
                })
                return
            }else{
                this.setState({
                    cip_percentageError:'',
                    cash_percentageError:''
                })
            }
        }
        
        let data = {
            fund_name:this.state.fund_name,
            investment_type:this.state.investment_type,
            mailing_address:this.state.mailing_address,
            bank_name:this.state.bank_name,
            branch_name:this.state.branch_name,
            account_no:this.state.account_no,
            dividend_option:this.state.dividend_option,
            cip_percentage:this.state.cip_percentage,
            cash_percentage:this.state.cash_percentage,
            bo_account_no:this.state.bo_account_no,
            others:this.state.others
        }
        const url = baseUrl+'/api/change-profile'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==400){
                Object.entries(res.data.data).forEach(([key, value]) => {
                    this.setState({
                        [key+'Error']: value[0],
                    })
                });
            }else{
                this.setState({
                    errorStatus:res.data.status
                })
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    render() {
        const {token= undefined,isLoading} = this.state;
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className='justify-content-center'>
                            <Col md={8}>
                                <p align="start" className="fw-bold">Investor Details</p>
                                <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.profileChange(e)}>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                        Investment Vehicle<span>*</span>
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control  as="select" 
                                                variant="secondary" 
                                                name="fund_name"
                                                onChange={(e)=>this.changeState(e)}
                                                required
                                                defaultValue={this.state.fund_name}
                                                >
                                                    <option value="" disabled>Select an option</option>
                                                {
                                                     this.state.portfolios.map(portfolio=>{
                                                        return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback className={this.state.fund_nameError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.fund_nameError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.fund_nameError==''?'This field is required':this.state.fund_nameError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            Investment Type<span>*</span>
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control  as="select" 
                                                variant="secondary" 
                                                name="investment_type"
                                                onChange={(e)=>this.changeState(e)}
                                                required
                                                defaultValue={this.state.investment_type}
                                                >
                                                <option value="" disabled>Select an option</option>
                                                <option value="1">Single Payment Investment (Non-SIP)</option>
                                                <option value="2">Systemative Investment Payment (SIP)</option>
                                            </Form.Control>
                                            <Form.Control.Feedback className={this.state.investment_typeError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.investment_typeError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.investment_typeError==''?'This field is required':this.state.investment_typeError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            Mailing Address
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control 
                                                type="text"
                                                name="mailing_address"
                                                onChange={(e)=>this.changeState(e)}
                                                defaultValue={this.state.mailing_address}
                                            />
                                            <Form.Control.Feedback className={this.state.mailing_addressError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.mailing_addressError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.mailing_addressError==''?'This field is required':this.state.mailing_addressError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                        Bank Name
                                        </Form.Label>
                                        <Col sm="7">
                                            {/* <Form.Control  as="select" 
                                                variant="secondary"
                                                name="bank_name"
                                                onChange={(e)=>this.changeState(e)}
                                                defaultValue={this.state.bank_name}
                                                >
                                                {
                                                    this.state.getBank.map(bank=>{
                                                        return <option value={bank.ORG_ID} key={bank.ORG_ID}>{bank.ORG_NAME}</option>  
                                                    })
                                                }
                                            </Form.Control> */}
                                            <Select 
                                                closeMenuOnSelect={true}
                                                defaultValue={this.state.bank_name}
                                                onChange={(e) => { this.changeState2(e) }}
                                                options=
                                                    {
                                                        this.state.getBank.map(bank => 
                                                            (
                                                                { 
                                                                    label: bank.ORG_NAME,
                                                                    value: bank.ORG_ID,
                                                                    name:'bank_name'
                                                                }
                                                            ))
                                                    }
                                                    placeholder='Select Bank Name'
                                            />
                                            <Form.Control.Feedback className={this.state.bank_nameError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.bank_nameError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            Branch Name
                                        </Form.Label>
                                        <Col sm="7">
                                            {/* <Form.Control  as="select" 
                                                variant="secondary" 
                                                name="branch_name"
                                                onChange={(e)=>this.changeState(e)}
                                                defaultValue={this.state.branch_name}
                                                >
                                                {
                                                    this.state.getBranch.map(branch=>{
                                                        return <option value={branch.ORG_BRANCH_ID} key={branch.ORG_BRANCH_ID}>{branch.BRANCH_NAME}</option>  
                                                    })
                                                }
                                            </Form.Control> */}
                                            <Select 
                                                closeMenuOnSelect={true}
                                                defaultValue={this.state.branch_name}
                                                onChange={(e) => { this.changeState2(e) }}
                                                options=
                                                    {
                                                        this.state.getBranch.map(branch => 
                                                            (
                                                                { 
                                                                    label: branch.BRANCH_NAME,
                                                                    value: branch.ORG_BRANCH_ID,
                                                                    name:'branch_name'
                                                                }
                                                            ))
                                                    }
                                                placeholder='Select Branch Name'
                                            />
                                            <Form.Control.Feedback className={this.state.branch_nameError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.branch_nameError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            Account No.
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control type="text"
                                                name="account_no"
                                                onChange={(e)=>this.changeState(e)}
                                                defaultValue={this.state.account_no}
                                             />
                                             <Form.Control.Feedback className={this.state.account_noError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.account_noError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.account_noError==''?'This field is required':this.state.account_noError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            Dividend Option
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control  as="select" 
                                                variant="secondary" 
                                                name="dividend_option"
                                                onChange={(e)=>this.changeState(e)}
                                                defaultValue={this.state.dividend_option}
                                                >
                                                <option value="CASH">CASH</option>
                                                <option value="CIP">CIP</option>
                                                <option value="BOTH">BOTH</option>
                                            </Form.Control>
                                            <Form.Control.Feedback className={this.state.dividend_optionError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.dividend_optionError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.dividend_optionError==''?'This field is required':this.state.dividend_optionError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    {
                                        this.state.dividend_option=='BOTH'?
                                        <>
                                            <Form.Group as={Row} className="mb-3 justify-content-center" controlId="validationCustom01">
                                                <Form.Label column sm="2"><span>*</span>CIP Percentage</Form.Label>
                                                <Col sm="7">
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        placeholder="CIP Percentage"
                                                        name="cip_percentage"
                                                        onChange={(e)=>{this.changeState(e) }}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={this.state.cip_percentage}
                                                    />
                                                    <Form.Control.Feedback className={this.state.cip_percentageError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.cip_percentageError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.cip_percentageError==''?'This field is required':this.state.cip_percentageError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3 justify-content-center" controlId="validationCustom01">
                                                <Form.Label column sm="2"><span>*</span>CASH Percentage</Form.Label>
                                                <Col sm="7">
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        placeholder="CASH Percentage"
                                                        name="cash_percentage"
                                                        onChange={(e)=>{this.changeState(e) }}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={this.state.cash_percentage}
                                                    />
                                                    <Form.Control.Feedback className={this.state.cash_percentageError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.cash_percentageError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.cash_percentageError==''?'This field is required':this.state.cash_percentageError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                        </>:''
                                    }
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            BO Account No.
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control type="text"
                                                name="bo_account_no"
                                                onChange={(e)=>this.changeState(e)}
                                                defaultValue={this.state.bo_account_no}
                                             />
                                             <Form.Control.Feedback className={this.state.bo_account_noError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.bo_account_noError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.bo_account_noError==''?'This field is required':this.state.bo_account_noError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" controlId="formPlaintextPassword">
                                        <Form.Label column sm="2">
                                            Others
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control as="textarea" rows={3} 
                                                onChange={(e)=>this.changeState(e)}
                                                name="others"
                                                 />
                                            <Form.Control.Feedback className={this.state.othersError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.othersError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.othersError==''?'This field is required':this.state.othersError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Row className='justify-content-end' >
                                        <Col align="end" md={3}>
                                            <Button 
                                                    className='form-button my-4'
                                                    type="submit"
                                                    id="nextBtn"
                                                    
                                                >Update <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}