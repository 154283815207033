import React, { Component } from 'react';
import { Link,Navigate } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button, ListGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import '../constants/env';
import axios from 'axios';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import Loading from '../loading'
axios.defaults.withCredentials = true;
const baseUrl=global.config.data.path.basePath
const date_format=global.config.data.date.date_format
const date_format_y_m_d=global.config.data.date.date_format_y_m_d
export default class Register extends Component {
    state = {
        validated: false,
        authorization:false,
        showRegister:true,
        showOtp:false,
        showPasswordForm:false,
        showComplete:false,
        errorStatus:'',
        errorMessage:'',
        //register
        accountType: 'individual',
        accountTypeCheck:1,
        name: '',
        mobile_no: '',
        email: '',
        nid: '',
        passport_no: '',
        passport_date: '',
        // passport_expiry_date:'',
        // passport_issue_place:'',
        institution_name: '',
        in_mobile_no: '',
        in_email: '',
        registration_no: '',
        incorporation_date: '',
        nameError: '',
        mobile_noError: '',
        emailError: '',
        nidError: '',
        institution_nameError: '',
        in_mobile_noError: '',
        in_emailError: '',
        registration_noError: '',
        incorporation_dateError: '',

        //otp
        otptoken:'',
        otpone: '',
        otptwo: '',
        otpthree: '',
        otpfour: '',
        otpError:'',

        //complete
        client_id:'',

        password:'',
        confirmPassword:'',
        confirmPasswordError:'',
        passwordError:'',

        id_type:'',
        isLoading:false,

    }
    componentDidMount(){
        document.title = 'Register';
    }
    changeState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if(e.target.name=='id_type'){
            if(e.target.value==1){
                this.setState({
                    passport_no: '',
                    passport_date:''
                })
            }else{
                this.setState({
                    nid: ''
                })
            }
        }
    }
     changeDate=(name,value)=>{
        this.setState({
            [name]:dayjs(value).format(date_format_y_m_d),
            [name+'Error']:''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    accountType = (e) => {
        if (e.target.textContent.trim() == 'Individual') {
            this.setState({
                accountType: 'individual',
            })
        } else if (e.target.textContent.trim() == 'Institution') {
            this.setState({
                accountType: 'institution'
            })
        }
    }
    accountTypeCheck = (e, value) => {
        this.setState({
            accountTypeCheck: value
        })
    }
    register = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const url = baseUrl+'/api/register'
        if (this.state.accountType == 'individual') {
            let data = {
                account_type: this.state.accountType,
                accountTypeCheck: this.state.accountTypeCheck,
                name: this.state.name,
                mobile_no: this.state.mobile_no,
                email: this.state.email,
                nid: this.state.nid,
                passport_no: this.state.passport_no,
                passport_date: this.state.passport_date,
                password:this.state.password,
                confirmPassword:this.state.confirmPassword
            }
            axios({
                url: url,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            }).then((res) => { 
                if(res.data.status==200){
                    this.setState({
                        showComplete:!this.state.showComplete,
                        showPasswordForm:!this.state.showPasswordForm,
                        client_id:res.data.data
                    }) 
                }
                this.setState({
                    isLoading: false
                })
            }).catch((err) => {
                this.setState({
                    isLoading: false
                })
            });
        } else {
            let data = {
                account_type: this.state.accountType,
                accountTypeCheck: 3,
                institution_name: this.state.institution_name,
                mobile_no: this.state.in_mobile_no,
                email: this.state.in_email,
                registration_no: this.state.registration_no,
                incorporation_date: this.state.incorporation_date,
                password:this.state.password,
                confirmPassword:this.state.confirmPassword
            }
            axios({
                url: url,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            }).then((res) => {
                if(res.data.status==200){
                    this.setState({
                        showComplete:!this.state.showComplete,
                        showPasswordForm:!this.state.showPasswordForm,
                        name: this.state.institution_name,
                        mobile_no: this.state.in_mobile_no,
                        email: this.state.in_email,
                        nid: 'N/A',
                        client_id:res.data.data,
                    }) 
                }
                this.setState({
                    isLoading: false
                })
                
            }).catch((err) => {
                this.setState({
                    isLoading: false
                })
            });
        }
    }
    otpCheck = (e) => {
        if (e.target.value.length >= 2) {
            this.setState({
                [e.target.name]: '',
            })
        } else {
            if(e.target.name=='otpone'){
                document.querySelector('input[name="otptwo"]')?.focus()
            }else if(e.target.name=='otptwo'){
                document.querySelector('input[name="otpthree"]')?.focus()
            }else if(e.target.name=='otpthree'){
                document.querySelector('input[name="otpfour"]')?.focus()
            }
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    sendOtp=(e)=>{
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        if (this.state.accountType == 'individual') {
            if(this.state.nid=='' && this.state.passport_date==''){
                if(this.state.passport_date==''){
                    this.setState({
                        passport_dateError:'This field is required'
                    })
                    var elements = document.getElementsByClassName("MuiInputBase-root");
                    for (var i = 0; i < elements.length; i++) {
                        elements[i].style.border = "1px solid red";
                    }
                    this.setState({
                        isLoading: false
                    })
                    return
                }
            }
            var data = {
                mobile_no: this.state.mobile_no,
                email: this.state.email,
                nid: this.state.nid,
                passport_no: this.state.passport_no,
                accountTypeCheck: this.state.accountTypeCheck
            }
        }else{
            if(this.state.incorporation_date==''){
                if(this.state.incorporation_date==''){
                    this.setState({
                        incorporation_dateError:'This field is required'
                    })
                    var elements = document.getElementsByClassName("MuiInputBase-root");
                    for (var i = 0; i < elements.length; i++) {
                        elements[i].style.border = "1px solid red";
                    }
                    this.setState({
                        isLoading: false
                    })
                    return
                }
            }
            var data = {
                mobile_no: this.state.in_mobile_no,
                email: this.state.in_email,
                accountTypeCheck: this.state.accountTypeCheck
            }
        }
        const url = baseUrl+'/api/send-otp'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': "*",
                // 'Access-Control-Allow-Credentials': 'true',
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    otptoken:res.data.data,
                    showRegister:!this.state.showRegister,
                    showOtp:!this.state.showOtp,
                    validated:!this.state.validated
                }) 
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }else{
                this.setState({
                    errorStatus:res.data.status,
                    errorMessage:res.data.message
                }) 
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading: false
            })
            
            
        }).catch((err) => {
            this.setState({
                isLoading: false
            })
        });
    }
    verifyOtp = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        let data={
            otptoken:this.state.otptoken,
            otp:String(this.state.otpone)+String(this.state.otptwo)+String(this.state.otpthree)+String(this.state.otpfour),
            otp_type:'REGISTER'
        }
        const url = baseUrl+'/api/verifyotp'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    showOtp:!this.state.showOtp,
                    showPasswordForm:!this.state.showPasswordForm,
                    validated:!this.state.validated
                })
            }else{
                this.setState({
                    errorStatus:400,
                    otpError:res.data.message
                }) 
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            this.setState({
                isLoading: false
            })
        })
    }
    resendOtp=(e)=>{
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        if (this.state.accountType == 'individual') {
            var data = {
                mobile_no: this.state.mobile_no,
                email: this.state.email,
                nid: this.state.nid,
                passport_no: this.state.passport_no,
            }
        }else{
            var data = {
                mobile_no: this.state.in_mobile_no,
                email: this.state.in_email,
                accountTypeCheck: this.state.accountTypeCheck
            }
        }
        const url = baseUrl+'/api/send-otp'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    otptoken:res.data.data,
                })
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }else{
                this.setState({
                    errorStatus:res.data.status,
                    errorMessage:res.data.message
                }) 
            }
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            this.setState({
                isLoading: false
            })
        });
    }
    setPassword=(e)=>{
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        if(this.state.password!=this.state.confirmPassword){
            this.setState({
                confirmPasswordError:'Confirm password not matched'
            })
            this.setState({
                isLoading: false
            })
            return
        }
        this.register(e)
    }
    procedToInvest=(e)=>{
        e.preventDefault();
        this.setState({
            errorStatus:200
        })
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
            this.setState({
                isLoading: false
            })
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
    }
    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/get-registration-details'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{client_id:this.state.client_id}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Reg-${this.state.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    render() {
        const {isLoading} = this.state
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    this.state.errorStatus==200?<Navigate to="/login" replace={false} />:''
                } 
                <Header />
                <Container fluid={true}>
                    <Row className="justify-content-center" >
                        {
                            this.state.showRegister==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.sendOtp(e)}>
                                    <Card>
                                        <Card.Body>
                                            <h1 className='mb-4' align="center" >SIGN UP</h1>
                                            <ListGroup horizontal className='my-4'>
                                                <Col align="center">
                                                    <ListGroup.Item variant={this.state.accountType == 'individual' ? 'primary' : ''} onClick={(e) => { this.accountType(e) }} className="border-0 pt-3 fw-bold" >
                                                        <FontAwesomeIcon icon={faUser} /> Individual
                                                    </ListGroup.Item>
                                                </Col>
                                                <Col align="center">
                                                    <ListGroup.Item variant={this.state.accountType == 'institution' ? 'primary' : ''} onClick={(e) => { this.accountType(e) }} className="border-0 pt-3 fw-bold" >
                                                        <FontAwesomeIcon icon={faBuildingColumns} /> Institution
                                                    </ListGroup.Item>
                                                </Col>
                                            </ListGroup>
                                            {
                                                this.state.accountType == 'individual' ?
                                                    <div>
                                                        <Card.Text align="center">
                                                            <span>*</span>Please Select Individual Account Type
                                                        </Card.Text>
                                                        <Form.Group align="center" className="mb-3">
                                                            <Form.Check
                                                                defaultChecked
                                                                inline
                                                                name="accountTypeCheck"
                                                                type="radio"
                                                                label="Individual Account                                                "
                                                                className="fw-bold"
                                                                onClick={(e) => { this.accountTypeCheck(e, '1') }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                name="accountTypeCheck"
                                                                type="radio"
                                                                label="Joint Account"
                                                                className="fw-bold"
                                                                onClick={(e) => { this.accountTypeCheck(e, '2') }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>*</span>Principal Investor's Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="name"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback className={this.state.nameError!=''?'show':''} type="invalid">
                                                                {
                                                                    this.state.nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    this.state.nameError==''?'This field is required':this.state.nameError
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>{this.state.email==''?'*':''}</span>Principal Investor's Mobile No.</Form.Label>
                                                            <Form.Control
                                                                required={this.state.email==''?true:false}
                                                                type="number"
                                                                placeholder="Principal Investor's Mobile No."
                                                                className="validation"
                                                                name="mobile_no"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>{this.state.mobile_no==''?'*':''}</span>Principal Investor's Email</Form.Label>
                                                            <Form.Control
                                                                required={this.state.mobile_no==''?true:false}
                                                                type="email"
                                                                placeholder="Principal Investor's Email"
                                                                className="validation"
                                                                name="email"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="validationCustom01">
                                                            <Form.Label><span>*</span>ID Type</Form.Label>
                                                            <Form.Control as="select" 
                                                                name="id_type" 
                                                                onChange={(e) => { this.changeState(e) }} 
                                                                variant="secondary" 
                                                                required 
                                                                defaultValue={this.state.id_type}
                                                                >
                                                                <option value="" disabled >Select one</option>
                                                                <option value="1">NID</option>
                                                                <option value="2">Passport</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        {
                                                            this.state.id_type==1?
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Principal Investor's NID/Smart ID</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        type="number"
                                                                        placeholder="Principal Investor's NID/Smart ID"
                                                                        name="nid"
                                                                        onChange={(e) => this.changeState(e)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is required
                                                                    </Form.Control.Feedback>   
                                                                </Form.Group>:
                                                            this.state.id_type==2?
                                                                <>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Principal Investor's Passport No</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            placeholder="Principal Investor's Passport No"
                                                                            name="passport_no"
                                                                            onChange={(e) => this.changeState(e)}
                                                                            
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            This field is required
                                                                        </Form.Control.Feedback> 
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Principal Investor's Passport Issue Date</Form.Label>
                                                                        <Col md={3}>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    className="formPlaintextEmail"
                                                                                    format={date_format}
                                                                                    onChange={(value) => { this.changeDate('passport_date',value) }} 
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            name: 'passport_date'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Col>
                                                                    </Form.Group>
                                                                    {/* <Form.Group className="mb-3">
                                                                        <Form.Label>Principal Investor's Passport Expiry Date</Form.Label>
                                                                        <Col md={3}>
                                                                            <Form.Control
                                                                                required
                                                                                type="date"
                                                                                className="validation pick-date px-3 py-2"
                                                                                name="passport_expiry_date"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Principal Investor's Passport Issue Place</Form.Label>
                                                                        <Col>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                className="validation pick-date px-3 py-2"
                                                                                name="passport_issue_place"
                                                                                onChange={(e) => this.changeState(e)}
                                                                            />
                                                                        </Col>
                                                                    </Form.Group> */}
                                                                </>:''

                                                        }
                                                    </div> :
                                                    <div>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>*</span>Institution Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Institution Name"
                                                                className="validation "
                                                                name="institution_name"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>*</span>Mobile No.</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="number"
                                                                placeholder="Mobile No."
                                                                className="validation"
                                                                name="in_mobile_no"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>*</span>Email</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="email"
                                                                placeholder="Email"
                                                                className="validation"
                                                                name="in_email"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>*</span>Registration/Trade License No.</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Registration/Trade License No."
                                                                className="validation"
                                                                name="registration_no"
                                                                onChange={(e) => this.changeState(e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><span>*</span> Date Of Incorporation</Form.Label>
                                                            <Col md={3}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        className="formPlaintextEmail"
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('incorporation_date',value) }} 
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'incorporation_date'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Col>
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                            }
                                            <div align="center">
                                                <Button
                                                    className='form-button'
                                                    type="submit"
                                                    id="nextBtn"
                                                >Register <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                <Card.Text className='danger mt-3' align="center">
                                                    Already have an account! <Link to="/login">Sign In Now</Link>
                                                </Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showOtp==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.verifyOtp(e)}>
                                    <Card className='common-margin'>
                                        <Card.Body className='my-3'>
                                            <Card.Title>OTP CONFIRMATION</Card.Title>
                                            <Card.Text>
                                                Provide The 4 Digit OTP Sent To Your Mobile Number/Email
                                            </Card.Text>
                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Row>
                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otpone"
                                                            value={this.state.otpone}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>

                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otptwo"
                                                            value={this.state.otptwo}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>
                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otpthree"
                                                            value={this.state.otpthree}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>
                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otpfour"
                                                            value={this.state.otpfour}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Form.Control.Feedback className={this.state.otpError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.otpError
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Card.Text className='danger' align="right">
                                                <a style={{cursor:"pointer"}} onClick={(e)=>this.resendOtp(e)}>RESEND OTP</a>
                                            </Card.Text>
                                            <Button
                                                className='form-button'
                                                type="submit"
                                                id="nextBtn"
                                            >Verify <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showPasswordForm==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.setPassword(e)}>
                                    <Card className='common-margin'>
                                        <Card.Body className='my-3'>
                                            <Card.Title>Create Password</Card.Title>
                                            <Form.Group as={Row} className="mb-3 justify-content-center" >
                                                <Form.Label column sm="4">
                                                    Password<span>*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required onChange={(e)=>{this.changeState(e)}} name="password" type="password" />
                                                    <Form.Control.Feedback className={this.state.passwordError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.passwordError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.passwordError==''?'This field is required':''
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3 justify-content-center" >
                                                <Form.Label column sm="4">
                                                    Confirm Password<span>*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required onChange={(e)=>{this.changeState(e)}} name="confirmPassword" type="password" />
                                                    <Form.Control.Feedback className={this.state.confirmPasswordError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.confirmPasswordError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.confirmPasswordError==''?'This field is required':''
                                                        }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Row className='justify-content-end' >
                                                <Col align="center" md={4} className='me-3'>
                                                    <Button
                                                        className='form-button my-4'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                </Col>

                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showComplete==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.procedToInvest(e)}>
                                    <Card>
                                        <Card.Body>
                                            <div id="print" >
                                                <p className="faCircleCheck mb-3" align="center" ><FontAwesomeIcon icon={faCircleCheck} /></p>
                                                <h1 className='mb-4' align="center" >Your Registration Is Complete.</h1>
                                                <ListGroup className='my-4 client-details'>
                                                    <Table className='text-center'>
                                                        <tbody>
                                                            <tr>
                                                                <td width="50%" >Name</td>
                                                                <td width="50%">{this.state.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" >
                                                                    {this.state.accountType!='institution'?(this.state.nid!=''?'NID No':'Passport No'):'Registration No'}
                                                                </td>
                                                                <td width="50%">
                                                                    {this.state.accountType!='institution'?(this.state.nid!=''?this.state.nid:this.state.passport_no):this.state.registration_no}
                                                                </td>
                                                            </tr>
                                                            {
                                                               this.state.mobile_no!=''?
                                                                <tr>
                                                                    <td width="50%" >Mobile No.</td>
                                                                    <td width="50%">{this.state.mobile_no}</td>
                                                                </tr>:'' 
                                                            }
                                                            {
                                                                this.state.email!=''?
                                                                <tr>
                                                                    <td width="50%" >Email</td>
                                                                    <td width="50%">{this.state.email}</td>
                                                                </tr>:''
                                                            }
                                                            <tr>
                                                                <td width="50%" >Investor ID</td>
                                                                <td width="50%">{this.state.client_id}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} width="100%">Investor ID has been sent to your email address.</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} onClick={(e)=>this.getPdf(e)} style={{cursor:"pointer"}} className="border-0 pb-3 vanis" variant="light">
                                                                    <FontAwesomeIcon icon={faCloudArrowDown} /> Download & Save
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </ListGroup>
                                                <Form.Group className="mb-3 bo-account-no my-5" controlId="validationCustom01">
                                                    <Card.Text className="fw-light" align="left">
                                                        <span>Note:</span> You will need the Investor ID to Login. Please download the above document to save the investor ID.
                                                    </Card.Text>
                                                </Form.Group>
                                            </div>
                                            <Button
                                                className='form-button vanis'
                                                type="submit"
                                                id="nextBtn"
                                            >Proceed to invest  <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                            :''
                        }
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}