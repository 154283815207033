import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import { Navigate } from "react-router-dom";
import Loading from '../loading'
const baseUrl=global.config.data.path.basePath

export default class Surrender extends Component {
    state={
        validated: false,
        authorization:false,
        showSurrender:true,
        showPurchaseAckTrf:false,
        showOtp:false,
        token: '',
        otptoken:'',
        errorStatus:'',

        portfolios:[],
        user:[],
        userPortfolio:[],
        fund:'',
        fund_name:'',
        investmentType: 2,
        account_list:[],
        reg_no:'',
        account_id:'',
        surrenderInfo:[],
        price:'',
        unitHeld:'',
        availableSurrenderUnitNumber:'',
        availableSurrenderUnit:'',
        exitLoadUnits:'',
        exitLoadUnitsNumber:'',
        withoutExitLoadUnits:'',
        exitLoadSalePrice:'',
        regular_surrender_amount:'',
        total_surrender_value:'',
        exit_load_surrender_amount:'',
        no_of_units_to_surrender:'',

        fundError:'',
        reg_noError:'',
        no_of_units_to_surrenderError:'',

        investor_unit_app_id:'',

        otptoken:'',
        otpone: '',
        otptwo: '',
        otpthree: '',
        otpfour: '',
        otpError:'',
        isLoading: true
    }
    componentDidMount() {
        document.title = 'Surrender'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
        this.getUserPortfolio(token)
    }
    changeState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if(e.target.name=='fund'){
            const selectedOption = e.target.options[e.target.selectedIndex];
            this.setState({
                fund_name:selectedOption.textContent 
            })
            this.getAccountList(e.target.value,this.state.token)
        }else if(e.target.name=='reg_no'){
            const investmentType    =e.target.selectedOptions[0].getAttribute('data-tag')
            const account_id        =e.target.selectedOptions[0].getAttribute('data-account_id')
            this.setState({
                investmentType:investmentType,
                account_id:account_id,
            })
            this.getSurrenderInformation(e.target.value,account_id,this.state.token)
        }else if(e.target.name=='no_of_units_to_surrender'){
            let sell_price=this.state.surrenderInfo.INVESTOR_SALE_PRICE==undefined?0:this.state.surrenderInfo.INVESTOR_SALE_PRICE
            this.setState({
                regular_surrender_amount:e.target.value*sell_price,
                total_surrender_value:e.target.value*sell_price,
            })
        }
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                isLoading: false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
            }

            this.setState({
                isLoading: false
            })
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                portfolios:res.data.data
            })
            
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getUserPortfolio=(token)=>{
        const url = baseUrl+'/api/get-user-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                userPortfolio:res.data.data[0]
            })
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    reg_no:res.data.data[0].INVESTOR_REG_NO
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getSurrenderInformation=(registration,account_id,token)=>{
        const url = baseUrl+'/api/investor-surrender'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{registration:registration,fund:this.state.fund,account_id:account_id}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    surrenderInfo:res.data.data
                })
            }
           
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    otpCheck = (e) => {
        if (e.target.value.length >= 2) {
            this.setState({
                [e.target.name]: '',
            })
        } else {
            if(e.target.name=='otpone'){
                document.querySelector('input[name="otptwo"]')?.focus()
            }else if(e.target.name=='otptwo'){
                document.querySelector('input[name="otpthree"]')?.focus()
            }else if(e.target.name=='otpthree'){
                document.querySelector('input[name="otpfour"]')?.focus()
            }
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    sendOTP=(e)=>{
        e.preventDefault();
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        const url = baseUrl+'/api/send-otp'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{otp_type:'SURRENDER'}
        }).then((res) => { 
            this.setState({
                otptoken:res.data.data,
                showOtp:!this.state.showOtp,
                showSurrender:!this.state.showSurrender,
                validated:!this.state.validated
            })
            Swal.fire({
                position: 'top-right',
                text: res.data.message,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        }).catch((err) => {
            
        });
    }
    resendOTP=(e)=>{
        e.preventDefault();
        const url = baseUrl+'/api/send-otp'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{otp_type:'SURRENDER'}
        }).then((res) => { 
            this.setState({
                otptoken:res.data.data
            })
            Swal.fire({
                position: 'top-right',
                text: res.data.message,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            })
        }).catch((err) => {
            console.log(err)
        });
    }
    verifyOtp = (e) => {
        e.preventDefault();
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        let data={
            otptoken:this.state.otptoken,
            otp:String(this.state.otpone)+String(this.state.otptwo)+String(this.state.otpthree)+String(this.state.otpfour),
            otp_type:'SURRENDER'
        }
        const url = baseUrl+'/api/verifyotp'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.surrender(e)
            }else{
                this.setState({
                    errorStatus:400,
                    otpError:res.data.message
                }) 
            }
            
            
        }).catch((err) => {
            
        });
    }
    surrender=(e)=>{
        e.preventDefault()
        let data = {
            reg_no:this.state.reg_no,
            account_id:this.state.account_id,
            no_of_units_to_surrender: this.state.no_of_units_to_surrender,
            fund: this.state.fund,
            investorPrice: this.state.surrenderInfo.INVESTOR_SALE_PRICE,
            investmentType:this.state.investmentType
        }
        const url = baseUrl+'/api/investor-surrender'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==400){
                Object.entries(res.data.data).forEach(([key, value]) => {
                    this.setState({
                        [key+'Error']: value[0],
                    })
                });
            }else if(res.data.status!=200){
                this.setState({
                    showOtp:!this.state.showOtp,
                    showSurrender:!this.state.showSurrender,
                    validated:true
                })
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'error',
                    timer: 4000,
                    showConfirmButton: false,
                })
            }
            else{
                this.setState({
                    errorStatus:res.data.status,
                    showOtp:!this.state.showOtp,
                    showPurchaseAckTrf:!this.state.showPurchaseAckTrf,
                    investor_unit_app_id:res.data.data.investor_unit_app_id
                })
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
            }
            this.setState({
                isLoading:false
            })
         });
    }
    CDBL_form=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/cdblsur-transfer-form'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{investor_unit_app_id:this.state.investor_unit_app_id}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `CDBL-${this.state.user.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
         }); 
    }
    ACK_form=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/surrender-download'
        this.setState({
            loading:true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund_name:this.state.fund_name,client_id:this.state.user.client_id,no_of_units_to_surrender:this.state.no_of_units_to_surrender,
                unit_price:this.state.surrenderInfo.INVESTOR_SALE_PRICE,
                total_unit_held: (this.state.surrenderInfo.CURRENT_NO_OF_UNITS-this.state.surrenderInfo.LAST_MONTH_PEN_PURCHASE)}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Ack-${this.state.user.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
         }); 
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    render() {
        const step_check=localStorage.getItem('STEP_CHECK')
        const {
            token= undefined,
            surrenderInfo,
            regular_surrender_amount,
            isLoading
        } = this.state
        let price=surrenderInfo.length!=0?surrenderInfo.INVESTOR_SALE_PRICE:''
        let unitHeld=surrenderInfo.length!=0?(surrenderInfo.CURRENT_NO_OF_UNITS-surrenderInfo.LAST_MONTH_PEN_PURCHASE):''
        let availableSurrenderUnitNumber=surrenderInfo.length!=0?(surrenderInfo.FREE_UNITS-surrenderInfo.LAST_MONTH_PEN_PURCHASE):''
        let availableSurrenderUnit=surrenderInfo.length!=0?(surrenderInfo.FREE_UNITS-surrenderInfo.LAST_MONTH_PEN_PURCHASE):''
        let exitLoadUnits=surrenderInfo.length!=0?surrenderInfo.TOTAL_WITH_CHARGE:''
        let exitLoadUnitsNumber=surrenderInfo.length!=0?surrenderInfo.TOTAL_WITH_CHARGE:''
        let withoutExitLoadUnits=surrenderInfo.length!=0?surrenderInfo.TOTAL_WITHOUT_CHARGE:''
        let exitLoadSalePrice=surrenderInfo.length!=0?surrenderInfo.INVESTOR_SALE_PRICE:''
        let regular_surrender=regular_surrender_amount
        let all_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
        let available_days=['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container className='content-area' fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center">
                        {
                            this.state.showSurrender==true?
                            <>
                                <Col md={6}>
                                    <p align="start" className="fw-bold">Surrender Request Form</p>
                                    <p align="start" className="fw-light">
                                        Units will be available for surrender from Sunday to Wednesday.
                                    </p>
                                    <p align="start" className="fw-light">
                                        To complete the surrender process, you need to request your broker to transfer units of
                                        the mutual fund from your BO account to the Fund's BO account.
                                    </p>
                                    <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.sendOTP(e)}>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <Form.Label column sm="3">
                                                Investor's Name<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control disabled type="text" defaultValue={this.state.user.name==null?this.state.user.institution_name:this.state.user.name} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Investor ID<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control disabled type="text" defaultValue={this.state.user.client_id} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Investment Vehicle<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    as="select" 
                                                    onChange={(e) => { this.changeState(e) }} 
                                                    name="fund" 
                                                    variant="secondary" 
                                                    
                                                    required
                                                    defaultValue={this.state.fund}
                                                    >
                                                        <option value='' disabled>Select Investment Vehicle</option>
                                                    {
                                                        this.state.portfolios.map(portfolio=>{
                                                            return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                        })
                                                    }

                                                </Form.Control>
                                                <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.fundError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.fundError==''?'This field is required':this.state.fundError
                                                    }
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Investment Type<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control as="select" 
                                                    name="reg_no" 
                                                    onChange={(e) => { this.changeState(e) }} 
                                                    variant="secondary" 
                                                    defaultValue={this.state.reg_no}
                                                    required 
                                                    >
                                                        <option value='' disabled>Select Investment Type</option>
                                                    {
                                                        this.state.account_list.map(accountlist=>{
                                                            return <option 
                                                                        data-tag={accountlist.INVESTMENT_TYPE_ID} 
                                                                        data-account_id={accountlist.INVESTOR_ACCOUNT_ID}
                                                                        value={accountlist.INVESTOR_REG_NO} 
                                                                        key={accountlist.INVESTOR_REG_NO}>
                                                                    {accountlist.INVESTMENT_TYPE_ID==1?accountlist.INVESTOR_REG_NO:"NON-SIP"}
                                                                    </option>  
                                                        })
                                                    }
                                                </Form.Control>
                                                <Form.Control.Feedback className={this.state.reg_noError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.reg_noError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.reg_noError==''?'This field is required':this.state.reg_noError
                                                    }
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                No. of Total Holding Units<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    className='auto-fill-bg'
                                                    required
                                                    onKeyDown={(e)=>e.preventDefault()} 
                                                    type="text" 
                                                    defaultValue={unitHeld} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                No. of Saleable Units<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    className='auto-fill-bg'
                                                    required
                                                    onKeyDown={(e)=>e.preventDefault()} 
                                                    type="text" 
                                                    defaultValue={availableSurrenderUnit} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                No. of Units to Surrender<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    required 
                                                    type="number" 
                                                    onChange={(e)=>this.changeState(e)} 
                                                    onWheel={(e) => e.target.blur()}
                                                    name="no_of_units_to_surrender"  />
                                                <Form.Control.Feedback className={this.state.no_of_units_to_surrenderError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.no_of_units_to_surrenderError
                                                    }
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.no_of_units_to_surrenderError==''?'This field is required':this.state.no_of_units_to_surrenderError
                                                    }
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Investor's Surrender Price / Unit<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    className='auto-fill-bg'
                                                    required
                                                    onKeyDown={(e)=>e.preventDefault()} 
                                                    type="text" 
                                                    defaultValue={price}  
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Total Surrender Value (৳)<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    className='auto-fill-bg'
                                                    required
                                                    onKeyDown={(e)=>e.preventDefault()}  
                                                    type="text" 
                                                    name="regular_surrender_amount" 
                                                    defaultValue={regular_surrender}  
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Exit Load<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    className='auto-fill-bg'
                                                    required
                                                    onKeyDown={(e)=>e.preventDefault()}  
                                                    type="text" 
                                                    name="exit_load_surrender_amount" 
                                                    defaultValue={exitLoadUnitsNumber}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="3">
                                                Amount Payable (৳)<span>*</span>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control 
                                                    className='auto-fill-bg'
                                                    required
                                                    onKeyDown={(e)=>e.preventDefault()}  
                                                    type="text" 
                                                    name="total_surrender_value" 
                                                    defaultValue={regular_surrender} 
                                                />
                                            </Col>
                                        </Form.Group>
                                        {
                                            available_days.includes(all_days) &&(
                                                <Row className='justify-content-end' >
                                                    <Col align="start" md={4}>
                                                        <Button
                                                            className='form-button my-4'
                                                            type="submit"
                                                            id="nextBtn"

                                                        >Submit <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        
                                    </Form>
                                </Col>
                                <Col md={4}>
                                    <p align="start" className="fw-bold">Fund's BO Account Info.
                                    </p>
                                    <ListGroup>
                                        <ListGroup.Item variant="danger">
                                            <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>BO Account Name</span>
                                            <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>GB - AIMS FIRST UF - RE - PURCHASE</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>BO Account ID</span>
                                            <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>16046200 75901873</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="danger">
                                            <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>Name of DP	</span>
                                            <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>Brac Bank Limited.</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item >
                                            <span className="col-md-6 fw-bold text-secondary" style={{ display: "inline-block" }}>DP ID</span>
                                            <span className="col-md-6 text-secondary" style={{ display: "inline-block" }}>46200</span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </>:''
                        }
                        {
                            this.state.showOtp==true?
                            <Col className="my-4" md={6} >
                                <Form className="my-4" noValidate validated={this.state.validated} onSubmit={(e)=>this.verifyOtp(e)}>
                                    <Card>
                                        <Card.Body className='my-3'>
                                            <Card.Title>OTP CONFIRMATION</Card.Title>
                                            <Card.Text>
                                                Provide The 4 Digit OTP Sent To Your Mobile Number/Email
                                            </Card.Text>
                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Row>
                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otpone"
                                                            value={this.state.otpone}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>

                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otptwo"
                                                            value={this.state.otptwo}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>
                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otpthree"
                                                            value={this.state.otpthree}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>
                                                    <Col className='me-2' md={1}>
                                                        <Form.Control
                                                            required
                                                            minLength="1"
                                                            maxLength="1"
                                                            type="number"
                                                            
                                                            name="otpfour"
                                                            value={this.state.otpfour}
                                                            onChange={(e) => { this.otpCheck(e) }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Form.Control.Feedback className={this.state.otpError!=''?'show':''} type="invalid">
                                                    {
                                                        this.state.otpError
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Card.Text className='danger' align="right">
                                                <a onClick={(e)=>this.resendOTP(e)}>RESEND OTP</a>
                                            </Card.Text>
                                            <Button
                                                className='form-button'
                                                type="submit"
                                                id="nextBtn"
                                            >Verify <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>:''
                        }
                        {
                            this.state.showPurchaseAckTrf==true?
                            <>
                            <Col className=' text-end' md={12}>
                                    <a className='btn btn-danger' onClick={(e)=>{this.ACK_form(e)}} >Acknowledgement Download</a>
                                    <a className='btn btn-danger ms-2' onClick={(e)=>{this.CDBL_form(e)}} >CDBL Form Download</a>
                            </Col>
                            <Col md={12}>
                                <Card className='mt-4'>
                                    <Card.Body className='my-3'>
                                    <p>Dear Valued Investor, thank you for submitting your surrender request and necessary information. Your surrender request is under process now. Once your payment is cleared, we will notify you and allocate the units to your BO accounts within 7 working days. 
                                        Please Download or Print Acknowledgement Slip and keep it for your reference.</p>
                                    </Card.Body>
                                </Card>
                            </Col></>:''
                        }
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}