import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

export default class Loading extends Component {
    render() {
        const loading=this.props.loading
        return (
            <>
                {
                    loading==true?
                    <div style={{position:"absolute",left:"50%",top:"50%",color:"rgb(22 101 52/1)"}}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            role="status"
                            aria-hidden="true"
                            />
                        <span>Loading...</span>
                    </div>:''
                }
            </>
            
        );
    }
}



