import React, { Component } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, Navigate } from "react-router-dom";
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import Loading from '../loading'
export default class TermsCond extends Component {
    state = {
        validated: false,
        authorization: false,
        token: '',
        errorStatus: '',
        isLoading: true
    }
    componentDidMount() {
        document.title = 'Terms & Conditions';
        const token = localStorage.getItem('token')
        if (token) {
            this.setState({
                token: token,
                authorization: true,
                isLoading: false
            })
        }
    }
    render() {
        const { token = undefined, isLoading } = this.state;
        if (isLoading) {
            return (
                <Loading loading={isLoading} />
            )
        }
        return (
            <>
                {
                    this.state.errorStatus == 401 ? <Navigate to="/login" replace={false} /> : ''
                }
                <Header auth={this.state.authorization} />
                <Container className="my-4">
                    <Row>
                        <Col md={12}>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    1. The units of “Micro-Savers Special Purpose Growth Scheme” of <strong>Grameen Bank-AIMS First Unit Fund</strong> may be bought through AIMS of Bangladesh Limited and authorized selling agents appointed by AIMS from time to time.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    2. Application must be made by an individual (both resident and non-resident), a corporation or company (both local and foreign), a trust, foundation or a society (registered in or outside of Bangladesh), partnership or proprietary firm and small & micro-savers but not by a minor or persons of unsound mind.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    3. Joint application by two persons is acceptable. In such a case, registration will be in favour of the principal applicant while dividend and other benefits, if any, will be addressed to bank account mentioned first in the Beneficiary Owners (BO) account. In case of the death of any of the joint holders, only the survivor shall be recognized as having the title to the units. On death of all the joint holders, the nominee, if any, will be responsible for distribution of the title, as per Supreme Court verdict of April 2016 (Civil Revision No. 1682 of 2015).
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    4. Minimum purchase amount for both individual and institutional investors is 500 (five hundred) units at the prevailing price or multiples thereof of the Scheme except under any Systematic Investment Plan (SIP) or for the Gift Voucher Units. Any investor can subscribe units at fair value.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    5. Application for purchase of the units should be accompanied by an account payee cheque/pay order/bank draft or attachment of any evidence of fund transfer (receipt) in favour of <strong>“Grameen Bank-AIMS First Unit Fund</strong>, A/C Number 1463542604002, The City Bank Limited, Karwan Bazaar Branch, Unique Trade Center (UTC), 8 Panthopath, Dhaka 1215, Bangladesh (Routing No. 225262531 SWIFT Code: CIBLBDDH)” for the total value of the units.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    6. After clearance/encashment of the cheque/draft/pay order, the applicant will be issued with a Confirmation of Unit Allocation through SMS and e-Mail against every purchase mentioning the denomination of number of units applied for and/or issued.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    7. The units may be transferred by way of inheritance/gift and/or by specific operation of the law. In case of transfer, the Fund will charge a nominal fee as decided by AIMS from time to time, except in the case of transfer by way of inheritance.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    8. Unitholders may split their joint account or consolidate their holdings to a joint account subject to minimum denomination of 500 (five hundred) units. In case of split, the Scheme will charge a nominal fee as decided by AIMS from time to time.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    9. Dividend may be delivered in cash or by way of units under Cumulative Investment Plan (CIP) or both as the applicant gives option on the application form or notified at a later date, within stipulated time.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    10. All payments/receipts in connection with or arising out of transactions in the units applied for shall be in Bangladeshi Taka.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    11. Each application must be accompanied with a filled-up “Know Your Client (KYC)” form by the Principal Applicant except in the Small & Micro-Savers Category.
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="my-2">
                                <ListGroup.Item>
                                    12. There shall be a moratorium of 90 days for sale/surrender after purchase.
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        )
    }
}