import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Loading from '../loading'
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const logo=global.config.data.files.logo
const baseUrl=global.config.data.path.basePath
const name=global.config.data.info.name
const address=global.config.data.info.address
const email=global.config.data.info.email
const web=global.config.data.info.web
const mobile=global.config.data.info.mobile
const date_format=global.config.data.date.date_format
const date_format_y_m_d=global.config.data.date.date_format_y_m_d
export default class NavHistoryStatement extends Component {
    state = {
        validated: false,
        authorization:false,
        navHistoryReportShow:false,
        token:'',
        portfolios:[],
        user:[],
        account_list:[],

        fund:'',
        date:'',
        e_date:'',

        NavHistory:[],

        fundError:'',
        dateError:'',
        e_dateError:'',
        isLoading: true
    }
    changeState=(e)=>{
        if(e.target.name=='fund'){
            this.getAccountList(e.target.value,this.state.token)
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeDate=(name,value)=>{
        this.setState({
            [name]:dayjs(value).format(date_format_y_m_d),
            [name+'Error']:''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    componentDidMount(){
        this.setState({
            fund:''
        })
        document.title = 'Nav history statement'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                authorization:true,
                isLoading: false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    isLoading: false
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.data.length>0)
                this.getAccountList(res.data.data[0].PORTFOLIO_ID,this.state.token)
            this.setState({
                portfolios:res.data.data,
                fund:res.data.data[0].PORTFOLIO_ID
            })
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    reg_no:res.data.data[0].INVESTOR_REG_NO
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    gainLossReport=(e)=>{
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid ||this.state.date=='' ||this.state.e_date==''){
            if(this.state.date==''){
                this.setState({
                    dateError:'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    if(i==0){
                        elements[i].style.border = "1px solid red";
                        break;
                    }
                }
            }
            if(this.state.e_date==''){
                this.setState({
                    e_dateError:'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    if(i==1){
                        elements[i].style.border = "1px solid red";
                        break;
                    }
                }
            }
            return
        }
        this.setState({
            isLoading:true
        })
        let data = {
            fund:this.state.fund,
            date:this.state.date,
            e_date:this.state.e_date,
        }
        const url = baseUrl+'/api/nav-history-report'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    errorStatus:res.data.status,
                    navHistoryReportShow:true,
                    NavHistory:res.data.data.NavHistory
                    
                }) 
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })

            }
            this.setState({
                isLoading:false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false

    }
    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/nav-history-report'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:this.state.fund,date:this.state.date,e_date:this.state.e_date}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("navHistoryReport");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    render() {
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                            <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.gainLossReport(e)}>
                                    <Table variant="light" striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td>Name of the Investor</td>
                                                <td>{this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Mutual Fund</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            name="fund" 
                                                            variant="secondary" 
                                                            required
                                                            defaultValue={this.state.fund}
                                                            >
                                                                {/* <option value='' disabled>Select fund</option> */}
                                                            {
                                                                this.state.portfolios.map(portfolio=>{
                                                                    return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                                })
                                                            }

                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fundError==''?'This field is required':this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Transaction Date</td>
                                                <td>
                                                    <Row>
                                                        <Col md={5}>
                                                            <Form.Group controlId="formPlaintextEmail">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        className="formPlaintextEmail"
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('date',value) }} 
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'date'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                <Form.Control.Feedback className={this.state.dateError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.dateError==''?'This field is required':this.state.dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2} align="center" className="fw-bold text-secondary">
                                                            To
                                                        </Col>
                                                        <Col md={5} >
                                                            <Form.Group controlId="formPlaintextEmail">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        className="formPlaintextEmail"
                                                                        format={date_format}
                                                                        onChange={(value) => { this.changeDate('e_date',value) }} 
                                                                        slotProps={{
                                                                            textField: {
                                                                                name: 'e_date'
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                <Form.Control.Feedback className={this.state.e_dateError!=''?'show':''} type="invalid">
                                                                    {
                                                                        this.state.e_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {
                                                                        this.state.e_dateError==''?'This field is required':this.state.e_dateError
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="end" colSpan={2}>
                                                    <Button
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                        {
                             this.state.navHistoryReportShow==true?
                             <>
                                <div className='text-end vanis'>
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.print(e)} icon={faPrint} /></a> 
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.getPdf(e)} icon={faFilePdf} /></a>
                                </div>
                                <Row id="navHistoryReport" className="justify-content-center">
                                    <Col md={12}>
                                        <p className="fw-bold">
                                        <Image
                                            src={logo}
                                            className="d-inline-block align-top print-logo"
                                            alt={name}

                                        />
                                        </p>
                                        <Table className="nav-history-report" variant="light" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <td colSpan={4} align="start">Investor ID: {this.state.user.client_id}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>NAV At Market Per Unit</td>
                                                    <td>Purchase Price Per Unit	</td>
                                                    <td>Surrender Price Per unit</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.NavHistory.map((historyResult,index)=>{
                                                        return <tr key={index}>
                                                                    <td>{historyResult.NAV_DATE}</td>
                                                                    <td>{historyResult.NAV_AT_MARKET_PRICE}</td>
                                                                    <td>{historyResult.INVESTOR_BUY_PRICE}</td>
                                                                    <td>{historyResult.INVESTOR_SALE_PRICE}</td>
                                                                </tr>
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className='text-end' colSpan={4}>
                                                        <p className="fw-bold text-secondary">{name}</p>
                                                        <p className="text-secondary">{address}</p>
                                                        <p>E-mail: {email}, Website: {web}</p>
                                                        <p>Mobile: {mobile}</p>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </>:''
                        }
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}