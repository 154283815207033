import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import { Navigate } from "react-router-dom";
import Loading from '../loading'
const baseUrl=global.config.data.path.basePath
export default class ChangePassword extends Component {
    state={
        validated: false,
        authorization:false,
        token: '',
        errorStatus:'',

        currentPassword:'',
        newPassword:'',
        confirmPassword:'',

        currentPasswordError:'',
        newPasswordError:'',
        confirmPasswordError:'',
        isLoading: true
    }
    componentDidMount() {
        document.title = 'Change Password';
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true,
                isLoading:false
            })
        }
    }
    changeState=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    changePassword=(e)=>{
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }
        e.preventDefault()
        let data = {
            currentPassword:this.state.currentPassword,
            newPassword:this.state.newPassword,
            confirmPassword:this.state.confirmPassword
        }
        const url = baseUrl+'/api/change-password'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => {
            this.setState({
                currentPasswordError:'',
                newPasswordError:'',
                confirmPasswordError:''
            }) 
            if(res.data.errors){
                Object.entries(res.data.errors).forEach(([key, value]) => {
                    this.setState({
                        [key+'Error']: value,
                    })
                });
            }else{
                this.setState({
                    errorStatus:res.data.status
                })
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 1000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
            }
            this.setState({
                isLoading:false
            })
         });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    render() {
        const {token= undefined,isLoading} = this.state;
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (
                        step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                        step_check=='PAYMENTOP' || step_check=='PAYMENTOP'
                    )?<Navigate to="/login" replace={false} />
                        :(
                            step_check!='FINISH'?
                                <Navigate to="/application-form" replace={false} />:
                            ''
                        )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className='justify-content-center'>
                            <Col md={6}>
                                <p align="start" className="fw-bold">Password Change</p>
                                <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.changePassword(e)}>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" >
                                        <Form.Label column sm="2">
                                            Current Password<span>*</span>
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control required onChange={(e)=>{this.changeState(e)}} name="currentPassword" type="password" />
                                            <Form.Control.Feedback className={this.state.currentPasswordError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.currentPasswordError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.currentPasswordError==''?'This field is required':this.state.currentPasswordError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" >
                                        <Form.Label column sm="2">
                                            New Password<span>*</span>
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control required onChange={(e)=>{this.changeState(e)}} name="newPassword" type="password" />
                                            <Form.Control.Feedback className={this.state.newPasswordError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.newPasswordError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.newPasswordError==''?'This field is required':this.state.newPasswordError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3 justify-content-center" >
                                        <Form.Label column sm="2">
                                            Confirm Password<span>*</span>
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control required onChange={(e)=>{this.changeState(e)}} name="confirmPassword" type="password" />
                                            <Form.Control.Feedback className={this.state.confirmPasswordError!=''?'show':''} type="invalid">
                                                {
                                                    this.state.confirmPasswordError
                                                }
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    this.state.confirmPasswordError==''?'This field is required':this.state.confirmPasswordError
                                                }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Row className='justify-content-end' >
                                        <Col align="end" md={4}>
                                            <Button
                                                className='form-button my-4'
                                                type="submit"
                                                id="nextBtn"
                                            >Update <FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}