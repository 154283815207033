import React, { Component } from 'react';
import { Container, Row, Col, ListGroup, Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import './constants/env';
import axios from 'axios';
import { Link,Navigate } from "react-router-dom";
const baseUrl=global.config.data.path.basePath
const logo=global.config.data.files.logo
const mobile=global.config.data.info.mobile
const email=global.config.data.info.email
const name=global.config.data.info.name
export default class Header extends Component {
    state={
        status:401
    }
    componentDidMount(){
        if(!this.props.auth){
            let data = {
                refresh: localStorage.getItem('refreshToken'),
            }
            const url = baseUrl+'/api/refresh'
            axios({
                url: url,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            }).then((res) => {
                localStorage.removeItem('token')
                localStorage.setItem('token',res.data.access)
                this.setState({
                    status:200
                })
            }).catch((err) => {
                localStorage.removeItem('token')
                this.setState({
                    status:401
                })
            });
            
        }
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    }
    render() {
        return (
            <Container className='vanis' fluid={true}>
                {
                    (this.state.status!=401 && this.props.path!='login'?<Navigate to="/investor/profile" replace={false} />:'')
                }
                <Row>
                    <Col sm={12} md={12} lg={12} className='top-header'>
                        <Container>
                            <ListGroup horizontal>
                                <ListGroup.Item className="border-0"><FontAwesomeIcon icon={faPhone} /><a className="text-light" href={"tel:"+mobile}> {mobile}</a></ListGroup.Item>
                                <ListGroup.Item className="border-0"><FontAwesomeIcon icon={faEnvelope} /><a className="text-light" href={"mailto:"+email}> {email}</a></ListGroup.Item>
                                <ListGroup.Item className="border-0 ms-auto"><Link className="text-light" to={`${this.props.auth==true?'/logout':'/login'}`}><FontAwesomeIcon icon={faArrowRightToBracket} /> {this.props.auth==true?'SIGN OUT':'SIGN IN'} </Link></ListGroup.Item>
                            </ListGroup>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Navbar bg="light" expand="lg">
                            <Container>
                                <Link className="navbar-brand" to="/login">
                                    <Image
                                        src={logo}
                                        className="d-inline-block align-top logo"
                                        alt={name}

                                    />
                                </Link>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
                                    <Nav className="nav">
                                        {/* <Link to="/" className='btn btn-danger transition-button me-3' ><span>Home</span></Link> */}
                                        <a href="https://www.aims-bangladesh.com/" className="nav-link" ><span>Home</span></a>
                                        <Link className="nav-link" to="/investor/profile">Profile</Link>
                                        <Link className="nav-link" to="/investor/change-profile">Update Info</Link>
                                        <Link className="nav-link" to="/purchase">Buy Units</Link>
                                        <Link className="nav-link" to="/surrender">Surrender Units</Link>
                                        <Link className="nav-link" to="/change-password">Change Password</Link>
                                        <NavDropdown title="Reports" id="basic-nav-dropdown">
                                            <Link className="dropdown-item" to="/report/portfolio-statement">Portfolio Statement</Link>
                                            <Link className="dropdown-item" to="/report/transactionStatement">Transaction History</Link>
                                            <Link className="dropdown-item" to="/report/unit-allocation-statement">Confirmation of Unit Allocation</Link>
                                            <Link className="dropdown-item" to="/report/gainloss-statement">Capital Gain / Loss</Link>
                                            {/* <Link className="dropdown-item" to="/report/nav-history-statement">NAV History</Link> */}
                                            <Link className="dropdown-item" to="/report/tax-certificate-statement">Tax Certificate</Link>
                                            <Link className="dropdown-item" to="/report/surrender-statement">Surrender Form</Link>
                                        </NavDropdown>
                                        <Link className="nav-link" to="/create-new-account">Create New SIP</Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>

                        </Navbar>
                    </Col>
                </Row>
            </Container>
        );
    }
}