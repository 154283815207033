import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import Header from '../header'
import Footer from '../footer'
import Swal from 'sweetalert2'
import { Navigate } from "react-router-dom";
import Loading from '../loading'
const baseUrl = global.config.data.path.basePath
export default class PaymentSuccess extends Component {
    state = {
        authorization: false,
        token: '',
        errorStatus: '',
        purchase_payment: false,
        create_account_payment: false,
        investor_unit_app_id: '',
        fund_name: '',
        reg_no: '',
        no_of_unit: '',
        purchase_price: '',
        installment_size: '',
        client_id: '',
        paymentMethod:'',
        investor_name:'',
        institution_name:'',
        boAccountNo:'',
        investmentType:'',
        installment_tenure:'',
        installment_date:'',
        bkash_change:1.3,
        visa_charge:1.6,
        master_charge:1.6,
        nexus_charge:1,
        rocket_charge:1,
        american_charge:1,
        gateway_title:'',
        operation_mode:'',
        isLoading: true
    }
    componentDidMount() {
        document.title = 'Payment';
        const token = localStorage.getItem('token')
        if (token) {
            this.setState({
                token: token,
                authorization: true
            })
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const statusc = urlParams.get('statusc');
        const statusp = urlParams.get('statusp');
        const iia = urlParams.get('iia');
        if (statusc == 200) {
            this.setState({
                create_account_payment: true,
                create_new_account_details:[]
            })
        } else if (statusp == 200) {
            this.setState({
                purchase_payment: true
            })
        }
        if(iia!='None'){
            this.getPaymentDetails(token, iia)
        }else{
            this.setState({
                isLoading: false
            })
        }
        if (statusc == 200 || statusp == 200) {
            Swal.fire({
                position: 'top-right',
                text: 'Your Request Successfully received',
                icon: 'success',
                timer: 2000
            })
        }

    }
    getPaymentDetails = (token, iia) => {
        const url = baseUrl + '/api/get-info-details'
        axios({
            url: url,
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + String(token)
            },
            params: { iia: iia }
        }).then((response) => {
            console.log(response.data)
            if (response.data.status == 200) {
                this.setState({
                    investor_unit_app_id: response.data.data.INVESTOR_UNIT_APP_ID,
                    fund_name: response.data.data.PORTFOLIO_NAME,
                    reg_no: response.data.data.INVESTOR_REG_NO,
                    no_of_unit: response.data.data.NO_OF_UNITS,
                    purchase_price: response.data.data.RATE,
                    installment_size: response.data.data.INSTALLMENT_SIZE,
                    client_id: response.data.data.CLIENT_ID.replace(/^AIMS-/i, ""),
                    paymentMethod:response.data.data.PAY_MODE,
                    investor_name:response.data.data.INVESTOR_NAME,
                    institution_name:response.data.data.INSTITUTION_NAME,
                    boAccountNo:response.data.data.BO_ACCOUNT_NO,
                    investmentType:response.data.data.INVESTMENT_TYPE_ID,
                    installment_tenure:response.data.data.INSTALLMENT_TENURE,
                    installment_date:response.data.data.INSTALLMENT_DATE,
                    operation_mode:response.data.data.OPERATION_MODE_ID
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    CDBL_form = (e) => {
        e.preventDefault()
        const url = baseUrl + '/api/cdbl-transfer-form'
        this.setState({
            isLoading: true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            params: { investor_unit_app_id: this.state.investor_unit_app_id }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `CDBL-${this.state.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            console.log(err)
            this.setState({
                isLoading: false
            })
        });
    }
    ACK_form = (e) => {
        e.preventDefault()
        const url = baseUrl + '/api/purchase-download'
        this.setState({
            loading: true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + String(this.state.token)
            },
            params: {
                fund_name: this.state.fund_name, reg_no: this.state.reg_no, no_of_unit: this.state.no_of_unit,
                purchase_price: this.state.purchase_price, total_purchase_amount: this.state.installment_size,
                client_id: this.state.client_id
            }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Ack-${this.state.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading: false
            })
        }).catch((err) => {
            this.setState({
                isLoading: false
            })
        });
    }

    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/get-payment-confirmation-details'
        this.setState({
            isLoading:true
        })
        
        let gateway_title =this.state.paymentMethod=='bKashMFS'?'Bkash Charge':
                    this.state.paymentMethod=='AMEX'?'American Express Charge':
                    this.state.paymentMethod=='MC'?'Mastercard Charge':
                    this.state.paymentMethod=='VC'?'Visa Charge':
                    this.state.paymentMethod=='MB'?'Rocket Charge':
                    this.state.paymentMethod=='NEXUS'?'Nexus Charge':''
        let charge =this.state.paymentMethod=='bKashMFS'?`${this.state.bkash_change }`:
                    this.state.paymentMethod=='AMEX'?`${this.state.american_charge }`:
                    this.state.paymentMethod=='MC'?`${this.state.master_charge }`:
                    this.state.paymentMethod=='VC'?`${this.state.visa_charge }`:
                    this.state.paymentMethod=='MB'?`${this.state.rocket_charge }`:
                    this.state.paymentMethod=='NEXUS'?`${this.state.nexus_charge }`:''
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{
                investor_name:(this.state.investor_name!=null?this.state.investor_name:this.state.institution_name),
                client_id:'AIMS-'+this.state.client_id,
                boAccountNo:this.state.boAccountNo,
                portfolio_name:this.state.fund_name,
                purchase_price:this.state.purchase_price,
                installment_size:this.state.installment_size,
                paymentType:this.state.paymentType,
                charge:charge,
                gateway_title:gateway_title,
                no_of_unit:this.state.installment_size/this.state.purchase_price,
                investmentType:this.state.investmentType,
                investmentTypeText:(this.state.investmentType==2?'Single Payment Investment (Non-SIP)':'Systemative Investment Payment (SIP)'),
                installment_tenure:this.state.installment_tenure,
                installment_date:this.state.installment_date
            }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Buy-AIMS-${this.state.client_id}.pdf`)
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }

    render() {
        const { token = undefined, isLoading } = this.state;
        const step_check = localStorage.getItem('STEP_CHECK')
        let gateway_title =this.state.paymentMethod=='bKashMFS'?'Bkash Charge':
                    this.state.paymentMethod=='AMEX'?'American Express Charge':
                    this.state.paymentMethod=='MC'?'Mastercard Charge':
                    this.state.paymentMethod=='VC'?'Visa Charge':
                    this.state.paymentMethod=='MB'?'Rocket Charge':
                    this.state.paymentMethod=='NEXUS'?'Nexus Charge':''
        let charge =this.state.paymentMethod=='bKashMFS'?`${this.state.bkash_change }`:
                    this.state.paymentMethod=='AMEX'?`${this.state.american_charge }`:
                    this.state.paymentMethod=='MC'?`${this.state.master_charge }`:
                    this.state.paymentMethod=='VC'?`${this.state.visa_charge }`:
                    this.state.paymentMethod=='MB'?`${this.state.rocket_charge }`:
                    this.state.paymentMethod=='NEXUS'?`${this.state.nexus_charge }`:''

        if (isLoading) {
            return (
                <Loading loading={isLoading} />
            )
        }
        return (
            <>
                {
                    token != undefined ? (this.state.errorStatus == 401 ? <Navigate to="/login" replace={false} /> : '') : <Navigate to="/login" replace={false} />
                }
                {
                    (
                        step_check == 'null' || step_check == 'INVESTMENT' || step_check == 'BOACCOUNT' || step_check == 'PAYMENTOP' ||
                        step_check == 'PAYMENTOP' || step_check == 'PAYMENTOP'
                    ) ? <Navigate to="/login" replace={false} />
                        : (
                            step_check != 'FINISH' ?
                                <Navigate to="/application-form" replace={false} /> :
                                ''
                        )
                }
                <Header auth={this.state.authorization} />
                <Container className='content-area' fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center">
                            {
                                this.state.purchase_payment == true ?
                                    <>
                                        <Col className=' text-end' md={12}>
                                            <a className='btn btn-danger' onClick={(e) => { this.ACK_form(e) }} >Acknowledgement Download</a>
                                            {/* <a className='btn btn-danger ms-2' onClick={(e) => { this.CDBL_form(e) }} >CDBL Form Download</a> */}
                                        </Col>
                                        <Col md={12}>
                                            <Card className='mt-4'>
                                                <Card.Body className='my-3'>
                                                    <p>Dear Valued Investor, thank you for submitting your purchase request and necessary information. Your purchase request is under process now. Once your payment is cleared, we will notify you and allocate the units to your BO accounts within 7 working days.
                                                        Please Download or Print Acknowledgement Slip and keep it for your reference.</p>
                                                </Card.Body>
                                            </Card>
                                        </Col></> : ''

                            }

                            {
                                this.state.create_account_payment == true ?
                                    <>
                                        <Col className="my-4" md={6} >
                                            <Form className="my-4">
                                                <Card className='tab'>
                                                    <Card.Body id="print">
                                                        <p className="faCircleCheck mb-3" align="center" ><FontAwesomeIcon icon={faCircleCheck} /></p>
                                                        <h1 className='mb-4' align="center" >Submitted Successfully!</h1>
                                                        <ListGroup className='my-4 client-details'>
                                                            <Table className='text-center'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td width="50%" >{this.state.operation_mode==1?"Investor Name:":(this.state.operation_mode==2?"Investor Name:":"Institution Name")}</td>
                                                                        <td width="50%">{this.state.operation_mode==1?this.state.investor_name:(this.state.operation_mode==2?this.state.investor_name:this.state.institution_name)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="50%" >Investor ID:</td>
                                                                        <td width="50%">{'AIMS-'+this.state.client_id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="50%" >BO Account No:</td>
                                                                        <td width="50%">{this.state.boAccountNo}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="50%" >Fund Name:</td>
                                                                        <td width="50%">{this.state.fund_name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="50%" >Buy Price Per Unit:</td>
                                                                        <td width="50%"><span className='text-danger'>৳</span> {this.state.purchase_price}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="50%" >No of Units Purchased:</td>
                                                                        <td width="50%">{this.state.no_of_unit}</td>
                                                                    </tr>
                                                                    {
                                                                        this.state.investmentType==2?
                                                                        <tr>
                                                                            <td width="50%" >Investment Amount:</td>
                                                                            <td width="50%"><span className='text-danger'>৳</span> {this.state.installment_size}</td>
                                                                        </tr>:
                                                                        <tr>
                                                                            <td width="50%" >Installment Amount:</td>
                                                                            <td width="50%"><span className='text-danger'>৳</span> {this.state.installment_size}</td>
                                                                        </tr>
                                                                    }
                                                                    <tr>
                                                                        <td width="50%" >Investor's Balance:</td>
                                                                        <td width="50%"><span className='text-danger'>৳</span> {this.state.installment_size}</td>
                                                                    </tr>
                                                                    {
                                                                        this.state.paymentType=='online'?
                                                                        <>
                                                                            <tr>
                                                                                <td width="50%" >{gateway_title}:</td>
                                                                                <td width="50%">
                                                                                    {charge}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width="50%" >Adjusted total Amount:</td>
                                                                                <td width="50%">{this.state.installment_size+(this.state.installment_size*(parseFloat(charge)/100))}</td>
                                                                            </tr>
                                                                        </>:''
                                                                    }
                                                                    
                                                                    <tr>
                                                                        <td width="50%" >Payment Method:</td>
                                                                        <td width="50%">
                                                                            {
                                                                                this.state.paymentType=='beftn'?
                                                                                    'Online Bank Deposit':
                                                                                this.state.paymentType=='online'?
                                                                                    'Online Payment Gateway'
                                                                                :
                                                                                    'Offline Bank Deposit'
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="50%" >Investment Type:</td>
                                                                        <td width="50%">{this.state.investmentType==2?'Single Payment Investment (Non-SIP)':'Systemative Investment Payment (SIP)'}</td>
                                                                    </tr>
                                                                    {
                                                                        this.state.investmentType==1?
                                                                        <>
                                                                            <tr>
                                                                                <td width="50%" >SIP Tenure:</td>
                                                                                <td width="50%">{this.state.installment_tenure}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width="50%" >Installment Date:</td>
                                                                                <td width="50%">{this.state.installment_date}</td>
                                                                            </tr>
                                                                        </>:''
                                                                    }
                                                                    <tr>
                                                                        <td colSpan={2} onClick={(e)=>this.getPdf(e)} style={{cursor:"pointer"}} className="border-0 pb-3 vanis" variant="light">
                                                                            <FontAwesomeIcon icon={faCloudArrowDown} /> Download & Save
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </ListGroup>
                                                    </Card.Body>
                                                </Card>
                                            </Form>
                                        </Col>
                                    </> : ''
                            }
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </>
        )
    }
}