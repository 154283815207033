import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Loading from '../loading'
import { Navigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const logo=global.config.data.files.logo
const baseUrl=global.config.data.path.basePath
const name=global.config.data.info.name
const email=global.config.data.info.email
const mobile=global.config.data.info.mobile
const address=global.config.data.info.address
const web=global.config.data.info.web
const date_format=global.config.data.date.date_format
const date_format_y_m_d=global.config.data.date.date_format_y_m_d
export default class UnitAllocationStatement extends Component {
    state = {
        validated: false,
        authorization:false,
        showUnitAllocation:false,
        token:'',
        portfolios:[],
        user:[],
        account_list:[],

        fund:'',
        reg_no:'',
        investor_account_id:'',
        date:'',
        allocationInfo:[],

        fundError:'',
        dateError:'',
        isLoading: true
    }
    changeState=(e)=>{
        if(e.target.name=='fund'){
            this.getAccountList(e.target.value,this.state.token)
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeDate=(name,value)=>{
        this.setState({
            [name]:dayjs(value).format(date_format_y_m_d),
            [name+'Error']:''
        })
        var elements = document.getElementsByClassName("MuiInputBase-root");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.border = "1px solid #fff";
        }
    }
    componentDidMount(){
        this.setState({
            fund:''
        })
        document.title = 'Unit allocation statement'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                authorization:true,
                isLoading: false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    isLoading: false
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.data.length>0)
                this.getAccountList(res.data.data[0].PORTFOLIO_ID,this.state.token)
            this.setState({
                portfolios:res.data.data,
                fund:res.data.data[0].PORTFOLIO_ID
            })
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    reg_no:res.data.data[0].PORTFOLIO_CODE,
                    investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    unitAllocationReport=(e)=>{
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid ||this.state.date==''){
            if(this.state.date==''){
                this.setState({
                    dateError:'This field is required'
                })
                var elements = document.getElementsByClassName("MuiInputBase-root");
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.border = "1px solid red";
                }
                return
            }
            return
        }
        this.setState({
            isLoading:true
        })
        let data = {
            fund:this.state.fund,
            reg_no:this.state.reg_no,
            investor_account_id:this.state.investor_account_id,
            date:this.state.date,
        }
        const url = baseUrl+'/api/unit-allocation-report'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    errorStatus:res.data.status,
                    showUnitAllocation:true
                }) 
                if(res.data.data.allocationInfo.length>0){
                    this.setState({
                        allocationInfo:res.data.data.allocationInfo[0]
                    })
                }
                Swal.fire({
                    position: 'top-right',
                    text: res.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false

    }
    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/unit-allocation-report'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:this.state.fund,reg_no:this.state.reg_no,date:this.state.date,investor_account_id:this.state.investor_account_id}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
        }).catch((err) => {
            console.log(err.response)
         });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("unitAllocationReport");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    render() {
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }

        let PORTFOLIO_CODE            = this.state.allocationInfo.PORTFOLIO_CODE
        let COUA_NO                = this.state.allocationInfo.PORTFOLIO_CODE+'-'+this.state.allocationInfo.INVESTOR_REG_NO
        let COUNT_NO_OF_PURCHASE    = this.state.allocationInfo.COUNT_NO_OF_PURCHASE
        let len                     = 1//this.state.allocationInfo.COUNT_NO_OF_PURCHASE.length
        if(len ==1){
            COUNT_NO_OF_PURCHASE = '00'.COUNT_NO_OF_PURCHASE
        } else if(len ==2){
            COUNT_NO_OF_PURCHASE = '0'.COUNT_NO_OF_PURCHASE
        }
        COUNT_NO_OF_PURCHASE    = COUA_NO+"-"+COUNT_NO_OF_PURCHASE
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                            <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.unitAllocationReport(e)}>
                                    <Table variant="light" striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td>Name of the Investor</td>
                                                <td>{this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>
                                                <Form.Group controlId="formPlaintextEmail">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            className="formPlaintextEmail"
                                                            format={date_format}
                                                            onChange={(value) => { this.changeDate('date',value) }} 
                                                            slotProps={{
                                                                textField: {
                                                                    name: 'date'
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    <Form.Control.Feedback className={this.state.dateError!=''?'show':''} type="invalid">
                                                        {
                                                            this.state.dateError
                                                        }
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.dateError==''?'This field is required':this.state.dateError
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                </td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Mutual Fund</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            name="fund" 
                                                            variant="secondary" 
                                                            
                                                            required
                                                            defaultValue={this.state.fund}
                                                            >
                                                                {/* <option value='' disabled>Select fund</option> */}
                                                            {
                                                                this.state.portfolios.map(portfolio=>{
                                                                    return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                                })
                                                            }

                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fundError==''?'This field is required':this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Registration No.</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control as="select" 
                                                            name="reg_no" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            variant="secondary" 
                                                            defaultValue={this.state.reg_no}
                                                            required 
                                                            >
                                                                {/* <option value='' disabled>Select reg. no.</option> */}
                                                            {
                                                                this.state.account_list.map(accountlist=>{
                                                                    return <option 
                                                                                value={accountlist.PORTFOLIO_CODE} 
                                                                                data-investor-account-id={accountlist.INVESTOR_ACCOUNT_ID}
                                                                                key={accountlist.INVESTOR_REG_NO}>{accountlist.INVESTOR_REG_NO}</option>  
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.reg_noError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.reg_noError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.reg_noError==''?'This field is required':this.state.reg_noError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td align="end" colSpan={2}>
                                                    <Button
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                        {
                            this.state.showUnitAllocation==true?
                            <>
                            <div className='text-end vanis'>
                                <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.print(e)} icon={faPrint} /></a> 
                                <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.getPdf(e)} icon={faFilePdf} /></a>
                            </div>
                            <div id="unitAllocationReport">
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <p className="fw-bold">
                                        <Image
                                            src={logo}
                                            className="d-inline-block align-top print-logo"
                                            alt={name}

                                        />
                                        </p>
                                        <Table className="unit-allocation-report" variant="light" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <td align="center" colSpan={2}>
                                                        <h1 className="text-secondary" >{this.state.allocationInfo.PORTFOLIO_NAME}</h1>
                                                        <p>Registered under the Securities and Exchange Commision (Mutual Fund) Rules, 2001</p>
                                                        <p>Investor ID: {this.state.user.client_id}</p>
                                                        <p>Registration No.: {this.state.allocationInfo.INVESTOR_ID}</p>
                                                        <p>Sponsor: {this.state.allocationInfo.SPONSOR}</p>
                                                        <p>Asset Manager: {this.state.allocationInfo.ASSET_MANAGER}</p>
                                                        <p>Trustee: {this.state.allocationInfo.TRUSTEE_NAME}</p>
                                                        <p>Custodian: {this.state.allocationInfo.CUSTODIAN_NAME}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" colSpan={2}>
                                                        <h3 className="text-secondary">CONFIRMATION OF UNIT ALLOCATION</h3>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} >
                                                        <p>
                                                            This is to certify that the following person is a registered unit-holder of {this.state.allocationInfo.PORTFOLIO_NAME} and has been allocated units of the fund issued by Aims of Bangladesh Limited under the
                                                            authority provided in the trust deed of {this.state.allocationInfo.PORTFOLIO_NAME}. Following are the details 
                                                            of the allotment as on {this.state.allocationInfo.date}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{width: "50%"}}>Investor’s Name:</td>
                                                    <td>{this.state.allocationInfo.INVESTOR_NAME}</td>
                                                </tr>
                                                <tr>
                                                    <td>Number of Units</td>
                                                    <td>{this.state.allocationInfo.TOTAL_NO_OF_UNITS}</td>
                                                </tr>
                                                <tr>
                                                    <td>Average Buy Price (BDT)</td>
                                                    <td>{this.state.allocationInfo.TOTAL_NO_OF_UNITS>0?
                                                    this.state.allocationInfo.TOTAL_AMOUNTS/this.state.allocationInfo.TOTAL_NO_OF_UNITS:0}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Investment Value</td>
                                                    <td>{this.state.allocationInfo.TOTAL_AMOUNTS}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <p className="fw-bold text-secondary">Note:</p>
                                                        <p>This Certificate is a confidential and legal document of subscribing {this.state.allocationInfo.PORTFOLIO_NAME}. Unauthorized use,
                                                            disclosure or copying of this document is unlawful and strictly prohibited, and shall be treated with appropriate
                                                            measures of law. This Certificate will be void as and when any new “Certificate” is issued to the same investor.
                                                            As this is a system-generated certificate, it does not require any authorized signature.</p>
                                                        <p>For any query or changes to your personal details, please contact us at 01327266767 or send email to
                                                            hello@aims-bangladesh.com for any assistance at any time.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='text-end' colSpan={2}>
                                                        <p className="fw-bold text-secondary">{name}</p>
                                                        <p className="text-secondary">{address}</p>
                                                        <p>E-mail: {email}, Website: {web}</p>
                                                        <p>Mobile: {mobile}</p>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </div></>
                            :''
                        }
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}