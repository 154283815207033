import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup, Table,Image } from 'react-bootstrap';
import axios from 'axios';
import '../constants/env';
import Header from '../header'
import Footer from '../footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Loading from '../loading'
import { Navigate } from "react-router-dom";
const logo=global.config.data.files.logo
const baseUrl=global.config.data.path.basePath
const name=global.config.data.info.name
const email=global.config.data.info.email
const mobile=global.config.data.info.mobile
const address=global.config.data.info.address
const web=global.config.data.info.web
export default class SurrenderStatement extends Component {
    state = {
        validated: false,
        authorization:false,
        showSurrenderReport:false,
        token:'',
        portfolios:[],
        user:[],
        account_list:[],

        unitsSurrenderDetails:[],

        fund:'',
        investor_account_id:'',

        fundError:'',
        investor_account_idError:'',
        isLoading: true
    }
    changeState=(e)=>{
        if(e.target.name=='fund'){
            this.getAccountList(e.target.value,this.state.token)
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    componentDidMount(){
        this.setState({
            fund:''
        })
        document.title = 'Surrender statement'
        const token=localStorage.getItem('token')
        if(token){
            this.setState({
                token:token,
                authorization:true
            })
        }
        this.authUser(token)
        this.getPortfolio(token)
    }
    authUser=(token)=>{
        const url = baseUrl+'/api/get-user'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            this.setState({
                user:res.data.data,
                authorization:true,
                isLoading: false
            })
            // Swal.fire({
            //     position: 'top-right',
            //     text: res.data.message,
            //     icon: 'success',
            //     timer: 2000,
            //     showConfirmButton: false,
            // })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                    isLoading: false
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
         });
    }
    getPortfolio=(token)=>{
        const url = baseUrl+'/api/get-portfolio'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            }
        }).then((res) => {
            if(res.data.data.length>0)
                this.getAccountList(res.data.data[0].PORTFOLIO_ID,this.state.token)
            this.setState({
                portfolios:res.data.data,
                fund:res.data.data[0].PORTFOLIO_ID
            })
            
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    getAccountList=(fund,token)=>{
        const url = baseUrl+'/api/get-accountlist'
        axios({
            url: url,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(token)
            },
            params:{fund:fund}
        }).then((res) => {
            if(res.data.status==200){
                this.setState({
                    account_list:res.data.data,
                    investor_account_id:res.data.data[0].INVESTOR_ACCOUNT_ID
                })
            }
        }).catch((err) => {
            console.log(err.response.data)
         });
    }
    
    getSurrenderReport=(e)=>{
        e.preventDefault()
        const valid=this.checkValidation(e)
        if(!valid){
            return
        }

        let data = {
            fund:this.state.fund,
            investor_account_id:this.state.investor_account_id,
        }
        this.setState({
            isLoading:true
        })
        const url = baseUrl+'/api/surrender-report'
        axios({
            url: url,
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            data: JSON.stringify(data),
        }).then((res) => { 
            if(res.data.status==200){
                this.setState({
                    validated:!this.state.validated,
                    errorStatus:res.data.status,
                    showSurrenderReport:true,
                    unitsSurrenderDetails:res.data.data.unitsSurrenderDetails
                    
                }) 

            }
            this.setState({
                isLoading:false
            })
            
        }).catch((err) => {
            if(err.response.status==401){
                this.setState({
                    errorStatus:err.response.status,
                })
                Swal.fire({
                    position: 'top-right',
                    text: err.response.statusText,
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }
            this.setState({
                isLoading:false
            })
         });
        return false

    }
    getPdf=(e)=>{
        e.preventDefault()
        const url = baseUrl+'/api/surrender-report'
        this.setState({
            isLoading:true
        })
        axios({
            url: url,
            method: "GET",
            headers: {
                'Authorization': 'Bearer '+ String(this.state.token)
            },
            params:{fund:this.state.fund,investor_account_id:this.state.investor_account_id}
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
            this.setState({
                isLoading:false
            })
        }).catch((err) => {
            this.setState({
                isLoading:false
            })
            console.log(err.response)
         });
    }
    checkValidation=(event)=>{
        let valid =1
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            valid=0
        }

        this.setState({
            validated:true
        })
        return valid
    }
    print(e){
        e.preventDefault()
        let ignoreElement = document.getElementsByClassName("vanis");
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "none";
        }
        window.print()
        for (var i = 0; i < ignoreElement.length; i++) {
            ignoreElement[i].style.display = "";
        }
        // let printElement = document.getElementById("surrenderReport");
        // var printWindow = window.open('', 'PRINT');
        // printWindow.document.write(document.documentElement.innerHTML);
        // printWindow.document.body.style.margin = '0 0';
        // printWindow.document.body.innerHTML = printElement.outerHTML;
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();

    }
    render() {
        let i=1
        const {token= undefined,isLoading} = this.state
        const step_check=localStorage.getItem('STEP_CHECK')
        if(isLoading) {
            return (
                <Loading loading={isLoading}/>
            )
        }
        return (
            <>
                {
                    token!=undefined?(this.state.errorStatus==401?<Navigate to="/login" replace={false} />:''):<Navigate to="/login" replace={false} />
                }
                {
                    (step_check=='null' || step_check=='INVESTMENT' ||  step_check=='BOACCOUNT' ||  step_check=='PAYMENTOP' || 
                    step_check=='PAYMENTOP' || step_check=='PAYMENTOP')?<Navigate to="/login" replace={false} />:
                    (
                        step_check!='FINISH'?
                            <Navigate to="/application-form" replace={false} />:
                        ''
                    )
                }
                <Header auth={this.state.authorization} />
                <Container fluid={true}>
                    <Container className="my-4">
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                                <p className="fw-bold">Investor Details</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center vanis">
                            <Col md={6}>
                            <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.getSurrenderReport(e)}>
                                    <Table variant="light" striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td>Name of the Investor</td>
                                                <td>{this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Mutual Fund</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control 
                                                            as="select" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            name="fund" 
                                                            variant="secondary" 
                                                            required
                                                            defaultValue={this.state.fund}
                                                            >
                                                                {/* <option value='' disabled>Select fund</option> */}
                                                            {
                                                                this.state.portfolios.map(portfolio=>{
                                                                    return <option value={portfolio.PORTFOLIO_ID} key={portfolio.PORTFOLIO_ID}>{portfolio.PORTFOLIO_NAME}</option>  
                                                                })
                                                            }

                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.fundError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.fundError==''?'This field is required':this.state.fundError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr className='hide'>
                                                <td>Registration No.</td>
                                                <td>
                                                    <Form.Group controlId="formPlaintextEmail">
                                                        <Form.Control as="select" 
                                                            name="investor_account_id" 
                                                            onChange={(e) => { this.changeState(e) }} 
                                                            variant="secondary" 
                                                            defaultValue={this.state.investor_account_id}
                                                            required 
                                                            >
                                                                {/* <option value='' disabled>Select reg. no.</option> */}
                                                            {
                                                                this.state.account_list.map(accountlist=>{
                                                                    return <option value={accountlist.INVESTOR_ACCOUNT_ID} key={accountlist.PORTFOLIO_CODE}>{accountlist.PORTFOLIO_CODE}</option>  
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback className={this.state.investor_account_idError!=''?'show':''} type="invalid">
                                                            {
                                                                this.state.investor_account_idError
                                                            }
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                this.state.investor_account_idError==''?'This field is required':this.state.investor_account_idError
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="end" colSpan={2}>
                                                    <Button
                                                        className='form-button'
                                                        type="submit"
                                                        id="nextBtn"
                                                    >Submit</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                        {
                            this.state.showSurrenderReport==true?
                            <>
                                <div className='text-end vanis'>
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.print(e)} icon={faPrint} /></a> 
                                    <a className='report-icon'><FontAwesomeIcon onClick={(e)=>this.getPdf(e)} icon={faFilePdf} /></a>
                                </div>
                                <div id="surrenderReport">
                                    <Row className="justify-content-center">
                                        <Col md={12}>
                                            <p className="fw-bold">
                                            <Image
                                                    src={logo}
                                                    className="d-inline-block align-top print-logo"
                                                    alt={name}

                                                />
                                            </p>
                                            <Table className="surrender-report" variant="light" striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={7} align="center">Surrender History</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4} align="start">Investor Name: {this.state.user.name==null?this.state.user.institution_name:this.state.user.name}</td>
                                                        <td colSpan={3} align="end">Investor ID: {this.state.user.client_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sl. No.</td>
                                                        <td>No. of Units</td>
                                                        <td>Repurchase Price TK/Unit</td>
                                                        <td>Total Surrender Value Tk</td>
                                                        <td>Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {   
                                                        this.state.unitsSurrenderDetails.length>0?
                                                            this.state.unitsSurrenderDetails.map(unitsSurrenderDetail=>{
                                                            return <tr key={i}>
                                                                            <td>{i++}</td>
                                                                            <td>{unitsSurrenderDetail.NO_OF_UNITS}</td>
                                                                            <td>{unitsSurrenderDetail.EXIT_LOAD_SALE_PRICE}</td>
                                                                            <td>{unitsSurrenderDetail.REGULAR_AMOUNT}</td>
                                                                            <td>{unitsSurrenderDetail.INVESTOR_UNIT_APP_ID}</td>
                                                                        </tr> 
                                                            })
                                                        :<tr><td className='text-center' colSpan={5}>Not Found</td></tr>
                                                        
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td className='text-end' colSpan={5}>
                                                            <p className="fw-bold text-secondary">{name}</p>
                                                            <p className="text-secondary">-{address}</p>
                                                            <p>E-mail: {email}, Website: {web}</p>
                                                            <p>Mobile: {mobile}</p>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </div></>:''
                        }
                    </Container>
                </Container>
                <Footer />
            </>
        );
    }
}